<template>
  <v-card flat outlined :style="cssStyleForPicture" :height="sizeWithBorder" :width="sizeWithBorder">
    <v-layout column align-center justify-center class="ma-0 pa-0">
      <v-avatar v-if="useUrl" :size="size" color="background" class="ma-0 pa-0" data-testid="urlAvatar">
        <img :width="size" :height="size" :src="pictureUrl" :alt="$tc('C.TAvatar.user-avatar')" @error="imageLoadError"/>
      </v-avatar>
      <v-avatar v-if="useImage" :size="size" color="background" class="ma-0 pa-0" data-testid="imageAvatar">
        <v-img :width="size" :height="size" :lazySrc="require(`@/assets/${imageSrc}`).placeholder" :src="require(`@/assets/${imageSrc}`)" :srcset="require(`@/assets/${imageSrc}`).srcSet" :alt="$t('C.TAvatar.user-avatar')"/>
      </v-avatar>
      <v-avatar v-if="useIcon" :size="size" color="background" class="ma-0 pa-0" data-testid="iconAvatar">
        <t-icon :href="iconName" color="secondary" :title="$t('C.TAvatar.user-avatar')" :size="size"/>
      </v-avatar>
      <v-avatar v-if="useInitials" :size="size" :style="{'background-color': color}" class="ma-0 pa-0" data-testid="initialsAvatar">
        <strong :style="{'color': fontColor, 'font-size': fontSize}" class="text-uppercase ma-0 pa-0">{{ initials }}</strong>
      </v-avatar>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-avatar',
})
export default class TAvatar extends Vue {
  @Prop({ type: Number, default: 40 })
  public readonly size!: number;
  @Prop({ type: String, default: '??' })
  public readonly initials!: string;
  @Prop({ type: String, default: 'accent' })
  public readonly color!: string;
  @Prop({ type: String, default: '' })
  public readonly pictureUrl!: string;
  @Prop({ type: Boolean, default: false })
  public readonly dark!: boolean;

  private isLoadError: boolean = false;

  public get sizeWithBorder(): number {
    return this.size + 2;
  }

  public get fontColor(): string {
    return 'black';
  }

  public get fontSize(): string {
    return this.size / 40.0 * 17 + 'px';
  }

  public get iconName(): string {
    if (this.pictureUrl.startsWith('#')) {
      return this.pictureUrl;
    } else {
      return '#user-unknown';
    }
  }

  public get imageSrc(): string {
    const imageSrc = this.pictureUrl.substring(9);
    return imageSrc;
  }

  public get useUrl(): boolean {
    if (this.isLoadError) {
      return false;
    }
    if (this.pictureUrl.startsWith('data:')) {
      return true;
    }
    if (this.pictureUrl.startsWith('http')) {
      return true;
    }
    return false;
  }

  public get useImage(): boolean {
    if (this.pictureUrl.startsWith('@/assets/')) {
      return true;
    }
    return false;
  }

  public get useIcon(): boolean {
    if (this.pictureUrl.startsWith('#')) {
      return true;
    }
    return false;
  }

  public get useInitials(): boolean {
    if (this.isLoadError) {
      return true;
    }
    if (this.pictureUrl === '') {
      return true;
    }
    return !(this.useIcon || this.useImage || this.useUrl);
  }

  public get cssStyleForPicture(): string {
    if (this.dark) {
      return 'border-radius:50%;border:1px solid white;';
    } else {
      return 'border-radius:50%;border:1px solid black;';
    }
  }

  public imageLoadError() {
    this.isLoadError = true;
  }
}
</script>

