<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 596" @keydown.esc="onCancel" @keydown.enter="onEnter" persistent scrollable no-click-animation max-width="500" :width="$vuetify.breakpoint.width - 10">
    <v-card flat color="background" style="height: 596px;">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel" data-testid="dialogCancelBtn">
            <t-icon href="#close" :title="$t('G.cancel')" color="white" :size="24"/>
          </v-btn>
          <v-toolbar-title class="subtitle-1">{{ $t('C.TDialogStatus.dialogTitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined rounded style="border-width: thin; border-radius: 12px;" class="button text-none" @click="onCancel" data-testid="dialogConfirmBtn">{{ $t('G.close') }}</v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-layout column align-center class="text-center">
          <t-panel-status class="mt-12 pa-0" :user="user" :version="version" :servers="servers" :serverId="serverId" :pendingMutationsToSyncCount="pendingMutationsToSyncCount" :isOnline="isOnline" :isLiveSynch="isLiveSynch" :isSynching="isSynching" :isForceOffline="isForceOffline" @store="$emit('store', $event)"/>
        </v-layout>
        <v-card flat color="transparent" height="24" class="ma-0 pa-0"></v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import TIcon from '../components/TIcon.vue';
import TPanelStatus from '../components/TPanelStatus.vue';

@Component({
  components: {
    TIcon, TPanelStatus,
  },
  name: 't-dialog-status',
})
export default class TDialogStatus extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly dialog!: boolean;
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly user!: Domain.User | null;
  @Prop({ type: Array, default: function() { return []; } })
  public readonly servers: {id: string, name: string}[];
  @Prop({ type: String, default: 'config' })
  public readonly serverId!: string;
  @Prop({ type: String, required: true })
  public readonly version!: string;
  @Prop({ type: Boolean, default: false })
  public readonly isOnline: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isSynching: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isLiveSynch: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isForceOffline: boolean;
  @Prop({ type: Number, default: 0 })
  public readonly pendingMutationsToSyncCount: number;
  // Events
  // this.$emit('cancel');

  public onEnter() {
    this.onCancel();
  }

  public onCancel() {
    this.$emit('cancel');
  }
}
</script>

