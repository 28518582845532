/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */

import Vue from 'vue';
import Axios from 'axios';
import * as Oidc from 'oidc-client-ts';
import { parseJwt } from '@/helpers/StringHelper';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  validateStatus: null,
};

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' AXIOS ';

const axios = Axios.create(config);

let idTokenSentAtLeastOnce = false; 

let oidcUserManager: Oidc.UserManager | null = null;
export function SetOidcUserManager(userManager: Oidc.UserManager) { oidcUserManager = userManager;}

let oldAccessToken: string = '';
let oldIdToken: string = '';

axios.interceptors.request.use(
  async (request) => {
    //get oidc stuff...
    let accessToken = 'init';
    let idToken = '';
    if (oidcUserManager !== null) {
      const oidcUser = await oidcUserManager.getUser();
      if (oidcUser === null) {
        if (debug) {console.error(debugPrefix, 'No User');}
        accessToken = 'no user';
        idToken = '';
      } else {
        accessToken = oidcUser.access_token;
        if (oldAccessToken !== accessToken) {
          oldAccessToken = accessToken;
          const jwt = parseJwt(accessToken);
          if (debug) {console.log(debugPrefix, `Using AccessToken '${jwt.sub}' IAT ${new Date(jwt.iat * 1000).toJSON()} -> EXP ${new Date(jwt.exp * 1000).toJSON()}`, jwt);}
        }
        if (oidcUser.id_token) {
          idToken = oidcUser.id_token;
          if (oldIdToken !== idToken) {
            oldIdToken = idToken;
            const jwt = parseJwt(idToken);
            if (debug) {console.log(debugPrefix, `Using IdToken '${jwt.sub}' IAT ${new Date(jwt.iat * 1000).toJSON()} -> EXP ${new Date(jwt.exp * 1000).toJSON()}`, jwt);}
          }
        }
      }
    } else {
      if (debug) {console.error(debugPrefix, 'No UserManager');}
    }

    // Do something before request is sent
    request.headers.Authorization = `bearer ${accessToken}`;
    request.headers.tds_tz = Intl.DateTimeFormat().resolvedOptions().timeZone; // let's always send the timezone so that we can track where a user is...

    
    if (idTokenSentAtLeastOnce === false && idToken.length !== 0 && request.url?.indexOf('/easySync/') !== -1) { // we only want to send the id_token once per session... otherwise it's extra networking and processing that we don't require...
      idTokenSentAtLeastOnce = true;
      request.headers.tds_idToken = idToken;
    }

    return request;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data

    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  },
);

const AxiosPlugin = {
  install: function(Vue) { // signature (Vue, options)
    Vue.axios = axios;
    // window.axios = axios;
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return axios;
        },
      },
      $axios: {
        get() {
          return axios;
        },
      },
    });
  },
};

Vue.use(AxiosPlugin);

export default axios;
