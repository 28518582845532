/* eslint-disable curly */
/* eslint-disable no-console */
import { ActionPayload, MutationPayload, Plugin, Store } from 'vuex';
import Services from '../services/Services';
import * as EventBus from '../services/EventBus';
import * as AppModuleDomain from '../models/AppModuleDomain';
import * as AuthenticationModuleDomain from '../models/AuthenticationModuleDomain';
import * as UserModuleDomain from '../models/UserModuleDomain';
import * as StudioModuleDomain from '../models/StudioModuleDomain';
import * as NotificationModuleDomain from '../models/NotificationModuleDomain';
import * as Domain from '../models/Domain';
import * as PersistanceHelper from '@/helpers/PersistanceHelper';
import * as ModuleDomain from '@/models/ModuleDomain';
import * as TipModuleDomain from '@/models/TipModuleDomain';
import * as AuthSideCar from '@/modules/AuthenticationModuleSideCar';
import store from './vuex';
import { UUID } from 'uuidjs';
import * as DateHelper from '@/helpers/DateHelper';
import Vue from 'vue';

// responsibilities of vuexmagic
// 1 - Perform app initialization
// 2 - manage period changes (based on timer)
// 3 - send messages to other tabs, handle messages from other tabs
// 4 - manage hooks before and after VUEX ACTIONS
// 5 - manage hooks AFTER VUEX MUTATIONS
// 6 - manage inter-vuexModule stuff (i.e. studio changes so we need to recalculate tips for that studio)
// 7 - capture mutations needing to be sent to server, send if online otherwise store
// 8 - manage local peristence for offline scenarios, update local vuex when a local persistence is updated by another tab
// 9 - reboot app on signin-signout from other tabs
// 10 - ensure some kind of work is only processed by one tab
// 11 - sync data from server if local persistence is empty and periodically

// issues:
// 1 - vuex doesn't call subscribe with await
// 2 - vuex doesn't call subscribeAction (pre-post) with await
// 3 - we can't yet observe vuex so need the IMutationAltersData interface instead do detect what entities have changed
// 4 - syncWork isn't perfect - ideally the serviceworker (if present) should tell us if we can run
// 5 - for some reason, the post-mutation console group makes for the root
//     level "To-Do Studio (dev user LOCAL) dev user Initialized..." to not be at the first level

// dev recipes (why maintain this other than if we are adding new responsibilities)
// 1 - if a new high level thing to be persisted locall or a new vuexmodule is added : HandleIModuleAltersData() + ResetLocalDatabases() + Restore() + serverSync()
// 2 - adapt server domain to local domain : FixupServerSyncData()
// 3 - special work after mutation : HandleMutation()
// 4 - manage new broadcast (send and receive) : BroadcastXXX() && HandleBroadcastMessage() + ServiceWorker

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' VuexMagic ';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default class VuexMagic<S> {
  private broadcastChannel: BroadcastChannel | null = null;
  private broadcastId: string;
  private periodTimeout: number | null = null;
  private serverSyncTimeout: number | null = null;
  private syncWorkLocks: Map<string, boolean> = new Map<string, boolean>();
  private syncWorkLastTimestamps: Map<string, number> = new Map<string, number>();

  public activityCounter: number = 0;

  public serverNormalSyncInterval: number = 5 * 60 * 1000; // 5 minutes
  public serverHighSpeedSyncInterval: number = 20 * 1000; // 20 seconds
  public serverLowSpeedSyncInterval: number = this.serverNormalSyncInterval * 6; // 6 times normal
  public isHighSpeedSync: Date | null = null;
  public isLowSpeedSync: boolean = false;
  public lastServerSyncInstant: Date | null = null;
  public isRestoring: boolean = false;
  public isSynching: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async HandleMeUserUpdate(mutation: MutationPayload | null): Promise<void> {
    if (store.state.app.user === null) await Services.AppStorage.removeItem('me');
    else {
      const data = PersistanceHelper.CloneForPersistance(store.state.app.user, 'me');
      await Services.AppStorage.setItem('me', data);
    }
    this.BroadcastMeUpdate();
  }

  private async HandleIModuleAltersData(mutation: MutationPayload): Promise<void> {
    const payload = <ModuleDomain.IMutationAltersData>mutation.payload;
    if (payload.metadata_deletes.length === 0 && payload.metadata_upserts.length === 0 && payload.metadata_potentiallyNothingToPersist === false) {
      if (debug) console.error(debugPrefix, `Mutation ${mutation.type} HAS NO DATA TO SAVE`, mutation);
      return;
    }

    let needTipCompute = {
      counter: 0,
    };
    await asyncForEach(payload.metadata_upserts, async (element: any) => {
      const data = PersistanceHelper.CloneForPersistance(element, `${element.type} ${element.id}`);
      if (element instanceof Domain.Notification) {
        await Services.NotificationOfflineStorage.setItem(element.id, data);
        this.BroadcastEntityUpsert('Notification', element.id);
      } else if (element instanceof Domain.PublicUser) {
        await Services.UserOfflineStorage.setItem(element.id, data);
        this.BroadcastEntityUpsert('PublicUser', element.id);
      } else if (element instanceof Domain.Studio) {
        await Services.StudioOfflineStorage.setItem(element.id, data);
        this.BroadcastEntityUpsert('Studio', element.id);
        needTipCompute.counter++;
      } else if (element instanceof Domain.ToDo) {
        await Services.ToDoOfflineStorage.setItem(element.id, data);
        this.BroadcastEntityUpsert('ToDo', element.id);
        needTipCompute.counter++;
      } else {
        throw new Error('Invalid Type');
      }
    });

    await asyncForEach(payload.metadata_deletes, async (element: any) => {
      if (element instanceof Domain.Notification) {
        await Services.NotificationOfflineStorage.removeItem(element.id);
        this.BroadcastEntityRemoved('Notification', element.id);
      } else if (element instanceof Domain.PublicUser) {
        await Services.UserOfflineStorage.removeItem(element.id);
        this.BroadcastEntityRemoved('PublicUser', element.id);
      } else if (element instanceof Domain.Studio) {
        await Services.StudioOfflineStorage.removeItem(element.id);
        this.BroadcastEntityRemoved('Studio', element.id);
        needTipCompute.counter++;
      } else if (element instanceof Domain.ToDo) {
        await Services.ToDoOfflineStorage.removeItem(element.id);
        this.BroadcastEntityRemoved('ToDo', element.id);
        needTipCompute.counter++;
      } else {
        throw new Error('Invalid Type');
      }
    });

    if (needTipCompute.counter > 0 && this.isRestoring === false && this.isSynching === false) {
      store.commit(TipModuleDomain.Compute.MutationName, new TipModuleDomain.Compute(store.state.studio.studiosArr, store.state.studio.toDosArr, store.state.app.user?.id as string));
    }

    if (mutation.payload instanceof StudioModuleDomain.ResetMutation) {
      await Services.StudioOfflineStorage.clear();
      await Services.ToDoOfflineStorage.clear();
    } else if (mutation.payload instanceof NotificationModuleDomain.ResetMutation) {
      await Services.NotificationOfflineStorage.clear();
    } else if (mutation.payload instanceof UserModuleDomain.ResetMutation) {
      await Services.UserOfflineStorage.clear();
    }
  }

  private mutationSessionTimestamp: number = Date.now();
  private mutationIncrementalNumber: number = 0;

  private async HandleMutation(mutation: MutationPayload): Promise<void> {
    if (debug) console.group(debugPrefix.replace('ic', 'ic Mutation'), `${mutation.type}`, mutation.payload);

    try {
      if (!(mutation.payload instanceof ModuleDomain.MutationBase)) throw new Error(`Mutation handled which is not a MutationBase ${mutation.type}`);
      
      if (ModuleDomain.IsMutationSendsToAppInsights(mutation.payload) === true) {
        Services.AppInsights.trackEvent({
          name: `SPA Mutation ${mutation.type}`,
          properties: {
            module: mutation.type.slice(0, mutation.type.indexOf('/')),
            mutation: mutation.type.slice(mutation.type.indexOf('/') + 1),
          },
        });
      }

      if (mutation.payload instanceof AppModuleDomain.SetBrowserOnlineMutation) {
        if (mutation.payload.isOnline === false || mutation.payload.isVisible === false) {
          this.isLowSpeedSync = true;
          if (debug) console.info(debugPrefix, `Low Speed Sync activated - isOnline: ${mutation.payload.isOnline} isVisible: ${mutation.payload.isVisible}`);
        } else {
          this.isLowSpeedSync = false;
          if (debug) console.info(debugPrefix, `Low Speed Sync deactivated - isOnline: ${mutation.payload.isOnline} isVisible: ${mutation.payload.isVisible}`);
          if (this.lastServerSyncInstant === null || (Date.now() - this.lastServerSyncInstant.getTime()) > (2 * 60 * 1000)) {
            this.scheduleServerSync(false);
          }
        }
      } else if (mutation.payload instanceof AuthenticationModuleDomain.InternalSignOutUserMutation) {
        store.commit(TipModuleDomain.ResetMutation.MutationName, new TipModuleDomain.ResetMutation());
        store.commit(NotificationModuleDomain.ResetMutation.MutationName, new NotificationModuleDomain.ResetMutation());
        store.commit(StudioModuleDomain.ResetMutation.MutationName, new StudioModuleDomain.ResetMutation());
        store.commit(UserModuleDomain.ResetMutation.MutationName, new UserModuleDomain.ResetMutation());
        store.commit(
          AppModuleDomain.SetLoggedInUserMutation.MutationName,
          new AppModuleDomain.SetLoggedInUserMutation(null, null));
        Services.AppInsights.clearAuthenticatedUserContext();
      } else if (mutation.payload instanceof AuthenticationModuleDomain.InternalSignInUserMutation) {
        const user = store.state.authentication.user;
        if (user !== null) {
          Services.AppInsights.setAuthenticatedUserContext(user.id, '', true);

          // restore me at this point (and not in restore) as that happens too late because of the initialisation issue.
          const meUser = await Services.AppStorage.getItem('me') as Domain.User | null;
          if (meUser !== null) {
            const userManager = AuthSideCar.getUserManager();
            if (userManager) {
              const sub = await AuthSideCar.getSub();
              if (meUser?.id !== sub) { 
                if (debug) console.error(debugPrefix.replace('ic', 'ic Mutation'), `ME.id ${meUser?.id} and IdToken.Sub ${sub} do not match.`);
                throw new Error(`ME.id ${meUser?.id} and IdToken.Sub ${sub} do not match.`);
              }
            }
          }

          store.commit(
            AppModuleDomain.SetLoggedInUserMutation.MutationName,
            new AppModuleDomain.SetLoggedInUserMutation(user, meUser));

          store.commit(
            UserModuleDomain.AddLoggedInUserMutation.MutationName,
            new UserModuleDomain.AddLoggedInUserMutation(
              new Domain.PublicUser(user.id, user.nickname, user.firstName, user.lastName, user.locale, user.pictureUrl)));

        } else { // useless code - just in case...
          Services.AppInsights.clearAuthenticatedUserContext();
          store.commit(
            AppModuleDomain.SetLoggedInUserMutation.MutationName,
            new AppModuleDomain.SetLoggedInUserMutation(null, null));
        }
      }
      if (mutation.payload instanceof AppModuleDomain.SetLastStudioRouteStudioIdMutation) {
        await Services.AppStorage.setItem('lastStudioId', mutation.payload.studioId);
      } else if (mutation.payload instanceof AppModuleDomain.PeriodChangedMutation) {
        await this.PerformSyncWork('ForceCompute', 5000, async () => {
          store.commit(TipModuleDomain.Compute.MutationName, new TipModuleDomain.Compute(store.state.studio.studiosArr, store.state.studio.toDosArr, store.state.app.user?.id as string));
        }, async () => { });
      } else if (mutation.payload instanceof AppModuleDomain.ForceSyncWithServerMutation) {
        this.scheduleServerSync(mutation.payload.forceFullSync);
      } else if (ModuleDomain.IsMutationAltersData(mutation.payload) === true && ModuleDomain.IsMutationRestore(mutation.payload) === false) {
        await this.HandleIModuleAltersData(mutation);
      } else if (ModuleDomain.IsMutationAltersMeUser(mutation.payload) === true && ModuleDomain.IsMutationRestore(mutation.payload) === false) {
        await this.HandleMeUserUpdate(mutation);
      }

      if (ModuleDomain.IsSyncToServer(mutation.payload) === true) {
        // save for server sync
        const mutationToStore = PersistanceHelper.CloneForPersistance(mutation.payload, `mutation ${mutation.type}`);
        delete mutationToStore.metadata_deletes;
        delete mutationToStore.metadata_upserts;
        delete mutationToStore.metadata_potentiallyNothingToPersist;
        mutationToStore.mutationType = mutation.type;
        mutationToStore.mutationTimestamp = this.mutationSessionTimestamp;
        mutationToStore.mutationTimestampDeep = this.mutationIncrementalNumber;
        await Services.MutationsToSyncStorage.setItem(mutationToStore.mutationId, mutationToStore);
        store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(store.state.app.pendingMutationsToSyncCount + 1));
        this.BroadcastMutationsToSendChanged(`mutation ${mutation.payload.mutationId} ${mutation.type}`);
        this.mutationIncrementalNumber++;

        this.scheduleServerSync(false);
      }
    } finally {
      if (debug) console.groupEnd();
      this.activityCounter--;
    }
  }

  private Broadcast(data: string): void {
    if (this.broadcastChannel === null) return;

    const tmp = data.split('|');
    const commandId = tmp[0];
    const commandParameters = tmp.slice(1);

    if (debug) console.info(debugPrefix, `Broadcast sent '${commandId}'`, commandParameters);

    this.broadcastChannel.postMessage(`${this.broadcastId}!${data}`);
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // tries to run something only once amongst the tabs and service worker. Ideally the sw would do the work and we can guarantee results.
  private async PerformSyncWork(workType: string, minimumIntervalInMilliseconds: number, work: { (): void }, noWork: { (): void}) {
    await this.delay((Math.random() * 200) + 50); // wait a rando thing to ensure things don't happen too much at the same time

    const lastTimeStamp = this.syncWorkLastTimestamps.get(workType);
    if (this.syncWorkLocks.has(workType) && this.syncWorkLocks.get(workType) === true) {
      if (lastTimeStamp !== undefined && (lastTimeStamp + (2 * 60 * 1000)) > Date.now()) {
        if (debug) console.warn(debugPrefix, `SyncWork '${workType}' taking control - seems other worker never finished`);
      } else {
        if (debug) console.warn(debugPrefix, `SyncWork '${workType}' ignoring - other worker`);
        await noWork();
        return;
      }
    }
    if (lastTimeStamp !== undefined) {
      if ((lastTimeStamp + minimumIntervalInMilliseconds) > Date.now()) {
        if (debug) console.warn(debugPrefix, `SyncWork '${workType}' ignoring - too soon`);
        await noWork();
        return;
      }
    }

    if (debug) console.group(debugPrefix, `SyncWork '${workType}'`);
    this.syncWorkLocks.set(workType, true);
    this.BroadcastSyncWorkStart(workType);
    try {
      await work();
    } finally {
      this.syncWorkLastTimestamps.set(workType, Date.now());
      this.syncWorkLocks.set(workType, false);
      this.BroadcastSyncWorkStop(workType);
      if (debug) console.groupEnd();
    }
  }

  private BroadcastSyncWorkStart(workType: string) {
    this.Broadcast(`SyncWorkStart|${workType}`);
  }

  private BroadcastSyncWorkStop(workType: string): void {
    this.Broadcast(`SyncWorkStop|${workType}`);
  }

  private BroadcastMeUpdate(): void {
    this.Broadcast('MeUpdated');
  }

  private BroadcastEntityUpsert(entityType: string, entityId: string): void {
    this.Broadcast(`EntityUpsert|${entityType}|${entityId}`);
  }

  private BroadcastEntityRemoved(entityType: string, entityId: string): void {
    this.Broadcast(`EntityRemoved|${entityType}|${entityId}`);
  }

  private BroadcastPing(): void {
    this.Broadcast('Ping');
  }

  private BroadcastPong(targetId: string): void {
    this.Broadcast(`Pong|${targetId}`);
  }

  private BroadcastEvent(eventName: string): void {
    this.Broadcast(`Event|${eventName}`);
  }

  private BroadcastMutationsToSendChanged(reason: string): void {
    this.Broadcast(`MutationsToSendChanged|${reason}`);
  }

  private BroadcastMutationCommitted(mutationId: string, status: string): void {
    this.Broadcast(`MutationCommitted|${mutationId}|${status}`);
  }

  private async HandleBroadcastMessage(evt: MessageEvent): Promise<void> {
    let tmp = (evt.data as string).split('!');
    const targetId = tmp[0];
    tmp = tmp[1].split('|');
    const commandId = tmp[0];
    const commandParameters = tmp.slice(1);
    try {
      if (debug) console.group(debugPrefix, `Broadcast received from ${targetId} '${commandId}'`, commandParameters);

      if (commandId === 'Ping') {
        this.BroadcastPong(targetId);
      } else if (commandId === 'Pong') {
        // do nothing
      } else if (commandId === 'Event') {
        const isCurrentlySignedIn = store.getters['authentication/isSignedIn'];
        if (commandParameters[0] === 'sign-in' && isCurrentlySignedIn === false) await this.RestartApp();
        if (commandParameters[0] === 'sign-in' && isCurrentlySignedIn === true) { } 
        else if (commandParameters[0] === 'sign-out' && isCurrentlySignedIn === true) await this.RestartApp();
        else if (commandParameters[0] === 'sign-out' && isCurrentlySignedIn === false) { }
        else {
          throw new Error(`VueXMagic HandleBroadcastMessage invalid event received : ${commandParameters[0]}`);
        }

      } else if (commandId === 'MeUpdated') {
        const meUser = await Services.AppStorage.getItem('me');
        if (meUser !== null) {
          if (debug) console.info(debugPrefix, 'Restoring me');
          const payload = new AppModuleDomain.RestoreMeUserMutation(meUser as Domain.User);
          store.commit(AppModuleDomain.RestoreMeUserMutation.MutationName, payload);
        }
      } else if (commandId === 'MutationsToSendChanged') {
        if (commandParameters[0].startsWith('mutation')) {
          store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(store.state.app.pendingMutationsToSyncCount + 1));
        } else if (commandParameters[0].startsWith('sync')) {
          const mutationsToSyncCount = (await Services.MutationsToSyncStorage.keys()).length;
          store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(mutationsToSyncCount));
        }
      } else if (commandId === 'SyncWorkStart') {
        this.syncWorkLastTimestamps.set(commandParameters[0], Date.now());
        this.syncWorkLocks.set(commandParameters[0], true);
      } else if (commandId === 'SyncWorkStop') {
        this.syncWorkLastTimestamps.set(commandParameters[0], Date.now());
        this.syncWorkLocks.set(commandParameters[0], false);
      } else if (commandId === 'EntityUpsert') {
        if (commandParameters[0] === 'Notification') {
          const notifiactionToUpsert = await Services.NotificationOfflineStorage.getItem(commandParameters[1]) as Domain.Notification;
          store.commit(NotificationModuleDomain.RestoreMutation.MutationName, new NotificationModuleDomain.RestoreMutation([notifiactionToUpsert], []));
        } else if (commandParameters[0] === 'PublicUser') {
          const userToUpsert = await Services.UserOfflineStorage.getItem(commandParameters[1]) as Domain.PublicUser;
          store.commit(UserModuleDomain.RestoreMutation.MutationName, new UserModuleDomain.RestoreMutation([userToUpsert], []));
        } else if (commandParameters[0] === 'Studio') {
          const studioToUpsert = await Services.StudioOfflineStorage.getItem(commandParameters[1]) as Domain.Studio;
          store.commit(StudioModuleDomain.RestoreMutation.MutationName, new StudioModuleDomain.RestoreMutation([studioToUpsert], [], [], []));
        } else if (commandParameters[0] === 'ToDo') {
          const toDoToUpsert = await Services.ToDoOfflineStorage.getItem(commandParameters[1]) as Domain.ToDo;
          store.commit(StudioModuleDomain.RestoreMutation.MutationName, new StudioModuleDomain.RestoreMutation([], [toDoToUpsert], [], []));
        } 
      } else if (commandId === 'EntityRemoved') {
        if (commandParameters[0] === 'Notification') {
          store.commit(NotificationModuleDomain.RestoreMutation.MutationName, new NotificationModuleDomain.RestoreMutation([], [commandParameters[1]]));
        } else if (commandParameters[0] === 'PublicUser') {
          store.commit(UserModuleDomain.RestoreMutation.MutationName, new UserModuleDomain.RestoreMutation([], [commandParameters[1]]));
        } else if (commandParameters[0] === 'Studio') {
          store.commit(StudioModuleDomain.RestoreMutation.MutationName, new StudioModuleDomain.RestoreMutation([], [], [commandParameters[1]], []));
        } else if (commandParameters[0] === 'ToDo') {
          store.commit(StudioModuleDomain.RestoreMutation.MutationName, new StudioModuleDomain.RestoreMutation([], [], [], [commandParameters[1]]));
        } 
      } else if (commandId === 'MutationCommitted') {
        EventBus.Bus.Publish(EventBus.EventKind.MutationCommitted, { mutationId: commandParameters[0], status: commandParameters[1] });
      } else {
        if (debug) console.error(debugPrefix, `Invalid command ${commandId}`);
      }
    } finally {
      if (debug) console.groupEnd();
    }
  }

  private async ResetLocalDatabases(includeMutations: boolean): Promise<void> {
    if (debug) console.info(debugPrefix, 'Clearing user data' + (includeMutations ? ' and mutationsToSync' : ''));
    await Services.ResetLocalDatabases(includeMutations);

    if (includeMutations) {
      store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(0));
    }
  }

  private async HandleRestoreError() {
    if (debug) console.error(debugPrefix, 'Error restoring local data');
    if (process.env.NODE_ENV !== 'production') {
      Services.Toaster.error('Error restoring local user data', {
        duration: 5000,
        action: {
          text: 'Clear & Restart',
          onClick: async () => {
            await this.ResetLocalDatabases(true);
            await this.RestartApp();
          },
        },
      });
    } else {
      Services.AppInsights.trackEvent({ name: 'ErrorRestoringLocalData' });
      await this.ResetLocalDatabases(true);
      await this.RestartApp();
    }
  }

  private async HandleServerSynchError() {
    if (debug) console.error(debugPrefix, 'Error synching server data');
    if (process.env.NODE_ENV !== 'production') {
      Services.Toaster.error('Error synching server data', {
        duration: 5000,
        action: {
          text: 'Clear & Restart',
          onClick: async () => {
            await this.ResetLocalDatabases(true);
            await this.RestartApp();
          },
        },
      });
    } else {
      Services.AppInsights.trackEvent({ name: 'ErrorSynchingServerData' });
      await this.ResetLocalDatabases(false);
      await this.RestartApp();
    }
  }

  private async Restore(): Promise<void> {
    if (store.getters['authentication/isSignedIn'] as boolean === false) {
      if (debug) console.warn(debugPrefix, 'Not signed in - clearing local db and not restoring');
      await this.ResetLocalDatabases(true);
      return;
    }

    try {
      if (debug) console.group(debugPrefix, 'Restoring');
      this.isRestoring = true;

      // restore users
      const usersToRestore: Domain.PublicUser[] = [];
      await Services.UserOfflineStorage.iterate((item: Domain.PublicUser) => { // signature (item, value, iterationNumber)
        usersToRestore.push(item);
      });
      if (usersToRestore.length !== 0) {
        if (debug) console.info(debugPrefix, `Restoring ${usersToRestore.length} public users`);
        const payloadRestoreUsers = new UserModuleDomain.RestoreMutation(usersToRestore, []);
        store.commit(UserModuleDomain.RestoreMutation.MutationName, payloadRestoreUsers);
        if (payloadRestoreUsers.out_error === true) await this.HandleRestoreError();
      }

      // restore studios studios and todos
      const studiosToRestore: Domain.Studio[] = [];
      const toDosToRestore: Domain.ToDo[] = [];
      await Services.StudioOfflineStorage.iterate((item: Domain.Studio) => { // signature (item, value, iterationNumber)
        studiosToRestore.push(item);
      });
      await Services.ToDoOfflineStorage.iterate((item: Domain.ToDo) => { // signature (item, value, iterationNumber)
        toDosToRestore.push(item);
      });
      if (studiosToRestore.length !== 0) {
        if (debug) console.info(debugPrefix, `Restoring ${studiosToRestore.length} studios and ${toDosToRestore.length} toDos`);
        const payloadRestoreStudios = new StudioModuleDomain.RestoreMutation(studiosToRestore, toDosToRestore, [], []);
        store.commit(StudioModuleDomain.RestoreMutation.MutationName, payloadRestoreStudios);
        if (payloadRestoreStudios.out_error === true) await this.HandleRestoreError();
      }

      // restore notifications
      const notificationsToRestore: Domain.Notification[] = [];
      await Services.NotificationOfflineStorage.iterate((item: Domain.Notification) => { // signature (item, value, iterationNumber)
        notificationsToRestore.push(item);
      });
      if (notificationsToRestore.length !== 0) {
        if (debug) console.info(debugPrefix, `Restoring ${notificationsToRestore.length} notifications`);
        const payloadRestoreNotifications = new NotificationModuleDomain.RestoreMutation(notificationsToRestore, []);
        store.commit(NotificationModuleDomain.RestoreMutation.MutationName, payloadRestoreNotifications);
        if (payloadRestoreNotifications.out_error === true) await this.HandleRestoreError();
      }

      // restore App stuff
      const appRestore = new AppModuleDomain.RestoreMutation();
      const lastStudioId = await Services.AppStorage.getItem('lastStudioId');
      if (lastStudioId !== null) {
        if (store.state.studio.studios.has(lastStudioId as string)) {
          if (debug) console.info(debugPrefix, `Restoring lastStudioId ${lastStudioId}`);
          appRestore.lastStudioId = lastStudioId as string;
        }
      }
      store.commit(AppModuleDomain.RestoreMutation.MutationName, appRestore);

      store.commit(TipModuleDomain.Compute.MutationName, new TipModuleDomain.Compute(store.state.studio.studiosArr, store.state.studio.toDosArr, store.state.app.user?.id as string));
    } finally {
      this.isRestoring = false;
      if (debug) console.groupEnd();
    }
  }

  public async EnsureAccessTokens():Promise<void> {
    
    const accessTokenIsExpired = await AuthSideCar.isAccessTokenExpired();
    if (accessTokenIsExpired === false) { return; }

    if (debug) console.info(debugPrefix, `Access Token expired - renewing silently`);
    const newUser = await AuthSideCar.getUserManager()!.signinSilent();
    if (newUser !== null) { return; }

    try {
      if (debug) console.info(debugPrefix, `Access Token expired - renewing with popup`);
      await AuthSideCar.getUserManager()!.signinPopup();

      const newUser = await AuthSideCar.getUserManager()!.getUser();
      if (!newUser?.access_token) {
        throw new Error('Access token not renewed not thrown');
      }
    } catch {
      if (debug) console.error(debugPrefix, 'Access Token expired - renewing with redirect');

      await AuthSideCar.getUserManager()!.signinRedirect();
      throw new Error('could not renew access token.');
    }
  }

  public async PreAppModuleDomainInitializeAction(): Promise<void> {
    debugPrefix = Services.Chalk.bgBlueBright(' VuexMagic ');
    debug = await Services.IsDebugOverride('VuexMagic');

    this.serverNormalSyncInterval = await Services.GetOverrideValueNumber('VuexMagic', 'SyncIntervalInMilliseconds', this.serverNormalSyncInterval );

    try {
      if (debug) console.group(debugPrefix, 'Pre-Initialization');
      if (debug) console.info(debugPrefix, `Starting - ${window.document.URL}`);

      Services.AppInsights.clearAuthenticatedUserContext();

      // manage version
      const dbVersion = await Services.AppPersistantStorage.getItem('Version') as string | null;
      if (dbVersion === null) {
        await Services.AppPersistantStorage.setItem('Version', store.getters['config/version']);
      } else if (dbVersion !== store.getters['config/version']) {
        if (debug) console.warn(debugPrefix, `Upgrading from ${dbVersion} to ${store.getters['config/version']}`);
        Services.AppInsights.trackEvent({ name: 'UpdateMarker' }, { from: dbVersion, to: store.getters['config/version'] });
        await this.ResetLocalDatabases(false);
        await Services.AppPersistantStorage.setItem('Version', store.getters['config/version']);
      }
    } finally {
      if (debug) console.groupEnd();
    }
  }

  public async PostAppModuleDomainInitializeAction(): Promise<void> {
    try {
      if (debug) console.group(debugPrefix, 'Post-Initialization');

      // start broadcast channel
      if (typeof window.BroadcastChannel !== 'function') {
        console.error(debugPrefix, 'BroadcastChannel not supported');
      } else {
        this.broadcastId = UUID.genV1().toString();
        if (debug) console.info(debugPrefix, `Broadcast ready - ${this.broadcastId}`);
        this.broadcastChannel = new window.BroadcastChannel('TDS');
        this.broadcastChannel.addEventListener('message', async e => { await this.HandleBroadcastMessage(e as MessageEvent); });
        this.BroadcastPing();
      }

      // start period checker
      store.commit(AppModuleDomain.PeriodChangedMutation.MutationName, new AppModuleDomain.PeriodChangedMutation());
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const setTimeoutInstance = this; // this is required to allow the settimeout lambda to capture "this"
      const nextPeriodChangeInterval = DateHelper.getIntervalToNextPeriod();
      if (debug) console.info(debugPrefix, `Next period change in ${nextPeriodChangeInterval / 1000 / 60} minutes`);
      this.periodTimeout = window.setTimeout(() => { setTimeoutInstance.periodTimeoutFunction(); }, nextPeriodChangeInterval);

    } finally {
      if (debug) console.groupEnd();
    }
  }

  private scheduleServerSync(forceFull: boolean = false) {
    if (this.serverSyncTimeout !== null) {
      window.clearTimeout(this.serverSyncTimeout);
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const setTimeoutInstance = this; // this is required to allow the settimeout lambda to capture "this"
    this.serverSyncTimeout = window.setTimeout(() => { setTimeoutInstance.serverSyncTimeoutFunction(forceFull); }, 100);
  }

  private async serverSyncTimeoutFunction(forceFull: boolean = false): Promise<void> {
    this.serverSyncTimeout = null;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const setTimeoutInstance = this; // this is required to allow the settimeout lambda to capture "this"

    await this.PerformSyncWork('ServerSync', 500, async () => {
      await setTimeoutInstance.serverSync(forceFull);

      const nextServerSyncInterval = setTimeoutInstance.getNextSyncInterval();
      if (debug) console.info(debugPrefix, `Next ServerSync in ${nextServerSyncInterval / 1000 / 60} minutes`);
      setTimeoutInstance.serverSyncTimeout = window.setTimeout(() => { setTimeoutInstance.serverSyncTimeoutFunction(); }, nextServerSyncInterval);
    }, async () => {
      const nextServerSyncInterval = setTimeoutInstance.getNextSyncInterval();
      if (debug) console.info(debugPrefix, `Next ServerSync in ${nextServerSyncInterval / 1000 / 60} minutes`);
      setTimeoutInstance.serverSyncTimeout = window.setTimeout(() => { setTimeoutInstance.serverSyncTimeoutFunction(); }, nextServerSyncInterval);
    });
  }

  private getNextSyncInterval(): number {
    if (this.isHighSpeedSync !== null && this.isHighSpeedSync > new Date()) return this.serverHighSpeedSyncInterval;
    if (this.isLowSpeedSync === true) return this.serverLowSpeedSyncInterval;

    return this.serverNormalSyncInterval;
  }

  private async serverSync(forceFull: boolean) {
    if (debug) console.info(debugPrefix, `ServerSync at ${new Date()} last ServerSync at ${this.lastServerSyncInstant}`);
    this.lastServerSyncInstant = new Date();
    if (store.getters['authentication/isSignedIn'] === false) {
      if (debug) console.warn(debugPrefix, 'ServerSync ignored because no user logged in');
      return;
    }
    if (store.getters['app/isOnline'] === false) {
      if (debug) console.warn(debugPrefix, 'ServerSync ignored because app is offline');
      return;
    }
    if (store.state.app.isSynching === true) {
      if (debug) console.warn(debugPrefix, 'ServerSync ignored because app is synching');
      return;
    }

    await this.EnsureAccessTokens();
    

    store.commit(AppModuleDomain.SetSynchingStatusMutation.MutationName, new AppModuleDomain.SetSynchingStatusMutation(true));
    let isSyncError = false;
    try {
      this.isSynching = true;
      let c = 1;
      let errorOccured = false;
      while (errorOccured === false) {
        // send mutations
        let mutationsToSyncKeys = await Services.MutationsToSyncStorage.keys();
        if (mutationsToSyncKeys.length === 0 && c !== 1) break;
        if (c !== 1) if (debug) console.info(debugPrefix, `Synching again round=${c} `);
        c++;

        store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(mutationsToSyncKeys.length));

        if (mutationsToSyncKeys.length > 0) {
          if (debug) console.info(debugPrefix, `${mutationsToSyncKeys.length} Mutations to sync found`);
          const mutations: any[] = [];
          await asyncForEach(mutationsToSyncKeys, async (item) => {
            const itemData: any = await Services.MutationsToSyncStorage.getItem(item);
            mutations.push(itemData);
          });
          const mutationSyncResult = await (Vue as any).axios.post(`${store.state.config.currentServer.apiUrl}/api/easySync/mutate`, mutations);

          if (mutationSyncResult.status >= 200 && mutationSyncResult.status < 300) {

            let succeededCount = 0;
            let failedCount = 0;
            let skippedCount = 0;
            let ignoreCount = 0;
            let unknownCount = 0;

            await asyncForEach(mutationSyncResult.data.mutationResults, async (mutationResult) => {
              if (mutationResult.status === 'succeeded') {
                succeededCount++;
                await Services.MutationsToSyncStorage.removeItem(mutationResult.mutationId);

                if (mutationResult.mutationType === 'studio/createTeammateInvitation' || 
                    mutationResult.mutationType === 'studio/createStudioInvitation' || 
                    mutationResult.mutationType === 'studio/processStudioInvitation') { 
                  this.isHighSpeedSync = new Date(Date.now() + (30 * 60 * 1000));  // 30 minutes of high speed syncs when creating invites or accepting one.
                  if (debug) console.info(debugPrefix, `High Speed Sync activated for ${mutationResult.mutationType} till ${this.isHighSpeedSync}`);
                }
              }
              if (mutationResult.status === 'ignored') {
                ignoreCount++;
                await Services.MutationsToSyncStorage.removeItem(mutationResult.mutationId);
                console.warn(debugPrefix, `Server ignored mutation ${mutationResult.mutationType} ${mutationResult.mutationId}`);
              }
              else if (mutationResult.status === 'failed') {
                await Services.MutationsToSyncStorage.removeItem(mutationResult.mutationId);
                console.error(debugPrefix, `Server failed mutation ${mutationResult.mutationType} ${mutationResult.mutationId}`);
                Services.AppInsights.trackEvent({ name: 'ServerFailedMutation' }, { mutationType: mutationResult.mutationType, mutationId: mutationResult.mutationId });
                failedCount++;
              } 
              else if (mutationResult.status === 'skipped') skippedCount++;
              else unknownCount++;
              this.BroadcastMutationCommitted(mutationResult.mutationId, mutationResult.status);
              EventBus.Bus.Publish(EventBus.EventKind.MutationCommitted, { mutationId: mutationResult.mutationId, status: mutationResult.status });
            });
            if ((succeededCount + ignoreCount) !== mutationsToSyncKeys.length) errorOccured = true;
            if (debug) console.info(debugPrefix, `Mutations ${mutationsToSyncKeys.length} committed: ${succeededCount} succeeded, ${ignoreCount} ignored, ${failedCount} failed, ${skippedCount} skipped, ${unknownCount} unknown`);

            mutationsToSyncKeys = await Services.MutationsToSyncStorage.keys();
            store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(mutationsToSyncKeys.length));
            this.BroadcastMutationsToSendChanged('Sync');
          } else {
            console.error(debugPrefix, `Failed mutations commit ${mutationSyncResult.status}`);
            errorOccured = true;
          }
        }
        mutationsToSyncKeys = await Services.MutationsToSyncStorage.keys();
        if (mutationsToSyncKeys.length !== 0 && errorOccured === false) continue; // don't sync right away. there are new mutations to be sent

        // sync
        const lastSyncToken: string | null = await Services.AppStorage.getItem('serverSyncToken');
        const lastFullSyncInstantString: string | null = await Services.AppStorage.getItem('serverSyncLastFullInstant');
        const lastFullSyncInstant: number | null = lastFullSyncInstantString === null ? null : parseInt(lastFullSyncInstantString);
        const isDueForFullSync = lastFullSyncInstant === null ? true : (Date.now() - lastFullSyncInstant) > (4 * 24 * 60 * 60 * 1000); //4 days
        let syncResult: any = null;
        let isFullSync = forceFull === true || lastSyncToken === null || isDueForFullSync === true;
        if (isFullSync) {
          if (debug) console.info(debugPrefix, 'Performing FULL server sync');
          syncResult = await (Vue as any).axios.get(`${store.state.config.currentServer.apiUrl}/api/easySync/sync?isBackgroundSync=${this.isLowSpeedSync ? 'true' : 'false'}`);
        } else {
          if (debug) console.info(debugPrefix, `Performing INCREMENTAL server sync with token ${lastSyncToken}`);
          syncResult = await (Vue as any).axios.get(`${store.state.config.currentServer.apiUrl}/api/easySync/sync?isBackgroundSync=${this.isLowSpeedSync ? 'true' : 'false'}&lastSyncToken=${lastSyncToken}`);
        }
        if (syncResult.status < 200 || syncResult.status >= 300) {
          console.error(debugPrefix, `Failed server sync ${syncResult.status}`);
          isSyncError = true;
          return;
        }

        isFullSync = syncResult.data.isFullSync;
        if (debug) console.info(debugPrefix, `Server returned fullsync : ${isFullSync}`);

        // restore me
        if (debug) console.info(debugPrefix, 'Synching profile');
        const me: Domain.User = this.FixupServerSyncDataElement('user', syncResult.data.profile);
        const payloadRestoreMe = new AppModuleDomain.RestoreMeUserMutation(me);
        store.commit(AppModuleDomain.RestoreMeUserMutation.MutationName, payloadRestoreMe);
        await this.HandleMeUserUpdate(null);

        // restore users
        const usersToRestore: Domain.PublicUser[] = this.FixupServerSyncData('publicUser', syncResult.data.users);
        if (usersToRestore.length !== 0  || isFullSync) {
          await asyncForEach(usersToRestore, async (element) => { if (element.etag === store.state.user.users.get(element.id)?.etag) return; await Services.UserOfflineStorage.setItem(element.id, element); this.BroadcastEntityUpsert('PublicUser', element.id); });
          if (debug) console.info(debugPrefix, `Synching Upserting ${usersToRestore.length} users`);
          
          let idsToDelete: string[] = [];
          if (isFullSync) {
            const allKeys = await Services.UserOfflineStorage.keys();
            const keysToKeep = usersToRestore.map((element) => element.id);
            idsToDelete = allKeys.filter((element) => keysToKeep.indexOf(element) === -1);
            await asyncForEach(idsToDelete, async (element) => { await Services.UserOfflineStorage.removeItem(element); this.BroadcastEntityRemoved('PublicUser', element); });
            if (debug) console.info(debugPrefix, `Synching Removing ${idsToDelete.length} users`);
          }
          
          const payloadRestoreUsers = new UserModuleDomain.RestoreMutation(usersToRestore, idsToDelete);
          store.commit(UserModuleDomain.RestoreMutation.MutationName, payloadRestoreUsers);
          if (payloadRestoreUsers.out_error === true) await this.HandleServerSynchError();
        }

        // restore studios studios and todos
        const studiosToRestore: Domain.Studio[] = this.FixupServerSyncData('studio', syncResult.data.studios);
        const toDosToRestore: Domain.ToDo[] = this.FixupServerSyncData('toDo', syncResult.data.toDos);
        if (studiosToRestore.length !== 0 || isFullSync) {
          await asyncForEach(studiosToRestore, async (element) => { if (element.etag === store.state.studio.studios.get(element.id)?.etag) return; await Services.StudioOfflineStorage.setItem(element.id, element); this.BroadcastEntityUpsert('Studio', element.id); });
          await asyncForEach(toDosToRestore, async (element) => { if (element.etag === store.state.studio.toDos.get(element.id)?.etag) return; await Services.ToDoOfflineStorage.setItem(element.id, element); this.BroadcastEntityUpsert('ToDo', element.id); });
          if (debug) console.info(debugPrefix, `Synching Upserting ${studiosToRestore.length} studios and ${toDosToRestore.length} toDos`);

          let toDoIdsToDelete: string[] = [];
          if (isFullSync) {
            const allKeys = await Services.ToDoOfflineStorage.keys();
            const keysToKeep = toDosToRestore.map((element) => element.id);
            toDoIdsToDelete = allKeys.filter((element) => keysToKeep.indexOf(element) === -1);
            await asyncForEach(toDoIdsToDelete, async (element) => { await Services.ToDoOfflineStorage.removeItem(element); this.BroadcastEntityRemoved('ToDo', element); });
            if (debug) console.info(debugPrefix, `Synching Removing ${toDoIdsToDelete.length} toDos`);
          }
          let studioIdsToDelete: string[] = [];
          if (isFullSync) {
            const allKeys = await Services.StudioOfflineStorage.keys();
            const keysToKeep = studiosToRestore.map((element) => element.id);
            studioIdsToDelete = allKeys.filter((element) => keysToKeep.indexOf(element) === -1);
            await asyncForEach(studioIdsToDelete, async (element) => { await Services.StudioOfflineStorage.removeItem(element); this.BroadcastEntityRemoved('Studio', element); });
            if (debug) console.info(debugPrefix, `Synching Removing ${studioIdsToDelete.length} studios`);
          }

          const payloadRestoreStudios = new StudioModuleDomain.RestoreMutation(studiosToRestore, toDosToRestore, studioIdsToDelete, toDoIdsToDelete);
          store.commit(StudioModuleDomain.RestoreMutation.MutationName, payloadRestoreStudios);
          if (payloadRestoreStudios.out_error === true) await this.HandleServerSynchError();
        }

        // restore notifications
        const notificationsToRestore: Domain.Notification[] = this.FixupServerSyncData('notification', syncResult.data.notifications);
        if (notificationsToRestore.length !== 0  || isFullSync) {
          await asyncForEach(notificationsToRestore, async (element) => { if (element.etag === store.state.notification.notifications.get(element.id)?.etag) return; await Services.NotificationOfflineStorage.setItem(element.id, element); this.BroadcastEntityUpsert('Notification', element.id); });
          if (debug) console.info(debugPrefix, `Synching Upserting ${notificationsToRestore.length} notifications`);
          
          let idsToDelete: string[] = [];
          if (isFullSync) {
            const allKeys = await Services.NotificationOfflineStorage.keys();
            const keysToKeep = notificationsToRestore.map((element) => element.id);
            idsToDelete = allKeys.filter((element) => keysToKeep.indexOf(element) === -1);
            await asyncForEach(idsToDelete, async (element) => { await Services.NotificationOfflineStorage.removeItem(element); this.BroadcastEntityRemoved('Notification', element); });
            if (debug) console.info(debugPrefix, `Synching Removing ${idsToDelete.length} notifications`);
          }
          
          const payloadRestoreNotifications = new NotificationModuleDomain.RestoreMutation(notificationsToRestore, idsToDelete);
          store.commit(NotificationModuleDomain.RestoreMutation.MutationName, payloadRestoreNotifications);
          if (payloadRestoreNotifications.out_error === true) await this.HandleServerSynchError();
        }

        await Services.AppStorage.setItem('serverSyncToken', syncResult.data.syncToken);
        if (isFullSync) await Services.AppStorage.setItem('serverSyncLastFullInstant', Date.now());
        if (debug) console.info(debugPrefix, `Finished server sync, token is ${syncResult.data.syncToken}`);
        EventBus.Bus.Publish(EventBus.EventKind.SyncFinished, { });
      }
      if (errorOccured) isSyncError = true;

      store.commit(TipModuleDomain.Compute.MutationName, new TipModuleDomain.Compute(store.state.studio.studiosArr, store.state.studio.toDosArr, store.state.app.user?.id as string));

    } catch (e) {
      isSyncError = true;
      throw e;
    }
    finally {
      this.isSynching = false;
      if (isSyncError) {
        await Services.AppStorage.setItem('serverSyncToken', null);
        await Services.AppStorage.setItem('serverSyncLastFullInstant', null);
      }
      if (store.state.app.isFirstSyncDone === false) {
        setTimeout(() => {
          store.commit(AppModuleDomain.SetSynchingStatusMutation.MutationName, new AppModuleDomain.SetSynchingStatusMutation(false, isSyncError));
        }, 500); // show the screen to the user a few moments...
      } else {
        store.commit(AppModuleDomain.SetSynchingStatusMutation.MutationName, new AppModuleDomain.SetSynchingStatusMutation(false, isSyncError));
      }
    }
  }

  private FixupServerSyncDataElement(kind: string, item: any): any {
    if (kind === 'publicUser') { // -------------------------------------- PUBLICUSER
      item.type = 'PublicUser';
      item.lastSeenInstant = new Date(item.lastSeenInstant);
      delete item.initials;
      delete item.fullName;

      const tags = new Map<string, Domain.Tag>();
      item.tags.forEach((innerItem) => {
        tags.set(innerItem.id, innerItem);
        innerItem.type = 'Tag';
      });
      item.tags = tags;

    } else if (kind === 'user') { // -------------------------------------- USER
      item.type = 'User';
      item.creationInstant = new Date(item.creationInstant);
      item.lastSeenInstant = new Date(item.lastSeenInstant);
      if (item.subscriptionDetails !== null) {
        item.subscriptionDetails.type = 'SubscriptionDetails';
      }

      const tags = new Map<string, Domain.Tag>();
      item.tags.forEach((innerItem) => {
        tags.set(innerItem.id, innerItem);
        innerItem.type = 'Tag';
      });
      item.tags = tags;

      delete item.initials;
      delete item.fullName;
      delete item.usage;

    } else if (kind === 'studio') { // -------------------------------------- STUDIO
      item.type = item.$type;
      item.creationInstant = new Date(item.creationInstant);
      if (item.closedInstant !== null) item.closedInstant = new Date(item.closedInstant);
      
      const teammates = new Map<string, Domain.Teammate>();
      item.teammates.forEach((innerItem) => {
        teammates.set(innerItem.userId, innerItem);
        innerItem.type = 'Teammate';
        innerItem.creationInstant = new Date(innerItem.creationInstant);
        if (innerItem.departure !== null) {
          innerItem.departure.type = 'StudioDeparture';
          innerItem.departure.creationInstant = new Date(innerItem.departure.creationInstant);
        }

        const tags = new Map<string, Domain.Tag>();
        innerItem.tags.forEach((innerInnerItem) => {
          tags.set(innerInnerItem.id, innerInnerItem);
          innerInnerItem.type = 'Tag';
        });
        innerItem.tags = tags;
      });
      item.teammates = teammates;

      const userStates = new Map<string, Domain.StudioUserState>();
      item.userStates.forEach((innerItem) => {
        userStates.set(innerItem.userId, innerItem);
        innerItem.type = 'StudioUserState';
      });
      item.userStates = userStates;

      const actions = new Map<string, Domain.Action>();
      item.actions.forEach((innerItem) => {
        actions.set(innerItem.id, innerItem);
        innerItem.type = innerItem.$type;
        delete innerItem.$type;
        innerItem.creationInstant = new Date(innerItem.creationInstant);
        innerItem.syncInstant = new Date(innerItem.syncInstant);
        if (innerItem.type === 'StudioInvitationCreated') {
          innerItem.expirationInstant = new Date(innerItem.expirationInstant);
        }
      });
      item.actions = actions;

      const toDoTemplates = new Map<string, Domain.ToDoTemplate>();
      item.toDoTemplates.forEach((innerItem) => {
        toDoTemplates.set(innerItem.id, innerItem);
        innerItem.type = innerItem.$type;
        delete innerItem.$type;
        innerItem.lastUpdateInstant = new Date(innerItem.lastUpdateInstant);
        innerItem.items.forEach((innerInnerItem) => {
          delete innerInnerItem.$type;
        });
      });
      item.toDoTemplates = toDoTemplates;

      const tags = new Map<string, Domain.Tag>();
      item.tags.forEach((innerItem) => {
        tags.set(innerItem.id, innerItem);
        innerItem.type = 'Tag';
      });
      item.tags = tags;

    } else if (kind === 'toDo') { // -------------------------------------- TODO
      item.type = item.$type;
      item.creationInstant = new Date(item.creationInstant);
      item.dueInstant = item.dueInstant === null ? null : new Date(item.dueInstant);
      item.startedInstant = item.startedInstant === null ? null : new Date(item.startedInstant);
      item.completionInstant = item.completionInstant === null ? null : new Date(item.completionInstant);
      item.cancellationInstant = item.cancellationInstant === null ? null : new Date(item.cancellationInstant);
      if (item.externalId) item.externalId.type = "ExternalId";
      else item.externalId = null;

      const userStates = new Map<string, Domain.ToDoUserState>();
      item.userStates.forEach((innerItem) => {
        userStates.set(innerItem.userId, innerItem);
        innerItem.type = 'ToDoUserState';
        innerItem.lastMessageSeenInstant = new Date(innerItem.lastMessageSeenInstant);

        if (innerItem.lastInvolvement !== null) {
          innerItem.lastInvolvement.type = innerItem.lastInvolvement.$type;
          delete innerItem.lastInvolvement.$type;
          innerItem.lastInvolvement.creationInstant = new Date(innerItem.lastInvolvement.creationInstant);
          innerItem.lastInvolvement.syncInstant = new Date(innerItem.lastInvolvement.syncInstant);
          if (innerItem.lastInvolvement.type === 'Postponed') {
            innerItem.lastInvolvement.postponementInstant = new Date(innerItem.lastInvolvement.postponementInstant);
          }
          if (innerItem.lastInvolvement.type === 'Committed') {
            innerItem.lastInvolvement.commitmentInstant = new Date(innerItem.lastInvolvement.commitmentInstant);
          }
        }

        if (innerItem.lastProgress !== null) {
          innerItem.lastProgress.type = 'Progressed';
          innerItem.lastProgress.creationInstant = new Date(innerItem.lastProgress.creationInstant);
          innerItem.lastProgress.syncInstant = new Date(innerItem.lastProgress.syncInstant);
          innerItem.lastProgress.progressInstant = new Date(innerItem.lastProgress.progressInstant);
        }

      });
      item.userStates = userStates;

      const actions = new Map<string, Domain.Action>();
      item.actions.forEach((innerItem) => {
        actions.set(innerItem.id, innerItem);
        innerItem.type = innerItem.$type;
        delete innerItem.$type;
        innerItem.creationInstant = new Date(innerItem.creationInstant);
        innerItem.syncInstant = new Date(innerItem.syncInstant);
        if (innerItem.type === 'Postponed') {
          innerItem.postponementInstant = new Date(innerItem.postponementInstant);
        }
        if (innerItem.type === 'Committed') {
          innerItem.commitmentInstant = new Date(innerItem.commitmentInstant);
        }
        if (innerItem.type === 'Progressed') {
          innerItem.progressInstant = new Date(innerItem.progressInstant);
        }
        if (innerItem.type === 'ToDoDueInstantChanged')
        {
          innerItem.oldValue = innerItem.oldValue === null ? null : new Date(innerItem.oldValue);
          innerItem.newValue = innerItem.newValue === null ? null : new Date(innerItem.newValue);
        }
      });
      item.actions = actions;

      const tags = new Map<string, Domain.Tag>();
      item.tags.forEach((innerItem) => {
        tags.set(innerItem.id, innerItem);
        innerItem.type = 'Tag';
      });
      item.tags = tags;

      const steps = new Map<string, Domain.Step>();
      item.steps.forEach((innerItem) => {
        tags.set(innerItem.id, innerItem);
        innerItem.type = 'Step';
        innerItem.creationInstant = new Date(innerItem.creationInstant);
        innerItem.deletionInstant = innerItem.deletionInstant === null ? null : new Date(innerItem.deletionInstant);
        if (innerItem.progress !== null) {
          innerItem.progress.type = innerItem.progress.$type;
          innerItem.progress.syncInstant = new Date(innerItem.progress.syncInstant);
          innerItem.progress.creationInstant = new Date(innerItem.progress.creationInstant);
          innerItem.progress.progressInstant = new Date(innerItem.progress.progressInstant);
        }
      });
      item.steps = steps;

      const preRequisites = new Map<string, Domain.PreRequisite>();
      item.preRequisites.forEach((innerItem) => {
        preRequisites.set(innerItem.id, innerItem);
        innerItem.type = 'PreRequisites';
      });
      item.preRequisites = preRequisites;

      delete item.isWaiting;
      delete item.isInProgress;
      delete item.isDone;
      delete item.isCanceled;

    } else if (kind === 'notification') { // -------------------------------------- NOTIFICATION
      item.type = item.$type;

    }

    item.etag = item._etag;
    delete item._etag;
    delete item._ts;
    delete item.ttl;
    delete item.partitionKey;
    delete item.$type;

    return item;
  }

  private FixupServerSyncData(kind: string, items: any[]): any[] {
    items.forEach((item) => {
      this.FixupServerSyncDataElement(kind, item);
    });

    return items;
  }

  private periodTimeoutFunction(): void {
    this.periodTimeout = null;
    if (debug) console.info(debugPrefix, `Period changed at ${new Date()}`);
    store.commit(AppModuleDomain.PeriodChangedMutation.MutationName, new AppModuleDomain.PeriodChangedMutation());

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const setTimeoutInstance = this; // this is required to allow the settimeout lambda to capture "this"

    const nextPeriodChangeInterval = DateHelper.getIntervalToNextPeriod();
    if (debug) console.info(debugPrefix, `Next period change in ${nextPeriodChangeInterval / 1000 / 60} minutes`);
    this.periodTimeout = window.setTimeout(() => { setTimeoutInstance.periodTimeoutFunction(); }, nextPeriodChangeInterval);
  }

  private async HandlePreAction(action: ActionPayload): Promise<void> {
    if (!(action.payload instanceof ModuleDomain.ActionBase)) throw new Error(`Action handled which is not a ActionBase ${action.type}`);
    if (debug) console.group(debugPrefix.replace('ic', 'ic Action'), `${action.type}`, action.payload);
    this.activityCounter--;
  }

  private async HandlePostAction(action: ActionPayload): Promise<void> {
    try {
      // signin
      if (action.payload instanceof AuthenticationModuleDomain.InternalSignInAllAction) {
        this.BroadcastEvent('sign-in');
        if (debug) console.info(debugPrefix, 'sign-in');

        // check if there are mutations to sync
        const mutationsToSyncCount = (await Services.MutationsToSyncStorage.keys()).length;
        store.commit(AppModuleDomain.SetUnsentMutationsCountMutation.MutationName, new AppModuleDomain.SetUnsentMutationsCountMutation(mutationsToSyncCount));
        if (mutationsToSyncCount > 0 && debug) {
          console.warn(debugPrefix, `${mutationsToSyncCount} Mutations to sync found`);
        }
        if (mutationsToSyncCount > 0 && store.getters['authentication/isSignedIn'] as boolean === false) {
          Services.AppInsights.trackEvent({ name: 'AppStartupSignedOutWithPendingItemsToSync' }); // TODOERIK this code is not at the proper place
        }

        // restore vuex
        await this.Restore();

        // schedule a server sync
        this.scheduleServerSync();
      }

      // signout
      if (action.payload instanceof AuthenticationModuleDomain.InternalSignOutAllAction) {
        await this.ResetLocalDatabases(true);
        this.BroadcastEvent('sign-out');
        if (debug) console.info(debugPrefix, 'sign-out');
      } else if (action.payload instanceof AuthenticationModuleDomain.SignOutAction) {
        await this.RestartApp('/sign-in/sign-out');
      } else if (action.payload instanceof AuthenticationModuleDomain.SignOutChangeEnvironmentAction) {
        await this.RestartApp('/sign-in/changed-environment');
      }
    } finally {
      if (debug) console.groupEnd();
      this.activityCounter--;
    }
  }

  public plugin: Plugin<S> = (innerStore: Store<S>): void => {

    innerStore.subscribe(async (mutation: MutationPayload) => { // signature (mutation: MutationPayload, state: S)
      this.activityCounter++;
      await this.HandleMutation(mutation); // should be await but caller isn't calling this with await
    });

    innerStore.subscribeAction({
      before: async (action: ActionPayload) => { // signature (action: ActionPayload, state: S)
        this.activityCounter++;
        await this.HandlePreAction(action); // should be await but caller isn't calling this with await
      },
      after: async (action: ActionPayload) => { // signature (action: ActionPayload, state: S)
        this.activityCounter++;
        await this.HandlePostAction(action); // should be await but caller isn't calling this with await
      },
    });

    // const oldCommitment = innerStore.commit;
    // innerStore.commit = async function(_type, _payload, _options): Promise<void> {
    //   oldCommitment(_type, _payload, _options);
    //   await this.HandleMutation(_payload);
    // };

    // const oldDispatch = innerStore.dispatch;
    // innerStore.dispatch = async function(_type, _payload): Promise<any> {
    //   await this.HandlePreAction(_payload);
    //   await oldDispatch(_type, _payload);
    //   await this.HandlePostAction(_payload);
    // };

  };

  public async RestartApp(destination: string = '/'): Promise<void> {
    if (debug) console.warn(debugPrefix, 'App being restarted');
    setTimeout(function() { window.location.href = destination; }, 250);
  }
}
