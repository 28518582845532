import { Route } from 'vue-router';
import * as ModuleDomain from '@/models/ModuleDomain';
import * as Domain from '@/models/Domain';

export class InternalInitializeMutation extends ModuleDomain.MutationBase {
  public static MutationName: string = 'internalInitialize';
  public meUser: Domain.User | null;

  constructor(meUser: Domain.User | null) {
    super();
    this.meUser = meUser;
  }
}

export class RestoreMutation extends ModuleDomain.MutationBase {
  public lastStudioId: string = '00000000-0000-0000-0000-000000000000';

  public static MutationName: string = 'app/restore';
}

@ModuleDomain.registerMutationAltersMeUser
@ModuleDomain.registerMutationRestore
export class RestoreMeUserMutation extends ModuleDomain.MutationBase {
  public user: Domain.User;

  constructor(user: Domain.User) {
    super();
    this.user = user;
  }

  public static MutationName: string = 'app/restoreMeUser';
}

export class SetLoggedInUserMutation extends ModuleDomain.MutationBase {
  public user: Domain.User | null;
  public persistedUser: Domain.User | null;

  constructor(user: Domain.User | null, persistedUser: Domain.User | null) {
    super();
    this.user = user;
    this.persistedUser = persistedUser;
  }

  public static MutationName: string = 'app/setLoggedInUser';
}

export class SetIsAppWaiting extends ModuleDomain.MutationBase {
  public isAppWaiting: boolean;

  constructor(isAppWaiting: boolean) {
    super();
    this.isAppWaiting = isAppWaiting;
  }

  public static MutationName: string = 'app/setIsAppWaiting';
}

export class SetLastStudioRouteStudioIdMutation extends ModuleDomain.MutationBase {
  public studioId: string;

  constructor(studioId: string) {
    super();
    this.studioId = studioId;
  }

  public static MutationName: string = 'app/setLastStudioRouteStudioId';
}

export class SetSynchingStatusMutation extends ModuleDomain.MutationBase {
  public isSynching: boolean;
  public isSyncError: boolean = false;

  constructor(isSynching: boolean, isSyncError: boolean = false) {
    super();
    this.isSynching = isSynching;
    this.isSyncError = isSyncError;
  }

  public static MutationName: string = 'app/setSynchingStatus';
}

export class SetUnsentMutationsCountMutation extends ModuleDomain.MutationBase {
  public pendingMutationsToSyncCount: number;

  constructor(pendingMutationsToSyncCount: number) {
    super();
    this.pendingMutationsToSyncCount = pendingMutationsToSyncCount;
  }

  public static MutationName: string = 'app/setUnsentMutationsCount';
}

export class InitializeAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'app/initialize';
}

export class PeriodChangedMutation extends ModuleDomain.MutationBase {
  public static MutationName: string = 'app/periodChanged';
}

export class ForceSyncWithServerMutation extends ModuleDomain.MutationBase {
  public static MutationName: string = 'app/forceSyncWithServer';
  public forceFullSync: boolean;

  constructor(forceFullSync: boolean = false) {
    super();
    this.forceFullSync = forceFullSync;
  }
}


export enum EnumFeedbackKind {
  // eslint-disable-next-line no-unused-vars
  general = 'general',
  // eslint-disable-next-line no-unused-vars
  bug = 'bug',
  // eslint-disable-next-line no-unused-vars
  idea = 'idea',
  // eslint-disable-next-line no-unused-vars
  question = 'question',
  // eslint-disable-next-line no-unused-vars
  survey = 'survey'
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class ProvideFeedbackMutation extends ModuleDomain.MutationBase {
  public title: string = '';
  public description: string = '';
  public kind: EnumFeedbackKind = EnumFeedbackKind.general;

  constructor(title: string, description: string = '', kind: EnumFeedbackKind = EnumFeedbackKind.general) {
    super();
    this.title = title;
    this.description = description;
    this.kind = kind;
  }

  public static MutationName: string = 'app/provideFeedback';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
export class ChangeProfileNicknameMutation extends ModuleDomain.MutationBase {
  public nickname: string;
  constructor(nickname: string) {
    super();
    this.nickname = nickname;
  }

  public static MutationName: string = 'app/changeProfileNickname';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
@ModuleDomain.registerMutationSendsToAppInsights
export class ConfirmProfileInitialMutation extends ModuleDomain.MutationBase {
  public firstName: string;
  public lastName: string;
  public nickname: string;
  public locale: Domain.EnumLocale;
  public color!: string;
  public pictureUrl: string;
  constructor(firstName: string, lastName: string, nickname: string, locale: Domain.EnumLocale, color: string, pictureUrl: string) {
    super();
    this.firstName = firstName;
    this.lastName = lastName;
    this.nickname = nickname;
    this.locale = locale;
    this.color = color;
    this.pictureUrl = pictureUrl;
  }

  public static MutationName: string = 'app/confirmProfileInitial';
}


@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
export class ChangeProfileFirstNameMutation extends ModuleDomain.MutationBase {
  public firstName: string;
  public color: string;
  constructor(firstName: string, color: string) {
    super();
    this.firstName = firstName;
    this.color = color;
  }

  public static MutationName: string = 'app/changeProfileFirstName';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
export class ChangeProfileLastNameMutation extends ModuleDomain.MutationBase {
  public lastName: string;
  public color: string;
  constructor(lastName: string, color: string) {
    super();
    this.lastName = lastName;
    this.color = color;
  }

  public static MutationName: string = 'app/changeProfileLastName';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
@ModuleDomain.registerMutationSendsToAppInsights
export class ChangeProfileLocaleMutation extends ModuleDomain.MutationBase {
  public locale: Domain.EnumLocale;
  constructor(locale: Domain.EnumLocale) {
    super();
    this.locale = locale;
  }

  public static MutationName: string = 'app/changeProfileLocale';
}

@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationAltersMeUser
export class ChangeProfilePictureUrlMutation extends ModuleDomain.MutationBase {
  public pictureUrl: string;
  constructor(pictureUrl: string) {
    super();
    this.pictureUrl = pictureUrl;
  }

  public static MutationName: string = 'app/changeProfilePictureUrl';
}

export class SetServiceWorkerReadyMutation extends ModuleDomain.MutationBase {
  public isServiceWorkerReady: boolean;

  constructor(isServiceWorkerReady: boolean) {
    super();
    this.isServiceWorkerReady = isServiceWorkerReady;
  }

  public static MutationName: string = 'app/setServiceWorkerReady';
}

export class SetForceOfflineMutation extends ModuleDomain.MutationBase {
  public force: boolean;

  constructor(force: boolean) {
    super();
    this.force = force;
  }

  public static MutationName: string = 'app/setForceOffline';
}

export class SetBrowserOnlineMutation extends ModuleDomain.MutationBase {
  public isOnline: boolean;
  public isVisible: boolean;

  constructor(isOnline: boolean, isVisible: boolean) {
    super();
    this.isOnline = isOnline;
    this.isVisible = isVisible;
  }

  public static MutationName: string = 'app/setBrowserOnline';
}

export class ChangedRouteMutation extends ModuleDomain.MutationBase {
  public currentRoute: Route;
  public previousRoute: Route;

  constructor(currentRoute: Route, previousRoute: Route) {
    super();
    this.currentRoute = currentRoute;
    this.previousRoute = previousRoute;
  }

  public static MutationName: string = 'app/changedRoute';
}

export class VoteMutation extends ModuleDomain.MutationBase {
  public userId: string;
  public studioId: string;
  public toDoId: string;
  public feature: string;
  public choice: string;
  public comment: string;

  constructor(userId: string, studioId: string, toDoId: string, feature: string, choice: string, comment: string) {
    super();
    this.userId = userId;
    this.studioId = studioId;
    this.toDoId = toDoId;
    this.feature = feature;
    this.choice = choice;
    this.comment = comment;
  }

  public static MutationName: string = 'app/vote';
}
