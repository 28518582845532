/* eslint-disable curly */
/* eslint-disable no-console */
// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import store from '../plugins/vuex';
import router from '../plugins/router';
import * as Domain from '../models/Domain';
import Services from '@/services/Services';
import * as AuthenticationModuleDomain from '@/models/AuthenticationModuleDomain';
import { Md5 } from 'ts-md5/dist/md5';
import { parseJwt } from '@/helpers/StringHelper';
import * as SideCar from '@/modules/AuthenticationModuleSideCar';
import i18n from '@/plugins/i18n';

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' AuthenticationModule ';

export class ServerlessSignInIdentity {
  public id: string;
  public description: string;
  public nickName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public locale: Domain.EnumLocale;
  public pictureUrls: string[];
  public pictureUrl: string;
}

@Module({ dynamic: true, store, namespaced: true, name: 'authentication' })
export default class AuthenticationModule extends VuexModule {

  public user: Domain.User | null = null;

  public availableServerlessSignInIdentities: ServerlessSignInIdentity[] = [
    {
      id: 'jane',
      description: 'normal user',
      nickName: 'Jane',
      email: 'jane.doe@to-do.studio',
      firstName: 'Jane',
      lastName: 'Doe',
      fullName: 'Jane Doe',
      locale: Domain.EnumLocale.en,
      pictureUrls: ['', '#user-jane'],
      pictureUrl: '#user-jane',
    },
    {
      id: 'john',
      description: 'normal user',
      nickName: 'John',
      email: 'john.doe@to-do.studio',
      firstName: 'John',
      lastName: 'Doe',
      fullName: 'John Doe',
      locale: Domain.EnumLocale.en,
      pictureUrls: ['', '#user-john'],
      pictureUrl: '#user-john',
    },
    {
      id: 'jane-*',
      description: 'normal multiperson group',
      nickName: 'Jane',
      email: 'jane.doe@to-do.studio',
      firstName: 'Jane',
      lastName: 'Doe',
      fullName: 'Jane Doe with friends',
      locale: Domain.EnumLocale.en,
      pictureUrls: ['', '#user-jane'],
      pictureUrl: '#user-jane',
    },
  ];

  get isSignedIn(): boolean {
    return this.user !== null;
  }
  
  @Action
  public async internalSignInAll(payload: AuthenticationModuleDomain.InternalSignInAllAction): Promise<void> {
    this.context.commit(AuthenticationModuleDomain.InternalSignInUserMutation.MutationName, new AuthenticationModuleDomain.InternalSignInUserMutation(payload.oidcuser, payload.serverlessUserId));
  }

  @Mutation
  public internalSignInUser(payload: AuthenticationModuleDomain.InternalSignInUserMutation): void {
    if (payload.oidcuser === null && payload.serverlessUserId === null) {
      this.user = null;
      return;
    }

    let serverlessIdentity: ServerlessSignInIdentity | undefined;
    if (payload.serverlessUserId !== null) {
      serverlessIdentity = this.availableServerlessSignInIdentities.find((t) => t.id === payload.serverlessUserId);
      if (!serverlessIdentity) {
        throw new Error('Serverless identity not found');
      }
    }

    if (serverlessIdentity !== undefined) {
      this.user = new Domain.User(serverlessIdentity.id, payload.now,
        serverlessIdentity.email, [serverlessIdentity.email],
        serverlessIdentity.nickName, serverlessIdentity.firstName, serverlessIdentity.lastName, serverlessIdentity.locale,
        serverlessIdentity.pictureUrl,
        serverlessIdentity.pictureUrls);
    } else {

      // P.S. note that there is an implementattion of this code in the backend and they must be kept in sync: EasySyncController.UpdateMeFromHeaders()

      let firstName = (payload.oidcuser!.profile.given_name !== undefined) ? payload.oidcuser!.profile.given_name : '';
      let lastName = (payload.oidcuser!.profile.family_name !== undefined) ? payload.oidcuser!.profile.family_name : '';
      let displayName = (payload.oidcuser!.profile.name !== undefined) ? payload.oidcuser!.profile.name as string : '';
      if(displayName.toLowerCase() === 'unknown') { displayName = ''; }

      const [displayNameFirst, ...displayNameLast] = displayName.split(' ');
      if (firstName === '') firstName = displayNameFirst;
      if (lastName === '') lastName = displayNameLast.join(' ');

      const nickName = displayNameFirst !== '' ? displayNameFirst : firstName;

      const emails: string[] = [];
      if (payload.oidcuser!.profile.emails !== undefined && payload.oidcuser!.profile.emails !== null) {
        const emailArray: string[] = payload.oidcuser!.profile.emails as string[];
        for (const email of emailArray) {
          emails.push(email as string);
        }
      }
      const email = emails.length > 0 ? emails[0] : null;
      const avatars = [''];
      if (email !== undefined && email !== null && email !== '') {
        const gravatarHash = Md5.hashStr(email);
        avatars.push(`https://gravatar.com/avatar/${gravatarHash}`);
      }

      this.user = new Domain.User(payload.oidcuser!.profile.sub as string, payload.now,
        email, emails,
        nickName, firstName, lastName, i18n.locale as Domain.EnumLocale,
        '', avatars);
    }
  }

  @Action
  public async internalSignOutAll(payload: AuthenticationModuleDomain.InternalSignOutAllAction): Promise<void> {
    this.context.commit(AuthenticationModuleDomain.InternalSignOutUserMutation.MutationName, new AuthenticationModuleDomain.InternalSignOutUserMutation());

    if (!this.context.rootGetters['config/isServerConfigured']) {
      await Services.AppPersistantStorage.removeItem('ServerlessIdentityId');
    }
  }

  @Mutation
  public internalSignOutUser(payload: AuthenticationModuleDomain.InternalSignOutUserMutation): void {
    this.user = null;
  }

  @Action
  public async initialize(payload: AuthenticationModuleDomain.InitializeAction): Promise<void> {
    debugPrefix = Services.GetGenericChalkCategory(debugPrefix.trim());
    debug = await Services.IsDebugOverride('AuthenticationModule');

    if (this.context.rootGetters['config/isServerConfigured']) {
      await this.context.dispatch(AuthenticationModuleDomain.InternalInitializeOIDCAction.ActionName, new AuthenticationModuleDomain.InternalInitializeOIDCAction());
    } else {
      await this.context.dispatch(AuthenticationModuleDomain.InternalInitializeServerlessAction.ActionName, new AuthenticationModuleDomain.InternalInitializeServerlessAction());
    }
  }

  @Action
  public async signIn(payload: AuthenticationModuleDomain.SignInAction): Promise<any> {
    let userManager = SideCar.getUserManager();
    if (userManager) {
      if (payload.redirect === null || payload.redirect === '') {
        await userManager!.signinRedirect();
      } else {
        await userManager!.signinRedirect({ state: payload.redirect });
      }
    } else {
      // tslint:disable-next-line:no-empty
      router.push({ name: 'sign-in' }).catch(() => { });
    }
  }

  @Action
  public async signInServerless(payload: AuthenticationModuleDomain.SignInServerlessAction): Promise<any> {

    Services.AppPersistantStorage.setItem('ServerlessIdentityId', payload.userId);

    await this.context.dispatch(AuthenticationModuleDomain.InternalSignInAllAction.ActionName, new AuthenticationModuleDomain.InternalSignInAllAction(null, payload.userId)).then(() => {
      if (payload.redirect === null || payload.redirect === '') {
        // tslint:disable-next-line:no-empty
        router.push('/').catch(() => { });
      } else {
        // tslint:disable-next-line:no-empty
        router.push(payload.redirect as string).catch(() => { });
      }
    });
  }

  @Action
  public async signOut(payload: AuthenticationModuleDomain.SignOutAction): Promise<any> {
    await this.context.dispatch(AuthenticationModuleDomain.InternalSignOutAllAction.ActionName, new AuthenticationModuleDomain.InternalSignOutAllAction());

    let userManager = SideCar.getUserManager();
    if (userManager) {
      await userManager!.signoutRedirect();
    }
  }

  @Action
  public async signOutChangeEnvironment(payload: AuthenticationModuleDomain.SignOutChangeEnvironmentAction): Promise<any> {
    await this.context.dispatch(AuthenticationModuleDomain.InternalSignOutAllAction.ActionName, new AuthenticationModuleDomain.InternalSignOutAllAction());

    let userManager = SideCar.getUserManager();
    if (userManager) {
      await userManager!.signoutRedirect();
    }
  }

  @Action
  private async internalInitializeServerless(payload: AuthenticationModuleDomain.InternalInitializeServerlessAction): Promise<void> {
    const serverLessIdentityId = await Services.AppPersistantStorage.getItem('ServerlessIdentityId');
    if (serverLessIdentityId) {
      await this.context.dispatch(AuthenticationModuleDomain.InternalSignInAllAction.ActionName, new AuthenticationModuleDomain.InternalSignInAllAction(null, serverLessIdentityId as string));
    }
  }

  @Action
  private async internalInitializeOIDC(payload: AuthenticationModuleDomain.InternalInitializeOIDCAction): Promise<void> {
    let userManager = SideCar.getUserManager();

    const oidcuser = await userManager!.getUser();
    if (oidcuser) {
      const idTokenJWT = parseJwt(oidcuser.id_token);
      const accessTokenJWT = parseJwt(oidcuser.access_token);
      if (debug) console.log(debugPrefix, `Startup IdToken '${idTokenJWT.sub}' IAT ${new Date(idTokenJWT.iat * 1000).toJSON()} -> EXP ${new Date(idTokenJWT.exp * 1000).toJSON()}`, idTokenJWT);
      if (new Date(idTokenJWT.exp * 1000) < new Date()) console.warn('IdToken EXPIRED at startup');
      if (debug) console.log(debugPrefix, `Startup AccessToken '${accessTokenJWT.sub}' IAT ${new Date(accessTokenJWT.iat * 1000).toJSON()} -> EXP ${new Date(accessTokenJWT.exp * 1000).toJSON()}`, accessTokenJWT);
      if (new Date(accessTokenJWT.exp * 1000) < new Date()) console.warn('AccessToken EXPIRED at startup');

      await this.context.dispatch(AuthenticationModuleDomain.InternalSignInAllAction.ActionName, new AuthenticationModuleDomain.InternalSignInAllAction(oidcuser, null));
    } else {
      await this.context.dispatch(AuthenticationModuleDomain.InternalSignOutAllAction.ActionName, new AuthenticationModuleDomain.InternalSignOutAllAction());
    }
  }
}
