/* eslint-disable curly */
/* eslint-disable no-console */
// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
/* eslint-disable @typescript-eslint/no-unused-vars */

import * as Oidc from 'oidc-client-ts';
import { parseJwt } from '@/helpers/StringHelper';
import Services from '@/services/Services';

// based on https://github.com/joaojosefilho/vuejsOidcClient/blob/master/src/services/SecurityService.js

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' AuthenticationModuleSideCar ';

class OidcClientConsoleWrapper implements Oidc.ILogger {
  info(message?: any, ...optionalParams: any[]): void {
    console.info(debugPrefix, message, optionalParams);
  }
  error(message?: any, ...optionalParams: any[]): void {
    console.error(debugPrefix, message, optionalParams);
  }
  warn(message?: any, ...optionalParams: any[]): void {
    console.warn(debugPrefix, message, optionalParams);
  }
  debug(message?: any, ...optionalParams: any[]): void {
    // console.debug(debugPrefix, message, optionalParams);
  }
}

let userManager: Oidc.UserManager | null = null;
export function getUserManager(): Oidc.UserManager | null {
  return userManager;
}

export async function initializeUserManager(oidcSettings: any) {
  debugPrefix = Services.GetGenericChalkCategory(debugPrefix.trim());
  debug = await Services.IsDebugOverride('AuthenticationModule');
  
  if (debug) {
    Oidc.Log.setLogger(new OidcClientConsoleWrapper());
    Oidc.Log.setLevel(Oidc.Log.DEBUG);
  }
  userManager = new Oidc.UserManager(oidcSettings);
}

export async function isSignedIn(): Promise<boolean> {
  const oidcuser = await userManager!.getUser();
  if (oidcuser !== null) {
    return true;
  }
  return false;
}

export async function getSub(): Promise<string> {
  const oidcuser = await userManager!.getUser();
  if (oidcuser !== null) {
    return oidcuser.profile.sub;
  }
  return 'invalid';
}

export async function isAccessTokenExpired(): Promise<boolean> {
  const oidcuser = await userManager!.getUser();
  if (oidcuser !== null) {
    const jwt = parseJwt(oidcuser!.access_token);
    if (new Date(jwt.exp * 1000) < new Date()) return true;
    return false;
  }
  return true;
}

export async function isIDTokenExpired(): Promise<boolean> {
  const oidcuser = await userManager!.getUser();
  if (oidcuser !== null) {
    if (oidcuser!.id_token === undefined) return false;
    const jwt = parseJwt(oidcuser!.id_token);
    if (new Date(jwt.exp * 1000) < new Date()) return true;
    return false;
  }
  return true;
}

export function isOnline(): boolean {
  return navigator.onLine || false;
}
