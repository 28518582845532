<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 410" persistent scrollable no-click-animation max-width="500" :width="$vuetify.breakpoint.width - 10">
    <v-card flat color="background" style="height: 410px;">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title class="subtitle-1">{{ title }}</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-4 ma-0" data-testid="textNotification">
        <template v-if="notificationType === 'StudioRemovalNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioRemovalNotification">{{ $t('C.TDialogNotify.studio-removal-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-removal-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioDeletionNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioDeletionNotification">{{ $t('C.TDialogNotify.studio-deletion-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-deletion-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationNotFoundNotification'">
          <v-layout column align-center class="mt-12 mx-0 pa-0">
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationNotFoundNotification">{{ $t('C.TDialogNotify.studio-invitation-not-found-1') }}</div>
            <br/>
            <div class="grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">{{ $t('C.TDialogNotify.studio-invitation-not-found-2') }}</div>
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-not-found-3') }}</div>
            <br/>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationRepliedNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationRepliedNotification">{{ $t('C.TDialogNotify.studio-invitation-replied-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-replied-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">{{ $t('C.TDialogNotify.studio-invitation-replied-3') }}</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationExpiredNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div v-if="user.id === notificationSenderUserId" class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationExpiredNotificationForSender"><b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-expired-1') }}</div>
            <div v-else class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationExpiredNotification">{{ $t('C.TDialogNotify.studio-invitation-expired-2a') }}<b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-expired-2b') }}</div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-expired-3') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">{{ $t('C.TDialogNotify.studio-invitation-expired-4') }}</div>
            <div v-if="user.id !== notificationSenderUserId" class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-expired-5') }}</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationAlreadyInStudioNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div v-if="user.id === notificationSenderUserId" class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0"><b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-already-in-studio-1a') }}</div>
            <div v-else class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-already-in-studio-1b') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-already-in-studio-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="user.id === notificationSenderUserId" class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0" data-testid="StudioInvitationInviteeIsSender">{{ $t('C.TDialogNotify.studio-invitation-already-in-studio-3a') }}</div>
            <div v-else class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0" data-testid="StudioInvitationAlreadyInStudioNotification">{{ $t('C.TDialogNotify.studio-invitation-already-in-studio-3b') }}</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationNotification') && (invitationState === 'init')">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationNotification">{{ $t('C.TDialogNotify.studio-invitation-init-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-init-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div class="font-italic grey--text text--darken-4 caption text-none text-center mt-0 mx-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-init-3') }}</div>
            <v-layout row align-center class="mt-8 mx-0 pa-0">
              <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-init-4') }}</div>
              <v-chip :color="notificationSenderColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="font-weight-bold ma-0 pa-0">{{ notificationSenderFirstName }}</div>
              </v-chip>
              <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mx-0 my-0 pa-0">?</div>
            </v-layout>
            <v-layout row align-center class="mt-8 mx-0 pa-0">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none" @click="onReportNonLegitStudioInvitation" data-testid="noBtn">{{ $t('G.no') }}</v-btn>
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none ml-6" @click="onReportLegitStudioInvitation" data-testid="yesBtn">{{ $t('G.yes') }}</v-btn>
            </v-layout>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationNotification') && (invitationState === 'nonLegit')">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <br/><br/>
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0" data-testid="StudioInvitationNotificationNonLegit">{{ $t('C.TDialogNotify.studio-invitation-non-legit-1') }}</div>
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-non-legit-2') }}</div>
            <br/><br/>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-12" @click="dialog = false" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationNotification') && (invitationState === 'legit')">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationNotificationConfirm">{{ $t('C.TDialogNotify.studio-invitation-init-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-init-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="ma-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div class="font-italic grey--text text--darken-4 caption text-none text-center mt-0 mx-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-init-3') }}</div>
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">{{ $t('C.TDialogNotify.studio-invitation-legit') }}</div>
            <v-layout row align-center class="mt-8 mx-0 pa-0">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none" @click="onDeclineStudioInvitation" data-testid="declineBtn">{{ $t('G.decline') }}</v-btn>
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none ml-6" @click="onAcceptStudioInvitation" data-testid="acceptBtn">{{ $t('G.accept') }}</v-btn>
            </v-layout>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationNotification') && (invitationState === 'accept')">
          <v-layout column align-center class="mt-12 mx-0 pa-0">
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-3 mx-1 pa-0" data-testid="StudioInvitationNotificationAccepted">{{ $t('C.TDialogNotify.studio-invitation-accept-1') }}{{ notificationSenderFirstName }}</div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <div class="grey--text text--darken-4 caption text-none text-center mx-0 my-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-accept-2') }}</div>
            <br/><br/>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="dialog = false" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationNotification') && (invitationState === 'decline')">
          <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-12 mx-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-decline-1') }}</div>
          <v-textarea v-model="reason" hide-details="auto" background-color="white" outlined class="mx-0 mt-5 px-0"
            auto-grow rows="2" :label="$t('C.TDialogNotify.studio-invitation-decline-2')" data-testid="StudioInvitationNotificationDeclinedReason">
          ></v-textarea>
          <v-layout column align-center class="mt-12 mx-0 pa-0">
            <v-layout row align-center class="ma-0 pa-0">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none" @click="onCancelStudioInvitationDeclined" data-testid="cancelBtn">{{ $t('G.cancel') }}</v-btn>
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none ml-2" @click="onConfirmStudioInvitationDeclined" data-testid="confirmBtn">{{ $t('G.confirm') }}</v-btn>
            </v-layout>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationDeclinedNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationDeclinedNotification"><b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-declined-1') }}</div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-declined-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="mx-1 my-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationAcceptedNotification') && (invitationState !== 'deny')">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationAcceptedNotification">{{ $t('C.TDialogNotify.studio-invitation-accepted-1') }}<b>{{notificationSenderFirstName}}</b></div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-accepted-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="mx-1 my-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">{{ $t('C.TDialogNotify.studio-invitation-accepted-3') }}</div>
            <v-layout row align-center class="mt-8 mx-0 pa-0">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none" @click="onDenyStudioAdmittance" data-testid="denyBtn">{{ $t('G.deny') }}</v-btn>
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none ml-6" @click="onAdmitStudioAdmittance" data-testid="admitBtn">{{ $t('G.admit') }}</v-btn>
            </v-layout>      
          </v-layout>
        </template>
        <template v-if="(notificationType === 'StudioInvitationAcceptedNotification') && (invitationState === 'deny')">
          <div class="font-weight-bold grey--text text--darken-4 caption text-none text-center mt-12 mx-0 pa-0" data-testid="StudioInvitationAcceptedNotificationConfirm">{{ $t('C.TDialogNotify.studio-invitation-accepted-deny-1') }}</div>
          <v-textarea v-model="reason" hide-details="auto" outlined background-color="white" class="mx-0 mt-4 px-0"
            auto-grow rows="2" :label="$t('C.TDialogNotify.studio-invitation-accepted-deny-2')" data-testid="StudioInvitationAdmittanceDeniedReason"
          ></v-textarea>
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <v-layout row align-center class="ma-0 pa-0">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none" @click="onCancelStudioAdmittanceDenied" data-testid="cancelBtn">{{ $t('G.cancel') }}</v-btn>
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none ml-2" @click="onConfirmStudioAdmittanceDenied" data-testid="confirmBtn">{{ $t('G.confirm') }}</v-btn>
            </v-layout>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationAdmittanceAcceptedNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationAdmittanceAcceptedNotification">{{ $t('C.TDialogNotify.studio-invitation-admittance-accepted-1a') }}<b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-admittance-accepted-1b') }}</div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-admittance-accepted-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="mx-1 my-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>
        <template v-if="notificationType === 'StudioInvitationAdmittanceDeniedNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0" data-testid="StudioInvitationAdmittanceDeniedNotification">{{ $t('C.TDialogNotify.studio-invitation-admittance-denied-1a') }}<b>{{notificationSenderFirstName}}</b>{{ $t('C.TDialogNotify.studio-invitation-admittance-denied-1b') }}</div>
            <t-avatar :size="40" :initials="notificationSenderInitials" :color="notificationSenderColor" :pictureUrl="notificationSenderPictureUrl" class="mx-0 mt-3 mb-2 pa-0"/>
            <v-layout row align-center class="ma-0 pa-0">
              <div class="grey--text text--darken-4 caption text-none text-center ma-0 pa-0">{{ $t('C.TDialogNotify.studio-invitation-admittance-denied-2') }}</div>
              <v-chip :color="notificationStudioColor" text-color="secondary" x-small class="ml-1 my-0 px-2 py-0">
                <div class="mx-1 my-0 pa-0">{{ notificationStudioTitle }}</div>
              </v-chip>
            </v-layout>
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>

        <template v-if="notificationType === 'SystemMessageNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>

        <template v-if="notificationType === 'SystemSelectionNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>

            <div v-for="(possibleSelection) in notificationPossibleSelections">
              <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onSetSelection(possibleSelection)" data-testid="closeBtn">{{ $t('G.systemSelectionNotification.' + possibleSelection) === possibleSelection ? possibleSelection : $t('G.systemSelectionNotification.' + possibleSelection) }}</v-btn>
            </div>
          </v-layout>
        </template>

        <template v-if="notificationType === 'PaddleSubscriptionChangeNotification'">
          <v-layout column align-center class="mt-8 mx-0 pa-0">
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionActivated)">{{ $t('C.TDialogNotify.paddle-subscription-1') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionPastDue)">{{ $t('C.TDialogNotify.paddle-subscription-2') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionCanceled)">{{ $t('C.TDialogNotify.paddle-subscription-3') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionUpdated)">{{ $t('C.TDialogNotify.paddle-subscription-4') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionTrial)">{{ $t('C.TDialogNotify.paddle-subscription-5') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionPaused)">{{ $t('C.TDialogNotify.paddle-subscription-6') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.subscriptionResumed)">{{ $t('C.TDialogNotify.paddle-subscription-7') }}</div>
            
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.proLicenseActivated)">{{ $t('C.TDialogNotify.paddle-subscription-8') }}</div>
            <div v-if="paddleSubscriptionChangeNotification_events.includes(EnumPaddleSubscriptionEvent.proLicenseDeactivated)">{{ $t('C.TDialogNotify.paddle-subscription-9') }}</div>

            <div v-if="notificationMessage.length > 0" style="word-wrap: break-word; white-space: pre-wrap;" class="font-italic grey--text text--darken-4 caption text-none text-center mt-8 mx-2 pa-0">"{{ notificationMessage }}"</div>
            <v-btn color="primary" large rounded style="border-radius: 12px;" class="button text-none mt-8" @click="onAcknowledgeNotification" data-testid="closeBtn">{{ $t('G.close') }}</v-btn>
          </v-layout>
        </template>

        <v-card flat color="transparent" height="24" class="ma-0 pa-0"></v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import * as NotificationModuleDomain from '../models/NotificationModuleDomain';
import TIcon from '../components/TIcon.vue';
import TAvatar from '../components/TAvatar.vue';
import TToDoCard from '../components/TToDoCard.vue';
import * as EventBus from '@/services/EventBus';

// eslint-disable-next-line no-unused-vars
enum EnumState {
  // eslint-disable-next-line no-unused-vars
  init = 'init',
  // eslint-disable-next-line no-unused-vars
  nonLegit = 'nonLegit',
  // eslint-disable-next-line no-unused-vars
  legit = 'legit',
  // eslint-disable-next-line no-unused-vars
  accept = 'accept',
  // eslint-disable-next-line no-unused-vars
  decline = 'decline',
  // eslint-disable-next-line no-unused-vars
  deny = 'deny',
}

@Component({
  components: {
    TIcon, TAvatar, TToDoCard,
  },
  name: 't-dialog-notify',
})
export default class TDialogNotify extends Vue {
  @Prop({ type: Array, default: function() { return []; } })
  public readonly notifications!: Domain.Notification[];
  @Prop({ type: Object, required: true })
  public readonly user!: Domain.User;
  // Events

  private tokenToUnsubscribe: number | undefined = undefined;

  public notificationId: string = '';
  public notificationType: string = '';
  public notificationMessage: string = '';
  public notificationSenderUserId: string = '';
  public notificationSenderFirstName: string = '';
  public notificationSenderNickname: string = '';
  public notificationSenderInitials: string = '';
  public notificationSenderColor: string = '';
  public notificationSenderPictureUrl: string = '';
  public notificationStudioId: string = '';
  public notificationStudioTitle: string = '';
  public notificationStudioColor: string = '';
  public paddleSubscriptionChangeNotification_events: Domain.EnumPaddleSubscriptionEvent[] = [];
  public EnumPaddleSubscriptionEvent = Object.freeze(Domain.EnumPaddleSubscriptionEvent);
  public notificationPossibleSelections: string[] = [];

  public dialog: boolean = false;
  public title: string = '';
  public reason: string = '';

  public invitationState: EnumState = EnumState.init;

  private beforeUnmount() {
    if (this.tokenToUnsubscribe !== undefined) { EventBus.Bus.Unsubscribe(this.tokenToUnsubscribe); }
  }

  private mounted() {
    this.checkNotifications();
    this.tokenToUnsubscribe = EventBus.Bus.Subscribe(EventBus.EventKind.SyncFinished, (message, data) => {
      this.checkNotifications();
    });
  }

  private checkNotifications() {
    if (this.dialog) {
      const isActiveNotificationExist: boolean = this.notifications.some(
        (notification: Domain.Notification) =>
          notification.id === this.notificationId,
      );
      if (isActiveNotificationExist) {
        return;
      } else {
        this.dialog = false;
      }
    }

    if (this.notifications.length === 0) { return; }

    this.reason = '';
    this.invitationState = EnumState.init;

    const studioRemovalNotification = this.notifications.find((item) => item instanceof Domain.StudioRemovalNotification && item.isAcknowledged === false);
    if (studioRemovalNotification !== undefined) {
      const notification = studioRemovalNotification as Domain.StudioRemovalNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-removal-title").toString();
      this.dialog = true;
      return;
    }

    const studioDeletionNotification = this.notifications.find((item) => item instanceof Domain.StudioDeletionNotification && item.isAcknowledged === false);
    if (studioDeletionNotification !== undefined) {
      const notification = studioDeletionNotification as Domain.StudioDeletionNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = "";
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-deletion-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationNotFoundNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationNotFoundNotification && item.isAcknowledged === false);
    if (studioInvitationNotFoundNotification !== undefined) {
      const notification = studioInvitationNotFoundNotification as Domain.StudioInvitationNotFoundNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.title = this.$t("C.TDialogNotify.studio-invitation-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationAdmittanceDeniedNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationAdmittanceDeniedNotification && item.isAcknowledged === false);
    if (studioInvitationAdmittanceDeniedNotification !== undefined) {
      const notification = studioInvitationAdmittanceDeniedNotification as Domain.StudioInvitationAdmittanceDeniedNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-admittance-denied-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationAdmittanceAcceptedNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationAdmittanceAcceptedNotification && item.isAcknowledged === false);
    if (studioInvitationAdmittanceAcceptedNotification !== undefined) {
      const notification = studioInvitationAdmittanceAcceptedNotification as Domain.StudioInvitationAdmittanceAcceptedNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-admittance-accepted-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationDeclinedNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationDeclinedNotification && item.isAcknowledged === false);
    if (studioInvitationDeclinedNotification !== undefined) {
      const notification = studioInvitationDeclinedNotification as Domain.StudioInvitationDeclinedNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-declined-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationAcceptedNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationAcceptedNotification && item.selectedSelection === null);
    if (studioInvitationAcceptedNotification !== undefined) {
      const notification = studioInvitationAcceptedNotification as Domain.StudioInvitationAcceptedNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-accepted-title").toString();
      this.reason = '';
      this.dialog = true;
      return;
    }

    const studioInvitationRepliedNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationRepliedNotification && item.isAcknowledged === false);
    if (studioInvitationRepliedNotification !== undefined) {
      const notification = studioInvitationRepliedNotification as Domain.StudioInvitationRepliedNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationExpiredNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationExpiredNotification && item.isAcknowledged === false);
    if (studioInvitationExpiredNotification !== undefined) {
      const notification = studioInvitationExpiredNotification as Domain.StudioInvitationExpiredNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationAlreadyInStudioNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationAlreadyInStudioNotification && item.isAcknowledged === false);
    if (studioInvitationAlreadyInStudioNotification !== undefined) {
      const notification = studioInvitationAlreadyInStudioNotification as Domain.StudioInvitationAlreadyInStudioNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-title").toString();
      this.dialog = true;
      return;
    }

    const studioInvitationNotification = this.notifications.find((item) => item instanceof Domain.StudioInvitationNotification && item.selectedSelection === null);
    if (studioInvitationNotification !== undefined) {
      const notification = studioInvitationNotification as Domain.StudioInvitationNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationSenderUserId = notification.senderUserId;
      this.notificationSenderFirstName = notification.senderFirstName;
      this.notificationSenderNickname = notification.senderNickname;
      this.notificationSenderInitials = notification.senderInitials;
      this.notificationSenderColor = notification.senderColor;
      this.notificationSenderPictureUrl = notification.senderPictureUrl;
      this.notificationStudioId = notification.studioId;
      this.notificationStudioTitle = notification.studioTitle;
      this.notificationStudioColor = notification.studioColor;
      this.title = this.$t("C.TDialogNotify.studio-invitation-title").toString();
      this.reason = '';
      this.dialog = true;
      return;
    }

    const systemMessageNotification = this.notifications.find((item) => item instanceof Domain.SystemMessageNotification && item.isAcknowledged === false);
    if (systemMessageNotification)
    {
      const notification = systemMessageNotification as Domain.SystemMessageNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.title = this.$t("C.TDialogNotify.system-message-notification-title").toString();
      this.dialog = true;
      return;
    }

    const systemSelectionNotification = this.notifications.find((item) => item instanceof Domain.SystemSelectionNotification && item.selectedSelection === null);
    if (systemSelectionNotification)
    {
      const notification = systemSelectionNotification as Domain.SystemSelectionNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.notificationPossibleSelections = notification.possibleSelections;
      this.title = this.$t("C.TDialogNotify.system-message-notification-title").toString();
      this.dialog = true;
      return;
    }

    const paddleSubscriptionChangeNotification = this.notifications.find((item) => item instanceof Domain.PaddleSubscriptionChangeNotification && item.isAcknowledged === false);
    if (paddleSubscriptionChangeNotification)
    {
      const notification = paddleSubscriptionChangeNotification as Domain.PaddleSubscriptionChangeNotification;
      this.notificationId = notification.id;
      this.notificationType = notification.type;
      this.notificationMessage = notification.message;
      this.title = this.$t("C.TDialogNotify.paddle-subscription-title").toString();
      this.paddleSubscriptionChangeNotification_events = notification.events;
      this.dialog = true;
      return;
    }
  }

  public onAcknowledgeNotification() {
    const payload = new NotificationModuleDomain.AcknowledgeNotificationMutation(this.user.id, this.notificationId);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.AcknowledgeNotificationMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.AcknowledgeNotificationMutation.MutationName, payload);
    }
    this.dialog = false;
  }

  public onSetSelection(selection: string) {
    const payload = new NotificationModuleDomain.SetNotificationSelectionMutation(this.user.id, this.notificationId, selection);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.SetNotificationSelectionMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.SetNotificationSelectionMutation.MutationName, payload);
    }
    this.dialog = false;
  }

  public onReportLegitStudioInvitation() {
    this.invitationState = EnumState.legit;
  }

  public onReportNonLegitStudioInvitation() {
    const payload = new NotificationModuleDomain.ReportNonLegitStudioInvitationMutation(this.user.id, this.notificationId);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.ReportNonLegitStudioInvitationMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.ReportNonLegitStudioInvitationMutation.MutationName, payload);
    }
    this.invitationState = EnumState.nonLegit;
  }

  public onAcceptStudioInvitation() {
    const payload = new NotificationModuleDomain.AcceptStudioInvitationMutation(this.user.id, this.notificationId);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.AcceptStudioInvitationMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.AcceptStudioInvitationMutation.MutationName, payload);
    }

    this.invitationState = EnumState.accept;
  }

  public onDeclineStudioInvitation() {
    this.invitationState = EnumState.decline;
  }

  public onConfirmStudioInvitationDeclined() {
    const payload = new NotificationModuleDomain.DeclineStudioInvitationMutation(this.user.id, this.notificationId, this.reason);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.DeclineStudioInvitationMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.DeclineStudioInvitationMutation.MutationName, payload);
    }
    this.dialog = false;
  }

  public onCancelStudioInvitationDeclined() {
    this.invitationState = EnumState.legit;
  }

  public onAdmitStudioAdmittance() {
    const payload = new NotificationModuleDomain.AdmitStudioAdmittanceMutation(this.user.id, this.notificationId);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.AdmitStudioAdmittanceMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.AdmitStudioAdmittanceMutation.MutationName, payload);
    }
    this.dialog = false;
  }

  public onDenyStudioAdmittance() {
    this.invitationState = EnumState.deny;
  }

  public onConfirmStudioAdmittanceDenied() {
    const payload = new NotificationModuleDomain.DenyStudioAdmittanceMutation(this.user.id, this.notificationId, this.reason);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert("{ 'store': '" + NotificationModuleDomain.DenyStudioAdmittanceMutation.MutationName + "' }");
    } else {
      this.$store.commit(NotificationModuleDomain.DenyStudioAdmittanceMutation.MutationName, payload);
    }
    this.dialog = false;
  }

  public onCancelStudioAdmittanceDenied() {
    this.invitationState = EnumState.init;
  }

}
</script>

<style scoped>
.notch-bottom:before, [notch-bottom]:before {
    position: absolute;
    content: '';
    left: 50%;
    top: 100%;
    margin-bottom: -8px;
    margin-left: -5px;
    width: 8px;
    height: 8px;
    border-top: 8px solid #333333;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 0;
}
</style>

