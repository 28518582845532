<template>
  <v-card flat color="transparent" :width="width" :min-height="40" class="ma-0 pa-0">
    <v-layout column align-start class="pa-0 ma-0" data-testid="card">
      <div :style="cssStyleForTitle" :class="`${classLineThrough} ${classTextColor} pa-0 my-0 ml-1 text-subtitle-1`" data-testid="title">
        {{toDo.title}}
      </div>
      <v-layout row align-center class="pa-0 ml-1 my-0">
        <v-chip v-if="studio !== null" x-small :color="studio === null ? '' : studio.color" text-color="secondary" style="font-size: 60%;" class="mr-1 my-0 px-3 py-0" data-testid="studio">
          <span class="font-weight-bold text-capitalize ma-0 pa-0">{{ studio === null ? '' : studio.title }}</span>
        </v-chip>
        <t-icon v-if="toDo.effort === 'xSmall'" href="#x-small" :title="$t('C.TToDoEffortSelector.extra-small-effort')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.effort === 'small'" href="#small" :title="$t('C.TToDoEffortSelector.small-effort')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.effort === 'medium'" href="#medium" :title="$t('C.TToDoEffortSelector.medium-effort')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.effort === 'large'" href="#large" :title="$t('C.TToDoEffortSelector.large-effort')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.effort === 'xLarge'" href="#x-large" :title="$t('C.TToDoEffortSelector.extra-large-effort')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.importance === 'high'" href="#high-importance" :title="$t('C.TToDoImportanceSelector.high-importance')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.importance === 'low'" href="#low-importance" :title="$t('C.TToDoImportanceSelector.low-importance')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.urgency === 'high'" href="#alert" :title="$t('C.TToDoUrgencySelector.high-urgency')" :color="textColor" :size="18" class="mr-1 my-0 pa-0"/>
        <t-icon v-if="toDo.isWaiting" href="#timer-sand" :title="$t('G.waiting')" :color="textColor" :size="18" class="ma-0 pa-0"/>
        <t-icon v-if="toDo.isInProgress" href="#in-progress" :title="$t('C.TStudioTab.to-dos-in-progress')" :color="textColor" :size="18" class="mr-1 my-0 pa-0" data-testid="inProgressIcon"/>
        <t-icon v-if="toDo.isDone" href="#done" :title="$t('C.TStudioTab.to-dos-done')" :color="textColor" :size="18" class="mr-1 my-0 pa-0" data-testid="doneIcon"/>
        <t-icon v-if="toDo.isCanceled" href="#cancel-to-do" :title="$t('G.logbook.to-do-canceled')" :color="textColor" :size="18" class="mr-1 my-0 pa-0" data-testid="cancelIcon"/>
      </v-layout>
    </v-layout>
    <div v-if="withDescription && (toDo.description !== '')" class="`${classLineThrough} ${classTextColor} pa-1 ml-1 my-0`" :style="cssStyleForDescription" data-testid="description">{{toDo.description}}</div>
  </v-card>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-to-do-card',
})
export default class TToDoCard extends Vue {
  @Prop({ type: Number, default: 220 })
  public readonly width!: number;
  @Prop({ type: Object, required: true })
  public readonly toDo!: Domain.ToDo;
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly studio!: Domain.Studio | null;
  @Prop({ type: Boolean, default: false })
  public readonly withExpandTitle!: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly withDescription!: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly withLineThrough!: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isSelected!: boolean;

  public get cssStyleForTitle(): string {
    if (this.withExpandTitle) {
      return 'line-height: 130%; word-wrap: break-word; width: ' + (this.width - 8) + 'px;';
    } else {
      return 'line-height: 130%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: ' + (this.width - 8) + 'px;';
    }
  }

  public get classTextColor(): string {
    const textColor: string = (this.toDo.isCanceled) ? (this.isSelected) ? ' primary--text text--lighten-3' : ' grey--text text--lighten-1 ' : (this.isSelected) ? ' primary--text' : ' secondary--text ';
    return textColor;
  }

  public get textColor(): string {
    const textColor: string = (this.toDo.isCanceled) ? (this.isSelected) ? '#90CAF9' : '#BDBDBD' : (this.isSelected) ? 'primary' : 'grey';
    return textColor;
  }

  public get classLineThrough(): string {
    const lineThrough: string = (this.withLineThrough) ? ' text-decoration-line-through' : '';
    return lineThrough;
  }

  public get cssStyleForDescription(): string {
    return 'line-height: 100%; font-size: 70%; word-wrap: break-word; white-space: pre-wrap; width: ' + (this.width - 8) + 'px;';
  }
}
</script>

