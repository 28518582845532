// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '../plugins/vuex';
import * as TipModuleDomain from '../models/TipModuleDomain';
import * as CopilotHelper from '../helpers/CopilotHelper';
import * as Domain from '../models/Domain';

@Module({ dynamic: true, store, namespaced: true, name: 'tip' })
export default class TipModule extends VuexModule {
  public tip: Domain.Tip = new Domain.Tip('noTip', []);

  @Mutation
  public reset(payload: TipModuleDomain.ResetMutation): void {
    this.tip = new Domain.Tip('noTip',[]);
    TipModule.ManageBadge(this.tip);
  }

  @Mutation
  public compute(payload: TipModuleDomain.Compute): void {
    this.tip = CopilotHelper.computeTip(payload.userId, payload.studios, payload.toDos);
    TipModule.ManageBadge(this.tip);
  }

  private static ManageBadge(tip: Domain.Tip): void {
    if (navigator.setAppBadge) {
      let toDoTipCount = tip.kind === 'fix' ? tip.toDoTips.length : 0;
      if (toDoTipCount > 9) { toDoTipCount = 9; }
      navigator.setAppBadge(toDoTipCount);
    }
  }
}
