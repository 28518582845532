<template>
  <svg v-if="href !== ''" :fill="svgColor" :width="size" :height="size">
    <title>{{ title }}</title>
    <use :xlink:href="href"></use>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  name: 't-icon',
})
export default class TIcon extends Vue {
  @Prop({ type: String, required: true })
  public readonly href!: string;
  @Prop({ type: String, required: true })
  public readonly title!: string;
  @Prop({ type: Number, required: true })
  public readonly size!: number;
  @Prop({ type: String, default: 'secondary' })
  public readonly color!: string;

  public get svgColor(): string {
    let svgColor: string = this.color;
    if (svgColor === 'background') {
      svgColor = '#FFFFF6';
    }
    if (svgColor === 'primary') {
      svgColor = '#1565C0';
    }
    if (svgColor === 'secondary') {
      svgColor = '#333333';
    }
    if (svgColor === 'accent') {
      svgColor = '#E3F2FD';
    }
    return svgColor;
  }
}
</script>

