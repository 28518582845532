<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 400" persistent scrollable no-click-animation max-width="500" data-testid="dialog">
    <v-card flat color="background" style="height: 400px;">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title class="subtitle-1">{{ $t('G.warning') }}</v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-4 ma-0">
        <v-layout column align-center class="mt-12 mx-0 pa-0">
          <t-icon href="#logo" :title="$t('G.appLogo')" color="secondary" :size="100" class="mt-6"/>
          <div class="subtitle font-weight-black">{{ $t('G.appName') }}</div>

          <div class="grey--text text--darken-4 body-2 text-none text-center mt-12 pa-0">{{ $t('C.TDialogScreenTooSmall.message-1') }}</div>
          <div class="grey--text text--darken-4 body-2 text-none text-center ma-0 pa-0">{{ $t('C.TDialogScreenTooSmall.message-2') }}</div>
          <v-layout row align-center class="mt-8 mx-0 pa-0">
            <t-icon href="#alert" title="Alert" color="red" :size="36" class="ma-0 pa-0"/>
            <div class="grey--text text--darken-4 body-2 text-none text-center ml-2 my-0 pa-0" data-testid="dialogWidth">{{ $t('C.TDialogScreenTooSmall.width-1') }}{{width}}{{ $t('C.TDialogScreenTooSmall.width-2') }}</div>
          </v-layout>
          <div class="grey--text text--darken-4 caption text-none text-center mt-4 pa-0">{{ $t('C.TDialogScreenTooSmall.width-3') }}</div>
        </v-layout>
        <v-card flat color="transparent" height="24" class="ma-0 pa-0"></v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-dialog-screen-too-small',
})
export default class TDialogScreenTooSmall extends Vue {
  @Prop({ type: Number, required: true })
  public readonly width!: number;

  public get dialog(): boolean {
    return this.width < 345;
  }
}
</script>

