import * as ModuleDomain from '@/models/ModuleDomain';
import * as Oidc from 'oidc-client-ts';

export class SignInAction extends ModuleDomain.ActionBase {
  public redirect: string|null;

  constructor(redirect: string|null) {
    super();
    this.redirect = redirect;
  }

  public static ActionName: string = 'authentication/signIn';
}

export class SignInServerlessAction extends ModuleDomain.ActionBase {
  public userId: string;
  public redirect: string|null;

  constructor(userId: string, redirect: string|null) {
    super();
    this.userId = userId;
    this.redirect = redirect;
  }

  public static ActionName: string = 'authentication/signInServerless';
}

export class SignOutAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'authentication/signOut';
}

export class SignOutChangeEnvironmentAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'authentication/signOutChangeEnvironment';
}

export class InitializeAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'authentication/initialize';
}

export class InternalInitializeOIDCAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'internalInitializeOIDC';
}

export class InternalInitializeServerlessAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'internalInitializeServerless';
}

export class InternalSignOutAllAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'internalSignOutAll';
}

export class InternalSignInAllAction extends ModuleDomain.ActionBase {
  public oidcuser: Oidc.User|null;
  public serverlessUserId: string|null;

  constructor(oidcuser: Oidc.User|null, serverlessUserId: string|null) {
    super();
    this.oidcuser = oidcuser;
    this.serverlessUserId = serverlessUserId;
  }

  public static ActionName: string = 'internalSignInAll';
}

export class InternalSignOutUserMutation extends ModuleDomain.MutationBase {
  public static MutationName: string = 'internalSignOutUser';
}

export class InternalSignInUserMutation extends ModuleDomain.MutationBase {
  public oidcuser: Oidc.User|null;
  public serverlessUserId: string|null;

  constructor(oidcuser: Oidc.User|null, serverlessUserId: string|null) {
    super();
    this.oidcuser = oidcuser;
    this.serverlessUserId = serverlessUserId;
  }

  public static MutationName: string = 'internalSignInUser';
}
