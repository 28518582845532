<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <t-header
      ref="headerID"
      :route="$store.state.app.currentRoute"
      :user="$store.state.app.user"
      :environmentName="$store.state.config.environmentName.toLowerCase()"
      :pendingMutationsToSyncCount="$store.state.app.pendingMutationsToSyncCount"
      :isOnline="$store.getters['app/isOnline']"
    />
    <v-main>
      <transition>
          <router-view v-if="$store.getters['app/isRouteSafeToShow'] === true"></router-view>
          <v-overlay v-else-if="($store.getters['app/isRouteSafeToShow'] === false && $store.state.app.user && $store.state.app.user.status === 'awaitingApprouval')" color="white" opacity="1">
            <v-layout column align-center class="mt-6">
              <t-icon href="#logo" title="Logo of To-Do Studio" color="secondary" :size="100"/>
              <div class="subtitle font-weight-black">To-Do Studio</div>
              <div class="text-center primary--text mt-6 pa-0">You are currently on the beta waiting list. We will get back to you when your account is activated.</div>
              <v-btn large rounded color="primary" style="border-radius: 12px;" class="button white--text text-none mt-12" @click="onSignOut">Sign Out</v-btn>
            </v-layout>
          </v-overlay>
          <v-overlay v-else-if="($store.getters['app/isRouteSafeToShow'] === false && $store.state.app.user && $store.state.app.user.status !== 'active')" color="white" opacity="1">
            <v-layout column align-center class="mt-6">
              <t-icon href="#logo" title="Logo of To-Do Studio" color="secondary" :size="100"/>
              <div class="subtitle font-weight-black">To-Do Studio</div>
              <div class="text-center primary--text mt-6 pa-0">You are not active - please contact the administrator.</div>
              <v-btn large rounded color="primary" style="border-radius: 12px;" class="button white--text text-none mt-12" @click="onSignOut">Sign Out</v-btn>
            </v-layout>
          </v-overlay>
          <v-overlay v-else color="white" opacity="1">
            <v-layout column align-center class="mt-10">
              <v-progress-circular indeterminate :size="100" color="primary" :width="10"></v-progress-circular>
              <div class="text-center primary--text mt-3 pa-0">Performing first synchronisation</div>
            </v-layout>
          </v-overlay>
      </transition>
    </v-main>
    <t-footer
      ref="footerID"
      :user="$store.state.app.user"
      :version="version"
      :isOnline="$store.getters['app/isOnline']"
      :isLiveSynch="$store.state.app.isLive"
      :isSynching="$store.state.app.isSynching"
      :isSyncError="$store.state.app.isSyncError"
      :isForceOffline="$store.state.app.isForceOffline"
      :serverId="$store.state.config.currentServer.id"
      :servers="servers"
      :pendingMutationsToSyncCount="$store.state.app.pendingMutationsToSyncCount"
    />
    <t-cookie-consent />
    <t-dialog-notify v-if="$store.state.app.user !== null" :notifications="$store.state.notification.notificationsArr" :user="$store.state.app.user"/>
    <t-dialog-screen-too-small :width="contentWidth"/>
    <v-overlay :value="$store.state.app.isAppWaiting">
      <v-progress-circular indeterminate size="200" color="primary" :width="10">Please wait</v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as AppModuleDomain from './models/AppModuleDomain';
import * as AuthenticationModuleDomain from './models/AuthenticationModuleDomain';
import Services from './services/Services';
import TFooter from './components/TFooter.vue';
import THeader from './components/THeader.vue';
import TCookieConsent from './components/TCookieConsent.vue';
import TDialogNotify from './components/TDialogNotify.vue';
import TDialogScreenTooSmall from './components/TDialogScreenTooSmall.vue';

@Component({
  name: 'App',
  components: {
    TFooter, THeader, TCookieConsent, TDialogNotify, TDialogScreenTooSmall,
  },
})
export default class App extends Vue {
  // eslint-disable-next-line vue/no-parsing-error
  private debugPrefix: string = ' To.Do Studio ';

  private created() {
    this.debugPrefix = Services.GetTDSChalkCategory(this.debugPrefix.trim());

    // eslint-disable-next-line no-console
    console.info(this.debugPrefix, 'Starting...');

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    window.addEventListener('load', this.updateOnlineStatus);
    document.addEventListener('visibilitychange', this.updateOnlineStatus);
    this.updateOnlineStatus();
  }

  private beforeDestroy() {
    // eslint-disable-next-line no-console
    console.info(this.debugPrefix, 'Ending...');

    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    window.removeEventListener('load', this.updateOnlineStatus);
    window.removeEventListener('visibilitychange', this.updateOnlineStatus);

    const payload = new AppModuleDomain.SetBrowserOnlineMutation(false, false);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AppModuleDomain.SetBrowserOnlineMutation.MutationName + '" }');
    } else {
      this.$store.commit(AppModuleDomain.SetBrowserOnlineMutation.MutationName, payload);
    }
  }

  private updateOnlineStatus() {
    const isVisible = document.hidden === false;
    const isOnline = navigator.onLine;

    const payload = new AppModuleDomain.SetBrowserOnlineMutation(isOnline, isVisible);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AppModuleDomain.SetBrowserOnlineMutation.MutationName + '" }');
    } else {
      this.$store.commit(AppModuleDomain.SetBrowserOnlineMutation.MutationName, payload);
    }
  }

  public async onSignOut() {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AuthenticationModuleDomain.SignOutAction.ActionName + '" }');
    } else {
      await this.$store.dispatch(AuthenticationModuleDomain.SignOutAction.ActionName, new AuthenticationModuleDomain.SignOutAction());
    }
  }

  public get contentWidth(): number {
    return (this as any).$vuetify.breakpoint.width;
  }

  public get version(): string {
    return 'App ' + this.$store.getters['config/version'];
  }

  public get servers(): {id: string, name: string}[] {
    return ((this.$store.state.config.isDevEnvironment || this.$store.getters['config/isStagingEnvironment'])) ? this.$store.state.config.availableServers : [];
  }
}
</script>
