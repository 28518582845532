// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function truncate(title: string, length: number): string {
  if (title.length > length) {
    return title.substring(0, length) + ' ...';
  } else {
    return title;
  }
}

export function truncateStart(title: string, length: number): string {
  if (title.length > length) {
    return '... ' + title.substring((title.length - length), title.length);
  } else {
    return title;
  }
}

export function getDefaultPastelColor(): string {
  return '#F1F8E9FF';
}

export function getRandomPastelColor(): string {
  const pastelColors = [
    '#F8BBD0FF', '#FFCDD2FF', '#FFCCBCFF', '#FCE4ECFF', '#FFEBEEFF', '#FBE9E7FF', '#FFE0B2FF', '#FFECB3FF', '#FFF3E0FF',
    '#E1BEE7FF', '#D1C4E9FF', '#C5CAE9FF', '#F3E5F5FF', '#E8EAF6FF', '#EDE7F6FF', '#EFEBE9FF', '#ECEFF1FF', '#F9FBE7FF',
    '#BBDEFBFF', '#B3E5FCFF', '#B2DFDBFF', '#B2EBF2FF', '#E3F2FDFF', '#E0F2F1FF', '#E0F7FAFF', '#E1F5FEFF', '#E8F5E9FF',
    '#C5E1A5FF', '#CFD8DCFF', '#C8E6C9FF', '#DCEDC8FF', '#F0F4C3FF', '#F1F8E9FF', '#FFF9C4FF', '#FFF8E1FF', '#FFFDE7FF',
  ];
  const randomIndex = Math.floor(Math.random() * 35); // Random value between 0 and 35
  return pastelColors[randomIndex];
}

export function toPastelColor(str: string): string {
  let hash: number = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // hash = str.charCodeAt(i) + hash;
  }

  const hue = hash % 360;
  const saturation = hash % 10 + 85;
  const lightness = hash % 4 + 88;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function toDarkColor(str: string): string {
  let hash: number = 0;
  for (const char of str) {
    // tslint:disable-next-line:no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return 'hsl(' + hue.toString(10) + ', 100%, 50%, 50%)';
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
