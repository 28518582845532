// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
import Vue from 'vue';
import * as Domain from '../models/Domain';

export function computeDerivedFields(studio: Domain.Studio, userId: string, newAction: Domain.Action | null = null) {
  // setup derived fields with initial values - we can't rely on ctor to have run.

  let actionMap = studio.actions;
  if (newAction !== null) { //only act on new action if one was provided
    actionMap = new Map<string, Domain.Action>();
    actionMap.set(newAction.id, newAction);
  }

  // actionMap.forEach((value) => {
  // });

  incrementDerivedFields(studio);

}

export function incrementDerivedFields(studio: Domain.Studio) {
  // setup derived fields with initial values - we can't rely on ctor to have run.
  if (studio.derivedChangeCount === null || studio.derivedChangeCount === undefined) {
    Vue.set(studio, 'derivedChangeCount', 0);
  }

  // increment change count
  studio.derivedChangeCount += 1;
}
