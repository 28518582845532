/* eslint-disable no-console */
// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
import * as Domain from '@/models/Domain';
import Services from '@/services/Services';
import Vue from 'vue';

let debug = process.env.NODE_ENV !== 'production';
let debugPrefix = ' ToDoHelper ';

export async function initialize(): Promise<void> {
  debug = await Services.IsDebugOverride('TodoHelper');
  debugPrefix = Services.GetGenericChalkCategory(debugPrefix.trim());
}

export function computeDerivedFields(studio: Domain.Studio, toDo: Domain.ToDo, userId: string, newAction: Domain.Action | null = null) {
  // check that todo is part of specified studio
  if (toDo.studioId !== studio.id) { throw new Error('ToDo.StudioId <> Studio.id'); }

  // setup derived fields with initial values - we can't rely on ctor to have run.
  if (toDo.derivedLatestMessage === null || toDo.derivedLatestMessage === undefined) {
    Vue.set(toDo, 'derivedLatestMessage', new Domain.NoInvolvement(userId, toDo.creationInstant));
  }

  let actionMap = toDo.actions;
  if (newAction !== null) { //only act on new action if one was provided
    actionMap = new Map<string, Domain.Action>();
    actionMap.set(newAction.id, newAction);
  }

  actionMap.forEach((value) => {
    if ((value instanceof Domain.ActionWithMessage) && (value.messageBody.length > 0)) {
      if (value.creationInstant.getTime() > toDo.derivedLatestMessage.creationInstant.getTime()) {
        // derivedLatestMessage
        toDo.derivedLatestMessage = value;
      }
    }
  });

  incrementDerivedFields(studio, toDo);

  if (debug) { console.log(debugPrefix, 'computeDerivedFields', studio, toDo, userId, newAction);}
}

export function incrementDerivedFields(studio: Domain.Studio, toDo: Domain.ToDo) {
  // check that todo is part of specified studio
  if (toDo.studioId !== studio.id) { throw new Error('ToDo.StudioId <> Studio.id'); }

  // setup derived fields with initial values - we can't rely on ctor to have run.
  if (studio.derivedChangeCount === null || studio.derivedChangeCount === undefined) {
    Vue.set(studio, 'derivedChangeCount', 0);
  }
  
  // setup derived fields with initial values - we can't rely on ctor to have run.
  if (toDo.derivedChangeCount === null || toDo.derivedChangeCount === undefined) {
    Vue.set(toDo, 'derivedChangeCount', 0);
  }

  // increment change count
  studio.derivedChangeCount += 1;
  toDo.derivedChangeCount += 1;
}
