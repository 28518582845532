import * as Domain from '@/models/Domain';
import VueI18n from 'vue-i18n';
import Services from '@/services/Services';

export class AppLocale {
  public title: string;
  public id: Domain.EnumLocale;
  public v18nLocale: string;

  constructor(title: string, id: Domain.EnumLocale, v18nLocale: string) {
    this.title = title;
    this.id = id;
    this.v18nLocale = v18nLocale;
  }
}

const appLocales: AppLocale[] = [];
appLocales.push(new AppLocale('English', Domain.EnumLocale.en, 'en'));
appLocales.push(new AppLocale('Français', Domain.EnumLocale.fr, 'fr'));

export function setLocale(vue18n: VueI18n, locale: Domain.EnumLocale) {
  if (locale === (vue18n.locale as Domain.EnumLocale)) {return;} 

  const localeItem = appLocales.find(x => x.id === locale);
  if (!localeItem) {
    Services.AppInsights.trackEvent({ name: 'LocaleNotFound' }, { locale });
    throw new Error(`Locale ${locale} not found in config`);
  } 

  if (vue18n.availableLocales.find(x => x === localeItem.v18nLocale) === undefined) {
    Services.AppInsights.trackEvent({ name: 'LocaleNotFoundInAssets' }, { locale });
    throw new Error(`Locale ${locale} not found in assets`);
  } 

  vue18n.locale = locale.toString();
}

export function getLocales(): AppLocale[] {
  return appLocales;
}

export function getClosestLocale(locale: string): Domain.EnumLocale {
  const appLocale = appLocales.find(x => x.id === locale);
  if (appLocale) {return appLocale.id;}

  const localeParts = locale.split('-');
  if (localeParts.length > 1) {
    const localePart = localeParts[0];
    const appLocale = appLocales.find(x => x.id === localePart);
    if (appLocale) {return appLocale.id;}
  }

  Services.AppInsights.trackEvent({ name: 'StartupLocaleNotFound' }, { locale });

  return Domain.EnumLocale.en;
}

