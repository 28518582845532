/* eslint-disable camelcase */
import * as Domain from '@/models/Domain';
import * as ModuleDomain from '@/models/ModuleDomain';

export abstract class UserModuleMutation extends ModuleDomain.MutationBase implements ModuleDomain.IMutationAltersData {
  public metadata_upserts: any[] = [];
  public metadata_deletes: any[] = [];
  public metadata_potentiallyNothingToPersist: boolean = false;
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerMutationRestore
export class RestoreMutation extends UserModuleMutation {
  public users: Domain.PublicUser[] = [];
  public removedUserIds: string[] = [];
  public out_error: boolean = false;

  constructor(users: Domain.PublicUser[], removedUserIds: string[]) {
    super();
    this.metadata_potentiallyNothingToPersist = true;

    this.users = users;
    this.removedUserIds = removedUserIds;
  }

  public static MutationName: string = 'user/restore';
}

@ModuleDomain.registerMutationAltersData
export class ResetMutation extends UserModuleMutation {
  constructor() {
    super();
    this.metadata_potentiallyNothingToPersist = true;
  }
  public static MutationName: string = 'user/reset';
}

@ModuleDomain.registerMutationAltersData
export class AddLoggedInUserMutation extends UserModuleMutation {
  public user: Domain.PublicUser;

  constructor(user: Domain.PublicUser) {
    super();
    this.user = user;
  }

  public static MutationName: string = 'user/addLoggedInUser';
}
