<template>
  <v-dialog v-model="value" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 500" @keydown.esc="onClose" @keydown.enter="onClose" persistent scrollable no-click-animation max-width="500" :width="$vuetify.breakpoint.width - 10">
    <v-card flat color="background" :style="`height: {{height}}px;`">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title class="subtitle-1 ml-1 my-0 pa-0" data-testid="DialogTitle">{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined rounded style="border-width: thin; border-radius: 12px;" class="button text-none" @click="onClose" data-testid="DialogCloseBtn">{{ $t('G.close') }}</v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="px-2 py-0">
        <slot name="text"></slot>
      </v-card-text>
      <v-card-actions class="px-2 pt-0 pb-2">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
  name: 't-dialog-message',
})
export default class TDialogMessage extends Vue {
  @Prop({ type: Boolean, default: true })
  public readonly value!: boolean;
  @Prop({ type: Number, default: 500 })
  public readonly height!: number;
  @Prop({ type: String, required: true })
  public readonly title!: string;
  // Events
  // this.$emit('input', false);

  public onClose() {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "emit": "close" }');
    }
    this.$emit('input', false);
  }
}
</script>

