import { UUID } from 'uuidjs';

// this interface is required because it is currently too costly to observe what changes in vuex to trigger a local persistance save
/* eslint-disable camelcase */
export interface IMutationAltersData {
  metadata_upserts: any[];
  metadata_deletes: any[];
  metadata_potentiallyNothingToPersist: boolean;
}

export class MutationBase {
  public now: Date;
  public timeZone: string;
  public mutationId: string;

  constructor() {
    this.now = new Date();
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.mutationId = UUID.genV1().toString();
  }
}

export class ActionBase {
  public now: Date;
  public actionId: string;

  constructor() {
    this.now = new Date();
    this.actionId = UUID.genV1().toString();
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function registerMutationSendsToAppInsights(constructor: any): void {
  constructor.prototype.dyn_MutationSendsToAppInsights = true;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IsMutationSendsToAppInsights(obj: any): boolean {
  if (obj && obj.dyn_MutationSendsToAppInsights) { return true; };
  return false;
}


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function registerMutationAltersData(constructor: any): void {
  constructor.prototype.dyn_IMutationAltersData = true;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IsMutationAltersData(obj: any): boolean {
  if (obj && obj.dyn_IMutationAltersData) { return true; };
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function registerMutationAltersMeUser(constructor: any): void {
  constructor.prototype.dyn_IMutationAltersMeUser = true;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IsMutationAltersMeUser(obj: any): boolean {
  if (obj && obj.dyn_IMutationAltersMeUser) { return true; };
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function registerMutationRestore(constructor: any): void {
  constructor.prototype.dyn_restore = true;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IsMutationRestore(obj: any): boolean {
  if (obj && obj.dyn_restore) { return true; };
  return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function registerSyncToServer(constructor: any): void {
  constructor.prototype.dyn_syncToServer = true;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function IsSyncToServer(obj: any): boolean {
  if (obj && obj.dyn_syncToServer) { return true; };
  return false;
}
