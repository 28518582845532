<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 490" @keydown.esc="onCancel" @keydown.enter="onEnter" persistent scrollable no-click-animation max-width="500" :width="$vuetify.breakpoint.width - 10">
    <v-card flat color="background" style="height: 490px;">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel" data-testid="dialogCancelBtn">
            <t-icon href="#close" :title="$t('G.cancel')" color="white" :size="24"/>
          </v-btn>
          <v-toolbar-title class="subtitle-1">{{ $t('C.TDialogSignOut.dialogTitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined rounded style="border-width: thin; border-radius: 12px;" class="button text-none" @click="onConfirm" data-testid="dialogConfirmBtn">{{ $t('G.confirm') }}</v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-10 mx-0 pa-0">
        <v-layout column align-center class="text-center ma-0 py-4 px-0">
          <div style="line-height: 120%; font-size: 120%;" class="grey--text text--darken-1 text-none text-center ma-0 pa-0" data-testid="signOutDialogConfirm"><b>{{ $t('C.TDialogSignOut.message-1') }}</b></div>
          <div style="line-height: 100%; font-size: 100%;" class="grey--text text--darken-1 text-none text-center mt-2 mx-0 pa-0"><i>{{ $t('C.TDialogSignOut.message-2') }}</i></div>

          <div v-if="pendingMutationsToSyncCount > 0" style="line-height: 100%; white-space: pre-line;" class="red--text text--darken-1 text-none text-center mt-12 mx-0 pa-0" data-testid="signOutDialogAttention"><b>{{ $t('C.TDialogSignOut.caution') }}</b></div>
          <t-panel-status v-if="pendingMutationsToSyncCount > 0" class="mt-4 mx-0 pa-0" :user="user" :pendingMutationsToSyncCount="pendingMutationsToSyncCount" :isOnline="isOnline"/>
        </v-layout>
      </v-card-text>
      <v-card flat color="transparent" height="24" class="ma-0 pa-0"></v-card>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import * as AuthenticationModuleDomain from '../models/AuthenticationModuleDomain';
import TIcon from '../components/TIcon.vue';
import TPanelStatus from '../components/TPanelStatus.vue';

@Component({
  components: {
    TIcon, TPanelStatus,
  },
  name: 't-dialog-sign-out',
})
export default class TDialogSignOut extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly dialog!: boolean;
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly user!: Domain.User | null;
  @Prop({ type: Boolean, default: false })
  public readonly isOnline: boolean;
  @Prop({ type: Number, default: 0 })
  public readonly pendingMutationsToSyncCount: number;
  // Events
  // this.$emit('cancel');
  // this.$emit('confirm');

  public onEnter() {
    this.onConfirm();
  }

  public async onConfirm() {
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AuthenticationModuleDomain.SignOutAction.ActionName + '" }');
    } else {
      await this.$store.dispatch(AuthenticationModuleDomain.SignOutAction.ActionName, new AuthenticationModuleDomain.SignOutAction());
    }
    this.$emit('confirm');
  }

  public onCancel() {
    this.$emit('cancel');
  }
}
</script>

