import AppInsights from '../plugins/applicationInsights';
import Vue from 'vue';
import { Toasted } from 'vue-toasted';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as Storage from '../plugins/AppStorage';
import chalk, { ChalkInstance } from 'chalk';
import i18n from '../plugins/i18n';
import VueI18n from 'vue-i18n';
import Store from '../plugins/vuex';

chalk.level = 2;

class Services {
  public async ResetLocalDatabases(includeMutations: boolean): Promise<void> {
    await this.StudioOfflineStorage.clear();
    await this.ToDoOfflineStorage.clear();
    await this.NotificationOfflineStorage.clear();
    await this.UserOfflineStorage.clear();
    await this.AppStorage.clear();

    if (includeMutations) {
      await this.MutationsToSyncStorage.clear();
    }
  }
  public LogException(err: Error) {
    this.AppInsights.trackException({ exception: err });

    // eslint-disable-next-line no-console
    console.error(this.GetTDSChalkCategory('To-Do Studio'), err);
  }
  public async IsDebugOverride(type: string): Promise<boolean> {

    const debugOverride = await this.AppPersistantStorage.getItem(`Debug_${type}`); // localStorage['To-Do.Studio/App/Debug_${TYPE}'] = true
    if (debugOverride !== null) {
      // eslint-disable-next-line no-console
      console.warn(this.GetTDSChalkCategory('To.Do Studio'), `Debugging Override flag found : ${type}...`);
      return true;
    }
    if (process.env.NODE_ENV === 'production' &&
      Store.state.config.environmentName.toLowerCase() !== 'prodXXX') { //todo remove XXX whn prod is stable enough.
      // eslint-disable-next-line no-console
      console.warn(this.GetTDSChalkCategory('To.Do Studio'), `Debugging Override environment : ${type}...`);
      return true;
    }

    if (process.env.NODE_ENV !== 'production') { return true; }
    return false;
  }
  public async GetOverrideValueNumber(moduleName: string, settingName: string, defaultValue: number): Promise<number> {
    const override = await this.AppPersistantStorage.getItem(`Override_${moduleName}_${settingName}`);
    if (override !== null) {
      // eslint-disable-next-line no-console
      console.warn(this.GetTDSChalkCategory('To.Do Studio'), `Override value found : ${moduleName}_${settingName}... ${override}`);
      return override as number;
    }
    return defaultValue;
  }
  public get Toaster(): Toasted {
    return Vue.toasted;
  }
  public get AppInsights(): ApplicationInsights {
    return AppInsights;
  }
  public get MutationsToSyncStorage(): LocalForage {
    return Storage.MutationsToSyncStorage;
  }
  public get AppStorage(): LocalForage {
    return Storage.AppStorage;
  }
  public get AppPersistantStorage(): LocalForage {
    return Storage.AppPersistantStorage;
  }
  public get StudioOfflineStorage(): LocalForage {
    return Storage.StudioOfflineStorage;
  }
  public get ToDoOfflineStorage(): LocalForage {
    return Storage.ToDoOfflineStorage;
  }
  public get NotificationOfflineStorage(): LocalForage {
    return Storage.NotificationOfflineStorage;
  }
  public get UserOfflineStorage(): LocalForage {
    return Storage.UserOfflineStorage;
  }
  public get Chalk(): ChalkInstance {
    return chalk;
  }
  public GetGenericChalkCategory(name: string): string {
    return chalk.bgYellowBright(` ${name} `);
  }
  public GetTDSChalkCategory(name: string): string {
    return `[100m ${name} [49m `;
  }
  public get I18N(): VueI18n {
    return i18n;
  }
}

const services = new Services();

export default services;
