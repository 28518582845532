import * as ModuleDomain from '@/models/ModuleDomain';

export class ServerDefinition {
  public id: string;
  public name: string;
  public authorityUrl: string;
  public authorityClientId: string;
  public apiUrl: string;
  public apiScope: string;
}

export class InternalInitializeMutation extends ModuleDomain.MutationBase {
  public static MutationName: string = 'internalInitialize';
}

export class InitializeAction extends ModuleDomain.ActionBase {
  public static ActionName: string = 'config/initialize';
}

export class InternalSetCurrentServerEnvironmentMutation extends ModuleDomain.MutationBase {
  public server: ServerDefinition;

  constructor(server: ServerDefinition) {
    super();
    this.server = server;
  }

  public static MutationName: string = 'internalSetCurrentServerEnvironment';
}

export class ChangeServerEnvironmentAction extends ModuleDomain.ActionBase {
  public serverEnvironmentId: string;

  constructor(serverEnvironmentId: string) {
    super();
    this.serverEnvironmentId = serverEnvironmentId;
  }

  public static ActionName: string = 'config/changeServerEnvironment';
}
