<template>
<v-container ref="page" fluid class="ma-0 pa-0">
  <v-card ref="scrollableCard" flat color="transparent" :height="contentHeight" :width="contentWidth" style="overflow-x: hidden; overflow-y: auto;" class="pa-0 ma-0">
    <v-card flat color="transparent" :width="scrollableWidth" class="pa-0 ma-0">
      <v-layout column justify-center align-center >
        <v-card flat color="transparent" min-width="350" max-width="520" elevation-1>
          <v-layout column align-center >
            <div class="red--text text--darken-1 body-2 text-center font-italic mx-1 my-3 pa-0" v-if="reason === 'sign-out'">{{ $t('P.SignIn.ReasonSignIn') }}</div>
            <div class="red--text text--darken-1 body-2 text-center font-italic mx-1 my-3 pa-0" v-if="reason === 'reset'">{{ $t('P.SignIn.ReasonReset') }}</div>
            <div class="red--text text--darken-1 body-2 text-center font-italic mx-1 my-3 pa-0" v-if="reason === 'token-expired'">{{ $t('P.SignIn.ReasonTokenExpired') }}</div>
            <div class="red--text text--darken-1 body-2 text-center font-italic mx-1 my-3 pa-0" v-if="reason === 'changed-environment'">{{ $t('P.SignIn.ReasonChangedEnvironment') }}</div>
            <div class="title text-center mx-0 my-6 pa-0">{{ $t('P.SignIn.Title') }}</div>
            <template v-if="isServerConfigured">
              <div class="body-2 text-center mx-0 my-2 pa-0" v-html="$t('P.SignIn.Instructions')"></div>
              <div v-if="isOffline" class="red--text text--darken-1 body-2 text-center mx-0 my-2 pa-0">{{ $t('P.SignIn.OfflineWarning') }}</div>
              <v-btn large block rounded style="border-radius: 12px;" class="button primary text-none mt-6 pa-8" @click="signInSocial" data-testid="signIn">
                {{ $t('P.SignIn.SignInButton') }}
              </v-btn>
              <div style="line-height: 200%; font-size: 80%;" class="text-center mx-0 my-0 pa-0">{{ $t('P.SignIn.SSOProviders') }}</div>
              <!--<v-btn large block rounded disabled style="border-radius: 12px;" class="button primary text-none mt-6 pa-8" @click="signInBusiness" data-testid="signIn">
                Sign in With Your Business Account
              </v-btn>-->
            </template>
            <template v-else> <!-- server is NOT configured - run in no backend mode -->
              <div class="body-2 text-center mx-0 my-6 pa-0">{{ $t('P.SignIn.NoBackendInstructions') }}</div>
              <div v-for="identity in $store.state.authentication.availableServerlessSignInIdentities" :key="'btn-' + identity.id">
                <v-btn large block rounded style="border-radius: 12px;" class="button primary text-none" @click="signInServerless(identity.id)">
                  {{ $t('P.SignIn.SignInServerlessButton') }} {{identity.fullName}}
                </v-btn>
                <div class="body-2 text-center mx-0 my-3 pa-0">{{identity.description}}</div>
              </div>
            </template>
          </v-layout>
        </v-card>
      </v-layout>
    </v-card>
  </v-card>
</v-container>
</template>

<script lang="ts">
/* tslint:disable:max-line-length  */
import { Component, Vue } from 'vue-property-decorator';
import * as AuthenticationModuleDomain from '../models/AuthenticationModuleDomain';

@Component
export default class SignIn extends Vue {

  public reason: string = ''; // empty, token-expired, sign-out
  public contentWidth: number = 0;
  public scrollableWidth: number = 0;
  private resizeObserver: ResizeObserver | null = null;
 
  private beforeMount() {
    this.contentWidth = (this as any).$vuetify.breakpoint.width;
    this.scrollableWidth = (this as any).$vuetify.breakpoint.width;
  }

  private mounted() {
    this.$nextTick(() => {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (this.$refs.page !== undefined) {
            if (entry.target === (this.$refs.page as Element)) {
              this.contentWidth = Math.floor(entry.contentRect.width);
            }
          }
          if (this.$refs.scrollableCard !== undefined) {
            if (entry.target === (this.$refs.scrollableCard as any).$el) {
              this.scrollableWidth = Math.floor(entry.contentRect.width);
            }
          }
        }
      });
      if (this.$refs.page !== undefined) {
        this.resizeObserver.observe((this.$refs.page as Element));
        this.contentWidth = (this.$refs.page as any).clientWidth;
      }
      if (this.$refs.scrollableCard !== undefined) {
        this.resizeObserver.observe((this.$refs.scrollableCard as any).$el);
        this.scrollableWidth = (this.$refs.scrollableCard as any).clientWidth;
      }
    });
  }

  private beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private created() {
    this.reason = this.$route.params.reason === undefined ? '' : this.$route.params.reason;
  }

  public get contentHeight(): number {
    const headerHeight: number = (this as any).$vuetify.application.top;
    const footerHeight: number = (this as any).$vuetify.application.footer;

    const height = (this as any).$vuetify.breakpoint.height - headerHeight - footerHeight - 8; // remove 8px for header elevation
    return height;
  }

  public get isServerConfigured(): boolean {
    return this.$store.getters['config/isServerConfigured'];
  }

  public get isOffline(): boolean {
    return (this.$store.getters['app/isOnline'] === false);
  }

  public async signInServerless(serverlessUserId: string) {
    const searchParams = new URLSearchParams(window.location.search.toLowerCase());
    let redirect: string | null = null;
    if (searchParams.has('redirect')) {
      redirect = searchParams.get('redirect');
    }

    const payload = new AuthenticationModuleDomain.SignInServerlessAction(serverlessUserId, redirect);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AuthenticationModuleDomain.SignInServerlessAction.ActionName + '" }');
    } else {
      await this.$store.dispatch(AuthenticationModuleDomain.SignInServerlessAction.ActionName, payload);
    }
  }

  public async signInSocial() {
    const searchParams = new URLSearchParams(window.location.search.toLowerCase());
    let redirect: string | null = null;
    if (searchParams.has('redirect')) {
      redirect = searchParams.get('redirect');
    }

    const payload = new AuthenticationModuleDomain.SignInAction(redirect);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AuthenticationModuleDomain.SignInAction.ActionName + '" }');
    } else {
      await this.$store.dispatch(AuthenticationModuleDomain.SignInAction.ActionName, payload);
    }
  }

  private async signInBusiness() {
    // toDo
  }
}
</script>

