import Vue from 'vue';
// tslint:disable-next-line: max-line-length
import Vuetify, {
  VApp, VAppBar, VAppBarNavIcon, VAvatar, VCard, VCardTitle, VCardText, VContainer, VCol, VRow, VHover,
  VBtn, VBtnToggle, VIcon, VLayout, VFlex, VTooltip, VNavigationDrawer, VSubheader, VDivider, VForm, VBadge, VFooter,
  VList, VListItem, VListItemContent, VListItemTitle, VListItemSubtitle, VListItemAvatar, VListItemAction,
  VToolbar, VToolbarTitle, VSpacer, VSpeedDial, VMenu, VProgressCircular, VDatePicker, VTimePicker, VTimePickerClock,
  VTextField, VTextarea, VCheckbox, VCardActions, VSwitch, VSheet, VOverflowBtn,
  VDialog, VRadio, VRadioGroup, VChip, VItemGroup, VItem, VSelect, VTabs, VTab, VTabsItems, VTabItem,
} from 'vuetify/lib';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAvatar,
    VBadge,
    VBtn,
    VBtnToggle,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VCheckbox,
    VChip,
    VContainer,
    VCol,
    VDatePicker,
    VDivider,
    VDialog,
    VFlex,
    VFooter,
    VForm,
    VHover,
    VIcon,
    VItemGroup,
    VItem,
    VLayout,
    VNavigationDrawer,
    VList,
    VListItem,
    VListItemAvatar,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VMenu,
    VOverflowBtn,
    VRadio,
    VRadioGroup,
    VRow,
    VSheet,
    VSubheader,
    VSelect,
    VSpacer,
    VSpeedDial,
    VSwitch,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VTimePicker, 
    VTimePickerClock,
    VToolbar,
    VToolbarTitle,
    VTooltip,
    VTextField,
    VTextarea,
    VProgressCircular,
  },
});

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  customProperties: true,
  theme: {
    dark: false,
    themes: {
      light: {
        background: '#FFFFF6',
        primary: '#1565C0',
        secondary: '#333333',
        accent: '#E3F2FD',
      },
      dark: {
        background: '#FFFFF6',
        primary: '#1565C0',
        secondary: '#333333',
        accent: '#E3F2FD',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 690,
      sm: 1200,
      md: 1800,
      lg: 2400,
    },
  },
});
