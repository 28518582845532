<template>
  <transition name="cookie-consent-transition">
    <div v-if="show" class="cookie-consent ma-0 pa-0" role="dialog">
      <slot name="message">
        <span class="grey--text text--lighten-2 caption mx-3 my-2">
          {{ $t('C.TCookieConsent.cookie-reason') }}
          <slot name="link">
            <a href="https://info.to-do.studio/privacyPolicy" target="_blank" rel="noopener" class="grey--text text--lighten-2 caption ma-3" role="button" data-testid="learnMoreUrl">{{ $t('C.TCookieConsent.learn-more') }}</a>
          </slot>
        </span>
      </slot>
      <section>
        <slot name="button">
          <v-btn outlined large rounded style="border-radius: 12px;" class="white--text button text-none my-2" @click="acceptCookieConsent" data-testid="consentButton">{{ $t('C.TCookieConsent.got-it') }}</v-btn>
        </slot>
      </section>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
  name: 't-cookie-consent',
})
export default class TCookieConsent extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDev: boolean;

  public show: boolean = false;

  private beforeMount(): void {
    this.show = !this.getCookie('to-do-studio-cookie-consent');
    if (this.isDev) {
      this.show = true;
    }
    if (this.show && !this.isDev) {
      (this as any).$appInsights.trackEvent('ShowCookieConsent');
    }
  }

  public acceptCookieConsent(): void {
    this.show = false;
    if (this.isDev) {
      // Show info in StoryBook
      const cookieInfo = {
        name: 'to-do-studio-cookie-consent',
        value: 'yes',
        expiryDays: 2048,
        domain: '',
        path: '/',
      };
      this.$emit('set-cookie', cookieInfo);
    } else {
      this.setCookie('to-do-studio-cookie-consent', 'yes', 2048, '', '/');
      (this as any).$appInsights.trackEvent('AcceptCookieConsent');
    }
  }

  private getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts!.length !== 2) {
      return undefined;
    }

    return parts.pop()!.split(';').shift();
  }

  private setCookie(
    name: string,
    value: string,
    expiryDays: number,
    domain: string,
    path: string,
  ): void {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + (expiryDays || 365));

    const cookie = [
      `${name}=${value}`,
      `expires=${exdate.toUTCString()}`,
      `path=${path || '/'}`,
    ];

    if (domain) {
      cookie.push(`domain=${domain}`);
    }

    document.cookie = cookie.join(';');
  }
}
</script>

<style lang="scss" scoped>
$cookieconstent-padding: 1rem 0 !default;
$cookieconstent-space: 0.5rem 1rem !default;
$cookieconstent-position: fixed !default;
$cookieconstent-background: #333333 !default;
$cookieconstent-z-index: 9999 !default;
$cookieconstent-width: 100% !default;

.cookie-consent {
  position: $cookieconstent-position;
  z-index: $cookieconstent-z-index;
  width: $cookieconstent-width;
  padding: $cookieconstent-padding;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background: $cookieconstent-background;
}

.cookie-consent-transition {
  &-leave-active {
    transition: transform 0.75s;
    transition-timing-function: cubic-bezier(0.75, 0, 0, 1);
  }
  &-leave-to {
    transform: translateY(100%);
  }
}

.cookie-consent {
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
