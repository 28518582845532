// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import * as Domain from '../models/Domain';
import store from '../plugins/vuex';
import * as UserModuleDomain from '@/models/UserModuleDomain';
import * as PersistanceHelper from '@/helpers/PersistanceHelper';
import Services from '@/services/Services';

@Module({ dynamic: true, store, namespaced: true, name: 'user' })
export default class UserModule extends VuexModule {
  public users: Map<string, Domain.PublicUser> = new Map<string, Domain.PublicUser>();
  public usersArr: Domain.PublicUser[] = []; // todovue3 remove this when vuejs supports map reactivity

  @Mutation
  public restore(payload: UserModuleDomain.RestoreMutation): void {
    try {
      payload.users.forEach((item) => {
        const matched = this.users.get(item.id);
        if (matched) {
          PersistanceHelper.Update(matched, item, `PublicUser ${item.id}`);
        } else {
          this.users.set(item.id, PersistanceHelper.Update(null, item, `PublicUser ${item.id}`));
          this.usersArr.push(this.users.get(item.id) as Domain.PublicUser);
        }
      });
      payload.removedUserIds.forEach((item) => {
        this.users.delete(item);

        const toRemove = this.usersArr.find((t) => t.id === item);
        if (toRemove !== undefined) {
          this.usersArr.splice(this.usersArr.indexOf(toRemove), 1);
        }
      });
    } catch (err: any) {
      Services.LogException(err);
      payload.out_error = true;
    }
  }

  @Mutation
  public reset(payload: UserModuleDomain.ResetMutation): void {
    this.users.forEach((value) => {
      payload.metadata_deletes.push(value);
    });
    this.users.clear();

    while (this.usersArr.length !== 0) {
      this.usersArr.pop();
    }
  }

  @Mutation
  public addLoggedInUser(payload: UserModuleDomain.AddLoggedInUserMutation): void {
    if (this.users.get(payload.user.id) !== undefined) {
      payload.metadata_potentiallyNothingToPersist = true;
      return;
    }

    this.users.set(payload.user.id, payload.user);
    this.usersArr.push(payload.user);

    if (payload.user.id.endsWith('-*')) {
      let userF = new Domain.PublicUser('Mario-*1', 'Mario', 'Mario', 'Doe', Domain.EnumLocale.en, '#user-mario');
      this.users.set(userF.id, userF);
      this.usersArr.push(userF);
      payload.metadata_upserts.push(userF);

      userF = new Domain.PublicUser('Erik-*2', 'Erik', 'Erik', 'Doe', Domain.EnumLocale.en, '#user-erik');
      this.users.set(userF.id, userF);
      this.usersArr.push(userF);
      payload.metadata_upserts.push(userF);

      userF = new Domain.PublicUser('Maryna-*3', 'Maryna', 'Maryna', 'Doe', Domain.EnumLocale.en, '');
      this.users.set(userF.id, userF);
      this.usersArr.push(userF);
      payload.metadata_upserts.push(userF);

      userF = new Domain.PublicUser('Danielle-*4', 'Danielle', 'Danielle', 'Doe', Domain.EnumLocale.en, '');
      this.users.set(userF.id, userF);
      this.usersArr.push(userF);
      payload.metadata_upserts.push(userF);
    }

    payload.metadata_upserts.push(payload.user);
  }
}
