/* eslint-disable curly */
/* eslint-disable no-console */

import { register } from 'register-service-worker';
import Services from '@/services/Services';
import '@/assets/styles/toasted.css';
import store from '@/plugins/vuex';
import * as AppModuleDomain from '@/models/AppModuleDomain';

const debugPrefix: string = '[45m RegisterServiceWorker [49m';
const debug = true; // todoerik for some reason doesn't display console stuff.

let refreshing = false; // used to prevent infinite reloads if "refresh SW on reload is active"

// eslint-disable-next-line no-constant-condition
if (process.env.NODE_ENV === 'production') {

  window.addEventListener('appinstalled', () => { // signature : evt
    Services.AppInsights.trackEvent({ name: 'PWAInstalled' });
    if (debug) console.warn(debugPrefix, 'App was installed');

    Services.Toaster.info('Congratulations, To-Do Studio has been installed to your device for convenient access.', {
      duration: 15000,
      className: 'toast-inner',
    });
  });

  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange', function() {
      if (refreshing) return;
      refreshing = true;
      if (debug) console.warn(debugPrefix, 'Service worker is now controlling us');
    });
  }

  register(`${process.env.BASE_URL}serviceWorker.js`, {
    registrationOptions: { scope: '/' },
    ready(registration: ServiceWorkerRegistration) { // params : registration: ServiceWorkerRegistration
      if (debug) console.info(debugPrefix, 'Service worker is ready.');

      if (window.location.pathname.toLowerCase() === '/force-update') {
        registration.update();
        if (debug) console.warn(debugPrefix, 'Forced update detection');
      }

      store.commit(AppModuleDomain.SetServiceWorkerReadyMutation.MutationName, new AppModuleDomain.SetServiceWorkerReadyMutation(true));
    },
    registered() { // params : registration: ServiceWorkerRegistration
      if (debug) console.info(debugPrefix, 'Service worker has been registered.');
    },
    cached() { // params : registration: ServiceWorkerRegistration
      if (debug) console.info(debugPrefix, 'Content has been cached for offline use.');
    },
    updatefound() { // params : registration: ServiceWorkerRegistration
      Services.AppInsights.trackEvent({ name: 'PWAUpdateFound' });

      if (debug) console.info(debugPrefix, 'New content is downloading.');
    },
    updated(registration: ServiceWorkerRegistration) { // params : registration: ServiceWorkerRegistration
      Services.AppInsights.trackEvent({ name: 'PWAUpdateReady' });
      if (debug) console.warn(debugPrefix, 'New content is available; please refresh.');
      Services.Toaster.info('A new version of To-Do Studio is available, save your work and refresh now to use it.', {
        duration: 120000,
        className: 'toast-inner',
        action: {
          text: 'Refresh',
          class: 'toast-action',
          onClick: (_e, toast) => {
            const sw = registration.waiting;
            if (sw !== null) {
              if (debug) console.info(debugPrefix, 'Waiting service worker found');
              sw.postMessage('ServiceWorkerSkipWaiting');
            } else {
              if (debug) console.error(debugPrefix, 'Waiting service worker NOT found');
            }

            toast.goAway(0);

            window.setTimeout(function() { // todoerik we seem to always fall here
              if (debug) console.error(debugPrefix, 'Tab reloading because ServiceWorker did not reboot us');
              window.location.reload();
            }, 10000); // refresh in 10 seconds if ever the service worker didn't refresh us !

            store.commit(AppModuleDomain.SetIsAppWaiting.MutationName, new AppModuleDomain.SetIsAppWaiting(true));
          },
        },
      });
    },
    offline() {
      if (debug) console.warn(debugPrefix, 'No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
} else {
}
