import PubSub from 'pubsub-js';

export enum EventKind {
  // eslint-disable-next-line no-unused-vars
  MutationCommitted = 'MutationCommitted',
  // eslint-disable-next-line no-unused-vars
  SyncFinished = 'SyncFinished'
}

class EventSub {
  public Publish(eventKind: EventKind, data: any): void {
    PubSub.publish(eventKind, data);
  }

  public Subscribe(eventKind: EventKind, subscribeFunction: Function): any {
    return PubSub.subscribe(eventKind, subscribeFunction);
  }

  public Unsubscribe(token: any): void {
    return PubSub.unsubscribe(token);
  }
}

export const Bus = new EventSub();
