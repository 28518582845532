<template>
  <v-card flat color="transparent" :height="50" :width="btnWidth" class="ma-0 pa-0">
    <v-card v-if="isActive" flat color="transparent" class="ma-0 pt-1 pb-0 px-0">
      <v-layout column align-center class="ma-0 pa-0">
        <t-icon color="primary" :href="btnIcon" :title="btnTitle" :size="24" class="ma-0 pa-0"/>
        <div :style="cssStyleForTitle" class="primary--text text-none text-center ma-0 pa-0" data-testid="activeBtnTitle">
          {{ btnTitle }}
        </div>
        <v-card flat color="#1976D2" width="100%" :height="2" class="ma-0 pa-0"></v-card>
      </v-layout>
    </v-card>
    <v-card v-else flat color="transparent" ripple @click="onClick" class="ma-0 pt-1 pb-0 px-0">
      <v-layout column align-center class="ma-0 pa-0">
        <t-icon color="#AAAAAA" :href="btnIcon" :title="btnTitle" :size="24" id="scale-on-hover" class="ma-0 pa-0"/>
        <div :style="cssStyleForTitle" class="grey--text text-none text-center ma-0 pa-0" data-testid="btnTitle">
          {{ btnTitle }}
        </div>
        <v-card flat color="transparent" width="100%" :height="2" class="ma-0 pa-0"></v-card>
      </v-layout>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-header-nav-bar-btn',
})
export default class THeaderNavBarBtn extends Vue {
  @Prop({ type: String, required: true })
  public readonly btn!: string;
  @Prop({ type: Boolean, default: true })
  public readonly isActive!: boolean;
  @Prop({ type: String, default: 'tips' })
  public readonly nav!: string;
  // Events

  public get cssStyleForTitle(): string {
    return 'line-height: 120%; font-size: 65%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: ' + (this.btnWidth) + 'px;';
  }

  public onClick() {
    const route = ((this.nav === 'tips') || (this.nav === 'agenda-upcoming') || (this.nav === 'small-wins-all') || (this.nav === 'studios')) ? this.nav : 'tips';
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "route": "' + route + '" }');
    } else {
      // tslint:disable-next-line:no-empty
      this.$router.push({ name: route }).catch(() => { });
    }
  }

  public get btnTitle(): string {
    switch (this.btn) {
    case 'copilot':
      return this.$t('C.THeader.copilot').toString();
    case 'agenda':
      return this.$t('C.THeader.agenda').toString();
    case 'smallWins':
      return this.$t('C.THeader.small-wins').toString();
    case 'studios':
      return this.$t('C.THeader.studios').toString();
    case 'toDo':
      return this.$t('C.THeader.to-do').toString();
    case 'subscription':
      return this.$t('C.THeader.subscription').toString();
    case 'installation':
      return this.$t('C.THeader.installation').toString();
    case 'profile':
      return this.$t('C.THeader.profile').toString();
    case 'feedback':
      return this.$t('C.THeader.feedback').toString();
    case 'about':
      return this.$t('C.THeader.about').toString();
    }
    return 'unknown';
  }

  public get btnIcon(): string {
    switch (this.btn) {
    case 'copilot':
      return '#copilot';
    case 'agenda':
      return '#agenda';
    case 'smallWins':
      return '#small-wins';
    case 'studios':
      return '#studio';
    case 'toDo':
      return '#to-do';
    case 'subscription':
      return '#subscription';
    case 'installation':
      return '#installation';
    case 'profile':
      return '#account-profile';
    case 'feedback':
      return '#megaphone';
    case 'about':
      return '#info-outline';
    }

    return '#copilot';
  }

  public get btnWidth(): number {
    switch (this.btn) {
    case 'copilot':
      return 40;
    case 'agenda':
      return 40;
    case 'smallWins':
      return 64;
    case 'studios':
      return 44;
    case 'toDo':
      return 44;
    case 'subscription':
      return 60;
    case 'installation':
      return 52;
    case 'profile':
      return 44;
    case 'feedback':
      return 60;
    case 'about':
      return 56;
    }
    return 44;
  }

}
</script>

<style>
#scale-on-hover svg:hover {
  transform: scale(1.15);
}
</style>

