/* eslint-disable camelcase */
import * as ModuleDomain from '@/models/ModuleDomain';
import * as Domain from '@/models/Domain';

export abstract class TipModuleMutation extends ModuleDomain.MutationBase {
}

export class ResetMutation extends TipModuleMutation {
  constructor() {
    super();
  }
  public static MutationName: string = 'tip/reset';
}

export class Compute extends TipModuleMutation {
  public studios: Domain.Studio[];
  public toDos: Domain.ToDo[];
  public userId: string;
  constructor(studios: Domain.Studio[], toDos: Domain.ToDo[], userId: string) {
    super();

    this.studios = studios;
    this.toDos = toDos;

    this.userId = userId;
  }
  public static MutationName: string = 'tip/compute';
}
