/* eslint-disable camelcase */
import * as Domain from './Domain';
import * as ModuleDomain from '@/models/ModuleDomain';

export abstract class NotificationModuleMutation extends ModuleDomain.MutationBase implements ModuleDomain.IMutationAltersData {
  public metadata_upserts: any[] = [];
  public metadata_deletes: any[] = [];
  public metadata_potentiallyNothingToPersist: boolean = false;
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerMutationRestore
export class RestoreMutation extends NotificationModuleMutation {
  public notifications: Domain.Notification[] = [];
  public removedNotificationIds: string[] = [];
  public out_error: boolean = false;

  constructor(notifications: Domain.Notification[], removedNotificationIds: string[]) {
    super();
    this.metadata_potentiallyNothingToPersist = true;

    this.notifications = notifications;
    this.removedNotificationIds = removedNotificationIds;
  }

  public static MutationName: string = 'notification/restore';
}

@ModuleDomain.registerMutationAltersData
export class ResetMutation extends NotificationModuleMutation {
  constructor() {
    super();
    this.metadata_potentiallyNothingToPersist = true;
  }

  public static MutationName: string = 'notification/reset';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class AcknowledgeNotificationMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;

  constructor(userId: string, notificationId: string) {
    super();
    this.notificationId = notificationId;
    this.userId = userId;
  }

  public static MutationName: string = 'notification/acknowledgeNotification';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class SetNotificationSelectionMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;
  public selection: string;

  constructor(userId: string, notificationId: string, selection: string) {
    super();
    this.notificationId = notificationId;
    this.userId = userId;
    this.selection = selection;
  }

  public static MutationName: string = 'notification/setNotificationSelection';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class ReportNonLegitStudioInvitationMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;

  constructor(userId: string, notificationId: string) {
    super();
    this.userId = userId;
    this.notificationId = notificationId;
  }

  public static MutationName: string = 'notification/reportNonLegitStudioInvitation';
}
@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class AcceptStudioInvitationMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;

  constructor(userId: string, notificationId: string) {
    super();
    this.userId = userId;
    this.notificationId = notificationId;
  }

  public static MutationName: string = 'notification/acceptStudioInvitation';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class DeclineStudioInvitationMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;
  public reason: string;

  constructor(userId: string, notificationId: string, reason: string) {
    super();
    this.userId = userId;
    this.notificationId = notificationId;
    this.reason = reason;
  }

  public static MutationName: string = 'notification/declineStudioInvitation';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class AdmitStudioAdmittanceMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;

  constructor(userId: string, notificationId: string) {
    super();
    this.userId = userId;
    this.notificationId = notificationId;
  }

  public static MutationName: string = 'notification/admitStudioAdmittance';
}

@ModuleDomain.registerMutationAltersData
@ModuleDomain.registerSyncToServer
@ModuleDomain.registerMutationSendsToAppInsights
export class DenyStudioAdmittanceMutation extends NotificationModuleMutation {
  public userId: string;
  public notificationId: string;
  public reason: string;

  constructor(userId: string, notificationId: string, reason: string = '') {
    super();
    this.userId = userId;
    this.notificationId = notificationId;
    this.reason = reason;
  }

  public static MutationName: string = 'notification/denyStudioAdmittance';
}
