<template>
  <v-card flat color="transparent" width="340">
    <v-layout column align-center class="text-center">
      <v-card v-if="version !== ''" outlined :width="340" class="ma-0 pa-0">
        <div class="body-2 font-weight-black mt-2 mx-0 pa-0">{{ $t('C.TPanelStatus.version') }}</div>
        <v-chip x-small color="transparent" style="font-size: 80%;" class="mx-0 my-2 pa-0" data-testid="version">
          <span class="grey--text text--darken-4 mx-1 my-0 pa-0">{{version}}</span>
        </v-chip>
      </v-card>
      <v-card outlined :width="340" :class="version === '' ? 'ma-0 pa-0' : 'mt-12 mx-0 pa-0'">
        <v-layout column align-center class="ma-0 pa-0">
          <div class="body-2 font-weight-black mt-2 mx-0 pa-0">{{ $t('C.TPanelStatus.connectivity') }}</div>
          <v-layout row align-center class="ma-0 pa-0">
            <v-chip v-if="isForceOfflineActive" small outlined color="transparent" style="font-size: 80%;" class="mx-0 my-2 pa-0" data-testid="forceOfflineActive">
              <t-icon href="#wifi-off" :title="$t('C.TPanelStatus.force-offline')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
              <span class="grey--text text--darken-4 mx-1 my-0 pa-0">{{ $t('C.TPanelStatus.force-offline') }}</span>
            </v-chip>
            <v-chip v-if="isOnlineActive" small outlined color="transparent" style="font-size: 80%;" class="mx-0 my-2 pa-0" data-testid="onlineActive">
              <t-icon href="#wifi" :title="$t('C.TPanelStatus.online')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
              <span class="grey--text text--darken-4 mx-1 my-0 pa-0">{{ $t('C.TPanelStatus.online') }}</span>
            </v-chip>
            <v-chip v-if="isOfflineActive" small outlined color="transparent" style="font-size: 80%;" class="mx-0 my-2 pa-0" data-testid="offlineActive">
              <t-icon href="#wifi-off" :title="$t('C.TPanelStatus.offline')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
              <span class="grey--text text--darken-4 mx-1 my-0 pa-0">{{ $t('C.TPanelStatus.offline') }}</span>
            </v-chip>
            <v-chip v-if="isLiveSynchActive" small outlined color="transparent" style="font-size: 80%;" class="ml-1 my-2 pa-0" data-testid="liveSyncActive">
              <t-icon href="#lan-connect" :title="$t('C.TPanelStatus.with-live-sync')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
              <span class="grey--text text--darken-4 mx-1 my-0 pa-0">{{ $t('C.TPanelStatus.with-live-sync') }}</span>
            </v-chip>
            <v-chip v-if="isSynchingActive" small outlined color="transparent" style="font-size: 80%;" class="ml-1 my-2 pa-0" data-testid="synchingActive">
              <t-icon href="#sync" :title="$t('C.TPanelStatus.synching')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
              <span class="grey--text text--darken-4 mx-1 my-0 pa-0">({{ $t('C.TPanelStatus.synching') }})</span>
            </v-chip>
            <v-badge v-if="pendingMutationsToSyncCount > 0" :content="pendingMutationsToSyncCount" color="red" left overlap class="ml-4 my-2 pa-0" data-testid="pendingMutationsToSyncBadge">
              <v-chip small outlined color="transparent" style="font-size: 80%;" class="ml-1 my-0 pa-0">
                <t-icon href="#upload-network" :title="$t('C.TPanelStatus.pending-items-to-sync')" color="secondary" :size="18" class="ml-1 my-0 pa-0"/>
                <span v-if="pendingMutationsToSyncCount === 1" class="grey--text text--darken-4 mx-1 my-0 pa-0">{{pendingMutationsToSyncCount}}{{ $t('C.TPanelStatus.change-to-sync-with-internet') }}</span>
                <span v-else class="grey--text text--darken-4 mx-1 my-0 pa-0">{{pendingMutationsToSyncCount}}{{ $t('C.TPanelStatus.changes-to-sync-with-internet') }}</span>
              </v-chip>
            </v-badge>
          </v-layout>
          <div v-if="pendingMutationsToSyncCount > 0" style="line-height: 100%; white-space: pre-line;" class="red--text text--darken-1 text-none text-center my-2 mx-0 pa-0" data-testid="pendingMutationsToSyncMessage">{{ $t('C.TPanelStatus.connect-your-device') }}</div>
          <v-layout v-if="isSignedIn" row align-center class="mx-1 mb-3 pa-0">
            <v-btn large rounded color="primary" :disabled="!canPerformSync" style="border-radius: 12px;" class="button white--text text-none mx-0 mt-2 px-2" @click="forceSyncWithServerMutation(isFullRefresh)" data-testid="PerformSync">{{ $t('C.TPanelStatus.perform-sync') }}</v-btn>
            <v-switch v-if="isProdEnv === false" v-model="isFullRefresh" :disabled="!canPerformSync" hide-details dense color="primary" class="caption ml-4 mb-2 pa-0">
              <template v-slot:label>
                <span :class="isFullRefresh ? 'blue--text text--darken-1' : ''">{{ $t('C.TPanelStatus.with-full-refresh') }}</span>
              </template>
            </v-switch>
          </v-layout>
          <template v-if="isProdEnv === false" >
            <hr v-if="isSignedIn && (isOnlineActive || isForceOfflineActive)" color="#eee" width="100%" class="my-1 pa-0" data-testid="forceOffline"/>
            <v-switch v-if="isSignedIn && (isOnlineActive || isForceOfflineActive)" v-model="isForceOfflineActive" hide-details dense color="primary" class="ml-2 my-2 pa-0">
              <template v-slot:label>
                <span :class="isForceOfflineActive ? 'blue--text text--darken-1' : ''">{{ $t('C.TPanelStatus.force-offline') }}</span>
              </template>
            </v-switch>
          </template>
        </v-layout>
      </v-card>
      <v-card v-if="isProdEnv === false" outlined :width="340" class="mt-12 mx-0 pa-0" data-testid="servers">
        <v-layout column align-center class="ma-0 pa-0">
          <div class="body-2 font-weight-black mt-2 mx-0 pa-0">{{ $t('C.TPanelStatus.backend') }}</div>
          <v-select :menu-props="{auto: true, offsetY: true}" :items="servers" item-text="name" item-value="id" :value="serverId" :label="$t('C.TPanelStatus.server')" persistent-hint hint="Select the backend server" filled dense @change="onServerChanged" class="mx-4 my-2 pa-0">
            <template v-slot:selection="{ item }">
              <v-layout column align-start class="ma-0 pa-0">
                <v-layout row align-center justify-start class="mx-1">
                  <t-icon :href="item.id === 'none' ? '#server-network-off' : '#server-network'" :title="item.id === 'none' ? $t('C.TPanelStatus.no-backend-server') : $t('C.TPanelStatus.backend-server')" color="secondary" :size="18"/>
                  <span class="ml-2 body-2">{{ item.name }}</span>
                </v-layout>
              </v-layout>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-layout column align-start class="ma-0 pa-0">
                  <v-layout row align-center justify-start class="mx-0">
                    <t-icon :href="item.id === 'none' ? '#server-network-off' : '#server-network'" :title="item.id === 'none' ? $t('C.TPanelStatus.no-backend-server') : $t('C.TPanelStatus.backend-server')" color="secondary" :size="18"/>
                    <span class="ml-2 body-2">{{ item.name }}</span>
                  </v-layout>
                </v-layout>
              </v-list-item-content>
            </template>
          </v-select>
        </v-layout>
      </v-card>

    </v-layout>
  </v-card>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import * as AppModuleDomain from '../models/AppModuleDomain';
import * as ConfigModuleDomain from '../models/ConfigModuleDomain';
import * as AuthenticationModuleDomain from '../models/AuthenticationModuleDomain';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-panel-status',
})
export default class TPanelStatus extends Vue {
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly user!: Domain.User | null;
  @Prop({ type: Array, default: function() { return []; } })
  public readonly servers: {id: string, name: string}[];
  @Prop({ type: String, default: 'config' })
  public readonly serverId!: string;
  @Prop({ type: String, default: '' })
  public readonly version!: string;
  @Prop({ type: Boolean, default: false })
  public readonly isOnline: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isSynching: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isLiveSynch: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isForceOffline: boolean;
  @Prop({ type: Number, default: 0 })
  public readonly pendingMutationsToSyncCount: number;
  // Events

  private innerIsForceOffline: boolean = false;
  public isFullRefresh: boolean = false;

  private beforeMount() {
    this.innerIsForceOffline = this.isForceOffline;
  }

  public get isSignedIn(): boolean {
    return this.user !== undefined && this.user !== null;
  }

  public get isProdEnv(): boolean {
    return (this.servers.length === 0);
  }

  public get canPerformSync(): boolean {
    if (this.isForceOfflineActive || this.isOfflineActive || this.isLiveSynchActive || this.isSynchingActive) {
      return false;
    }
    return this.isOnline;
  }

  public get isOnlineActive(): boolean {
    if (this.innerIsForceOffline) {
      return false;
    }
    return this.isOnline;
  }

  public get isOfflineActive(): boolean {
    if (this.innerIsForceOffline) {
      return false;
    }
    return (this.isOnline === false);
  }

  public get isLiveSynchActive(): boolean {
    if (this.innerIsForceOffline) {
      return false;
    }
    return this.isLiveSynch;
  }

  public get isSynchingActive(): boolean {
    if (this.innerIsForceOffline) {
      return false;
    }
    return this.isSynching;
  }

  public get isForceOfflineActive(): boolean {
    return this.innerIsForceOffline;
  }
  public set isForceOfflineActive(value: boolean) {
    this.innerIsForceOffline = value;

    const payload = new AppModuleDomain.SetForceOfflineMutation(value);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AppModuleDomain.SetForceOfflineMutation.MutationName + '" }');
    } else {
      this.$store.commit(AppModuleDomain.SetForceOfflineMutation.MutationName, payload);
    }
  }

  public async onServerChanged(newServerId: string): Promise<void> {
    const payload = new ConfigModuleDomain.ChangeServerEnvironmentAction(newServerId);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + ConfigModuleDomain.ChangeServerEnvironmentAction.ActionName + '" }');
    } else {
      await this.$store.dispatch(ConfigModuleDomain.ChangeServerEnvironmentAction.ActionName, payload);
      await this.$store.dispatch(AuthenticationModuleDomain.SignOutChangeEnvironmentAction.ActionName, new AuthenticationModuleDomain.SignOutChangeEnvironmentAction());
    }
  }

  public forceSyncWithServerMutation(forceFullSync: boolean) {
    const payload = new AppModuleDomain.ForceSyncWithServerMutation(forceFullSync);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AppModuleDomain.ForceSyncWithServerMutation.MutationName + '" }');
    } else {
      this.$store.commit(AppModuleDomain.ForceSyncWithServerMutation.MutationName, payload);
    }
  }

}
</script>

