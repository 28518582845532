<template>
  <v-card flat color="transparent" class="ma-0 pa-0">
    <v-layout row align-center class="ma-0 px-0 py-0">
      <template v-if="$vuetify.breakpoint.xs">
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="copilot" :isActive="activeBtn === 'copilot'" nav="tips" class="ma-0 pa-0" data-testid="copilotBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="agenda" :isActive="activeBtn === 'agenda'" nav="agenda-upcoming" class="ma-0 pa-0" data-testid="agendaBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="smallWins" :isActive="activeBtn === 'smallWins'" nav="small-wins-all" class="ma-0 pa-0" data-testid="smallWinsBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <template v-if="activeBtn === 'toDo' || activeBtn === 'profile' || activeBtn === 'subscription' || activeBtn === 'installation' || activeBtn === 'feedback' || activeBtn === 'about'">
          <t-header-nav-bar-btn v-if="activeBtn === 'toDo'" btn="toDo" class="ma-0 pa-0" data-testid="profileBtn"/>
          <t-header-nav-bar-btn v-if="activeBtn === 'profile'" btn="profile" class="ma-0 pa-0" data-testid="profileBtn"/>
          <t-header-nav-bar-btn v-if="activeBtn === 'subscription'" btn="subscription" class="ma-0 pa-0" data-testid="subscriptionBtn"/>
          <t-header-nav-bar-btn v-if="activeBtn === 'installation'" btn="installation" class="ma-0 pa-0" data-testid="installationBtn"/>
          <t-header-nav-bar-btn v-if="activeBtn === 'feedback'" btn="feedback" class="ma-0 pa-0" data-testid="feedbackBtn"/>
          <t-header-nav-bar-btn v-if="activeBtn === 'about'" btn="about" class="ma-0 pa-0" data-testid="aboutBtn"/>
        </template>
        <template v-else>
          <t-header-nav-bar-btn btn="studios" :isActive="activeBtn === 'studios'" nav="studios" class="ma-0 pa-0" data-testid="studiosBtn"/>
        </template>
      </template>
      <template v-else>
        <v-card flat color="transparent" :width="32" :height="50"></v-card>
        <t-header-nav-bar-btn btn="copilot" :isActive="activeBtn === 'copilot'" nav="tips" class="ma-0 pa-0" data-testid="copilotBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="agenda" :isActive="activeBtn === 'agenda'" nav="agenda-upcoming" class="ma-0 pa-0" data-testid="agendaBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="smallWins" :isActive="activeBtn === 'smallWins'" nav="small-wins-all" class="ma-0 pa-0" data-testid="smallWinsBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn btn="studios" :isActive="activeBtn === 'studios'" nav="studios" class="ma-0 pa-0" data-testid="studiosBtn"/>
        <v-card flat color="transparent" :width="12" :height="50"></v-card>
        <t-header-nav-bar-btn v-if="activeBtn === 'toDo'" btn="toDo" class="ma-0 pa-0" data-testid="profileBtn"/>
        <t-header-nav-bar-btn v-if="activeBtn === 'profile'" btn="profile" class="ma-0 pa-0" data-testid="profileBtn"/>
        <t-header-nav-bar-btn v-if="activeBtn === 'subscription'" btn="subscription" class="ma-0 pa-0" data-testid="subscriptionBtn"/>
        <t-header-nav-bar-btn v-if="activeBtn === 'installation'" btn="installation" class="ma-0 pa-0" data-testid="installationBtn"/>
        <t-header-nav-bar-btn v-if="activeBtn === 'feedback'" btn="feedback" class="ma-0 pa-0" data-testid="feedbackBtn"/>
        <t-header-nav-bar-btn v-if="activeBtn === 'about'" btn="about" class="ma-0 pa-0" data-testid="aboutBtn"/>
      </template>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import TIcon from '../components/TIcon.vue';
import THeaderNavBarBtn from '../components/THeaderNavBarBtn.vue';

@Component({
  components: {
    TIcon, THeaderNavBarBtn,
  },
  name: 't-header-nav-bar',
})
export default class THeaderNavBar extends Vue {
  @Prop({ type: String, required: true })
  public readonly activeBtn!: string;
  @Prop({ type: Object, required: true })
  public readonly user!: Domain.User;

  public get userNickname(): string {
    return ('@' + this.user.nickname);
  }

}
</script>

