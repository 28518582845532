<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.width < 500 || $vuetify.breakpoint.height < 500" @keydown.esc="onCancel" @keydown.enter="onEnter" persistent scrollable no-click-animation max-width="500" :width="$vuetify.breakpoint.width - 10">
    <v-card flat color="background" style="height: 500px;">
      <v-card-title class="pa-0 ma-0">
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel" data-testid="dialogCancelBtn">
            <t-icon href="#close" :title="$t('G.cancel')" color="white" :size="24"/>
          </v-btn>
          <v-toolbar-title class="subtitle-1">{{ $t('C.TDialogFeedback.dialogTitle') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined rounded style="border-width: thin; border-radius: 12px;" class="button text-none" :disabled="!isFormValid" @click="onConfirm" data-testid="dialogConfirmBtn">{{ $t('G.ok') }}</v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-4 ma-0">
        <v-layout column align-center class="text-center">
          <v-card flat color="transparent" class="mt-5 mx-0 pa-0">
            <v-layout column align-center justify-start class="ma-0 pa-0">
              <v-chip-group v-model="kind" active-class="#E3F2FD primary--text" column mandatory class="ma-0 pa-0">
                <v-chip small ripple value="general" data-testid="generalBtn">{{ $t('C.TDialogFeedback.general') }}</v-chip>
                <v-chip small ripple value="bug" data-testid="bugBtn">{{ $t('C.TDialogFeedback.bug') }}</v-chip>
                <v-chip small ripple value="idea" data-testid="ideaBtn">{{ $t('C.TDialogFeedback.idea') }}</v-chip>
                <v-chip small ripple value="question" data-testid="questionBtn">{{ $t('C.TDialogFeedback.question') }}</v-chip>
              </v-chip-group>
            </v-layout>
          </v-card>
        </v-layout>
        <v-form v-model="isFormValid" transparent @submit.prevent>
          <div class="subtitle grey--text text--darken-1 ml-1 mt-6 pa-0"><b>{{ $t('C.TDialogFeedback.title') }}</b></div>
          <div style="line-height: 100%; font-size: 85%;" class="grey--text text--darken-1 ml-1 mb-1 pa-0">{{ $t('C.TDialogFeedback.title-description') }}</div>
          <v-text-field ref="title" v-model="title" background-color="white" outlined dense :placeholder="$t('C.TDialogFeedback.title-placeholder')" :rules="titleRules" required style="border-radius: 8px;" class="tds-feedback ma-0 pa-0" data-testid="dialogRequiredTitle"></v-text-field>

          <div class="subtitle grey--text text--darken-1 ml-1 mt-4 pa-0"><b>{{ $t('C.TDialogFeedback.description') }}</b></div>
          <div style="line-height: 100%; font-size: 85%;" class="grey--text text--darken-1 ml-1 mb-1 pa-0">{{ $t('C.TDialogFeedback.description-description') }}</div>
          <v-textarea v-model="description" background-color="white" outlined :placeholder="$t('C.TDialogFeedback.description-placeholder')" auto-grow :rows="5" style="border-radius: 8px;" class="tds-feedback ma-0 pa-0" data-testid="dialogDescription"></v-textarea>
        </v-form>
        <v-card flat color="transparent" height="24" class="ma-0 pa-0"></v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// tslint:disable:max-line-length
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as AppModuleDomain from '../models/AppModuleDomain';
import TIcon from '../components/TIcon.vue';

@Component({
  components: {
    TIcon,
  },
  name: 't-dialog-feedback',
})
export default class TDialogFeedback extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly dialog!: boolean;
  // Events
  // this.$emit('cancel');
  // this.$emit('confirm');

  public isFormValid: boolean = false;
  public titleRules: any[] = [];
  public title: string = '';
  public description: string = '';
  public kind: AppModuleDomain.EnumFeedbackKind = AppModuleDomain.EnumFeedbackKind.general;

  private beforeMount() {
    this.titleRules = [
      (value) => (value || '').length > 0 || this.$t('C.TDialogFeedback.title-is-required'),
      (value) => value.length <= 200 || this.$t('C.TDialogFeedback.title-less-than-200'),
    ];
    this.dialogChanged();
  }

  @Watch('dialog', { immediate: false, deep: false })
  private dialogChanged() {
    if (this.dialog) {
      this.title = '';
      this.description = '';
      this.kind = AppModuleDomain.EnumFeedbackKind.general;
    }
  }

  public onEnter() {
    if (this.isFormValid) {
      this.onConfirm();
    }
  }

  public onConfirm() {
    const title = "Feedback-" + this.title;
    const description = this.description;
    const kind = this.kind;

    const payload = new AppModuleDomain.ProvideFeedbackMutation(title, description, kind);
    if ((window as any).Cypress && (window as any).Cypress.testingType === 'component') {
      alert('{ "store": "' + AppModuleDomain.ProvideFeedbackMutation.MutationName + '" }');
    } else {
      this.$store.commit(AppModuleDomain.ProvideFeedbackMutation.MutationName, payload);
    }
    this.$emit('confirm');
  }

  public onCancel() {
    this.$emit('cancel');
  }
}
</script>

<style>

.tds-feedback fieldset {
  border-color: #BDBDBD;
}

.tds-feedback .v-text-field__slot textarea {
   line-height: 140% !important;
   font-size: 90% !important;
}

.tds-feedback textarea {
   line-height: 140% !important;
   font-size: 90% !important;
}

.tds-feedback .v-input__append-inner {
   margin-top: 9px !important;
}

</style>
