// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import * as Domain from '../models/Domain';
import store from '../plugins/vuex';
import * as NotificationModuleDomain from '@/models/NotificationModuleDomain';
import * as PersistanceHelper from '@/helpers/PersistanceHelper';
import Services from '@/services/Services';

@Module({ dynamic: true, store, namespaced: true, name: 'notification' })
export default class NotificationModule extends VuexModule {
  public notifications: Map<string, Domain.Notification> = new Map<string, Domain.Notification>();
  public notificationsArr: Domain.Notification[] = []; // todovue3 remove this when vuejs supports map reactivity

  @Mutation
  public restore(payload: NotificationModuleDomain.RestoreMutation): void {
    try {
      payload.notifications.forEach((item) => {
        const matched = this.notifications.get(item.id);
        if (matched) {
          PersistanceHelper.Update(matched, item, `Notification ${item.id}`);
        } else {
          this.notifications.set(item.id, PersistanceHelper.Update(null, item, `Notification ${item.id}`));
          this.notificationsArr.push(this.notifications.get(item.id) as Domain.Notification);
        }
      });
      payload.removedNotificationIds.forEach((item) => {
        this.notifications.delete(item);

        const toRemove = this.notificationsArr.find((t) => t.id === item);
        if (toRemove !== undefined) {
          this.notificationsArr.splice(this.notificationsArr.indexOf(toRemove), 1);
        }
      });
    } catch (err: any) {
      Services.LogException(err);
      payload.out_error = true;
    }
  }

  @Mutation
  public reset(payload: NotificationModuleDomain.ResetMutation): void {
    this.notifications.forEach((value) => {
      payload.metadata_deletes.push(value);
    });
    this.notifications.clear();

    while (this.notificationsArr.length !== 0) {
      this.notificationsArr.pop();
    }
  }

  @Mutation
  public acknowledgeNotification(payload: NotificationModuleDomain.AcknowledgeNotificationMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const acknowledgementNotification = notification as Domain.AcknowledgementNotification;
    acknowledgementNotification.isAcknowledged = true;

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public setNotificationSelection(payload: NotificationModuleDomain.SetNotificationSelectionMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const acknowledgementNotification = notification as Domain.SelectionNotification;
    acknowledgementNotification.selectedSelection = payload.selection;

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public reportNonLegitStudioInvitation(payload: NotificationModuleDomain.ReportNonLegitStudioInvitationMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const actionNotification = notification as Domain.SelectionNotification;
    actionNotification.selectedSelection = 'reportNonLegit';

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public acceptStudioInvitation(payload: NotificationModuleDomain.AcceptStudioInvitationMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const actionNotification = notification as Domain.SelectionNotification;
    actionNotification.selectedSelection = 'accept';

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public declineStudioInvitation(payload: NotificationModuleDomain.DeclineStudioInvitationMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const actionNotification = notification as Domain.SelectionNotification;
    actionNotification.selectedSelection = 'decline';

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public admitStudioAdmittance(payload: NotificationModuleDomain.AdmitStudioAdmittanceMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const actionNotification = notification as Domain.SelectionNotification;
    actionNotification.selectedSelection = 'admit';

    payload.metadata_upserts.push(notification);
  }

  @Mutation
  public denyStudioAdmittance(payload: NotificationModuleDomain.DenyStudioAdmittanceMutation): void {
    const notification = this.notifications.get(payload.notificationId);
    const actionNotification = notification as Domain.SelectionNotification;
    actionNotification.selectedSelection = 'refuse';

    payload.metadata_upserts.push(notification);
  }
}
