<template>
    <v-footer height="36" min-height="36" app fixed tile :clipped-left="true" class="secondary my-0 py-0">
      <span v-if="$vuetify.breakpoint.smAndUp" class="grey--text text--lighten-2 mr-3 caption">
        {{ $t('C.TFooter.copyright') }} &#169; <a class="grey--text text--lighten-2" href="https://info.to-do.studio" target="_blank" rel="noopener" data-testid="homeUrl">{{$t("G.appName")}}</a>
      </span>
      <a class="grey--text text--lighten-2 caption mr-3" href="https://info.to-do.studio/termsOfUse" target="_blank" rel="noopener" data-testid="termsUrl">{{$t("C.TFooter.termsLink")}}</a>
      <a class="grey--text text--lighten-2 caption" href="https://info.to-do.studio/privacyPolicy" target="_blank" rel="noopener" data-testid="privacyUrl">{{$t("C.TFooter.privacyLink")}}</a>
      <v-spacer></v-spacer>
      <v-card v-if="$vuetify.breakpoint.xs" flat color="transparent" :min-width="46" class="ma-0 pa-0" @click="openFeedbackDialog(true)">
        <v-layout row align-center class="ma-0 pa-0">
          <t-icon href="#megaphone" title="Feedabck" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          <div style="font-size: 80%; line-height: 100%; white-space: pre-line;" class="grey--text text--lighten-2 ml-1 my-0 pa-0">{{ $t('C.TFooter.feedback') }}</div>
        </v-layout>
      </v-card>
      <v-spacer></v-spacer>
      <v-card flat color="transparent" :min-width="46" class="ma-0 pa-0" @click="openStatusDialog(true)">
        <v-layout row align-center class="ma-0 pa-0">
          <v-badge v-if="isSyncError" content="x" color="red" class="ma-0 pa-0">
            <t-icon href="#wifi" :title="$t('C.TPanelStatus.sync-error')" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          </v-badge>
          <t-icon v-else-if="isSynching" href="#sync" :title="$t('C.TPanelStatus.synching')" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          <t-icon v-else-if="isOnline && !isForceOffline && isLiveSynch" href="#wifi" :title="$t('C.TPanelStatus.online')" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          <t-icon v-else-if="isOnline && !isForceOffline" href="#wifi" :title="$t('C.TPanelStatus.online')" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          <t-icon v-else href="#wifi-off" :title="$t('C.TPanelStatus.offline')" color="#EEEEEE" :size="18" class="ma-0 pa-0" />
          <t-icon v-if="(serverId === 'none') && (pendingMutationsToSyncCount === 0)" href="#server-network-off" :title="$t('C.TPanelStatus.no-backend-server')" color="#EEEEEE" :size="18" class="ml-4 pa-0"/>
          <t-icon v-if="(serverId !== 'none') && (pendingMutationsToSyncCount === 0)" href="#server-network" :title="$t('C.TPanelStatus.with-backend-server')" color="#EEEEEE" :size="18" class="ml-4 pa-0"/>
          <v-badge v-if="pendingMutationsToSyncCount > 0" :content="pendingMutationsToSyncCount" color="red" left class="ml-4 my-0 pa-0">
            <t-icon href="#upload-network" :title="$t('C.TPanelStatus.pending-items-to-sync')" color="#EEEEEE" :size="18" class="ma-0 pa-0"/>
          </v-badge>
        </v-layout>
      </v-card>
      <t-dialog-feedback :dialog="isDialogFeedbackOpen" @cancel="openFeedbackDialog(false)" @confirm="openFeedbackDialog(false)" @store="$emit('store', $event)"/>
      <t-dialog-status :dialog="isDialogStatusOpen" :user="user" :servers="servers" :serverId="serverId" :version="version" :pendingMutationsToSyncCount="pendingMutationsToSyncCount" :isOnline="isOnline" :isLiveSynch="isLiveSynch" :isSynching="isSynching" :isForceOffline="isForceOffline" @cancel="openStatusDialog(false)" @store="$emit('store', $event)"/>
    </v-footer>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as Domain from '../models/Domain';
import TIcon from '../components/TIcon.vue';
import TDialogFeedback from '../components/TDialogFeedback.vue';
import TDialogStatus from '../components/TDialogStatus.vue';

@Component({
  components: {
    TIcon, TDialogFeedback, TDialogStatus,
  },
  name: 't-footer',
})
export default class TFooter extends Vue {
  @Prop({ validator: (prop) => (typeof prop === 'object' || prop === null), default: null })
  public readonly user!: Domain.User | null;
  @Prop({ type: Array, default: function() { return []; } })
  public readonly servers: {id: string, name: string}[];
  @Prop({ type: String, default: 'config' })
  public readonly serverId!: string;
  @Prop({ type: String, default: '' })
  public readonly version!: string;
  @Prop({ type: Boolean, default: false })
  public readonly isOnline: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isSynching: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isSyncError: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isLiveSynch: boolean;
  @Prop({ type: Boolean, default: false })
  public readonly isForceOffline: boolean;
  @Prop({ type: Number, default: 0 })
  public readonly pendingMutationsToSyncCount: number;

  private dialogFeedback: boolean = false;
  private dialogStatus: boolean = false;

  private beforeMount() {
    this.dialogFeedback = false;
    this.dialogStatus = false;
  }

  public get isDialogFeedbackOpen(): boolean {
    return this.dialogFeedback;
  }

  public openFeedbackDialog(value: boolean) {
    this.dialogFeedback = value;
  }

  public get isDialogStatusOpen(): boolean {
    return this.dialogStatus;
  }

  public openStatusDialog(value: boolean) {
    this.dialogStatus = value;
  }

}
</script>

