// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length

export const noRecurrence = '';
export const noDayOfWeekValue = '*';
export const noMonthDayValue = '*';
export const noMonthValue = '*';
export const oneMonthValue = '*/1';
export const twoMonthValue = '*/2';
export const threeMonthValue = '*/3';
export const fourMonthValue = '*/4';
export const sixMonthValue = '*/6';
export const noHourValue = '*';

export function getType(recurrence: string): string {
  if (recurrence === noRecurrence) { return 'none'; }

  const recurrences = recurrence.split(' ');
  if (recurrences.length !== 5) {
    throw new Error('From getType in RecurrenceHelper.ts: recurrence parameter is NOT a valid Cron instruction - need 5 sections');
  }

  if (recurrences[4] === '0-6') { return 'day'; }
  else if (recurrences[4] === '1-5') { return 'weekday'; }
  else if ((recurrences[4] !== '*') && (recurrences[4] !== '0-6') && (recurrences[4] !== '1-5')) { return 'week'; }
  else if (recurrences[3] === '*/1') { return 'oneMonth'; }
  else if (recurrences[3] === '*/2') { return 'twoMonth'; }
  else if (recurrences[3] === '*/3') { return 'threeMonth'; }
  else if (recurrences[3] === '*/4') { return 'fourMonth'; }
  else if (recurrences[3] === '*/6') { return 'sixMonth'; }
  else if (recurrences[3] !== '*') { return 'year'; }
  else { return 'none'; }

}

export function getRecurrenceForDay(hour: string): string {
  return '0 ' + hour + ' * * 0-6' ;
}

export function getRecurrenceForWeekday(hour: string): string {
  return '0 ' + hour + ' * * 1-5' ;
}

export function getRecurrenceForWeek(dayOfWeek: string, hour: string): string {
  return '0 ' + hour + ' * * ' + dayOfWeek;
}

export function getRecurrenceForOneMonth(monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' */1 *';
}

export function getRecurrenceForTwoMonth(monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' */2 *';
}

export function getRecurrenceForThreeMonth(monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' */3 *';
}

export function getRecurrenceForFourMonth(monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' */4 *';
}

export function getRecurrenceForSixMonth(monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' */6 *';
}

export function getRecurrenceForYear(month: string, monthDay: string, hour: string): string {
  return '0 ' + hour + ' ' + monthDay + ' ' + month + ' *';
}

export function getDayOfWeek(recurrence: string): string {
  if (recurrence === noRecurrence) { return '*'; }

  const recurrences = recurrence.split(' ');
  if (recurrences.length !== 5) {
    throw new Error('From getDayOfWeek in RecurrenceHelper.ts: recurrence parameter is NOT a valid Cron instruction - need 5 sections');
  }

  return recurrences[4];
}

export function getMonth(recurrence: string): string {
  if (recurrence === noRecurrence) { return '*'; }

  const recurrences = recurrence.split(' ');
  if (recurrences.length !== 5) {
    throw new Error('From getMonth in RecurrenceHelper.ts: recurrence parameter is NOT a valid Cron instruction - need 5 sections');
  }

  return recurrences[3];
}

export function getMonthDay(recurrence: string): string {
  if (recurrence === noRecurrence) { return '*'; }

  const recurrences = recurrence.split(' ');
  if (recurrences.length !== 5) {
    throw new Error('From getMonthDay in RecurrenceHelper.ts: recurrence parameter is NOT a valid Cron instruction - need 5 sections');
  }

  return recurrences[2];
}

export function getHour(recurrence: string): string {
  if (recurrence === noRecurrence) { return '*'; }

  const recurrences = recurrence.split(' ');
  if (recurrences.length !== 5) {
    throw new Error('From getHour in RecurrenceHelper.ts: recurrence parameter is NOT a valid Cron instruction - need 5 sections');
  }

  return recurrences[1];
}
