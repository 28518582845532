import { createInstance, LOCALSTORAGE, INDEXEDDB } from 'localforage';

export const AppPersistantStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'App',
  driver: LOCALSTORAGE,
  version: 1,
});

export const AppStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'App',
  driver: INDEXEDDB,
  version: 1,
});

export const MutationsToSyncStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'MutationsToSync',
  driver: INDEXEDDB,
  version: 1,
});

export const StudioOfflineStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'Studios',
  driver: INDEXEDDB,
  version: 1,
});

export const ToDoOfflineStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'ToDos',
  driver: INDEXEDDB,
  version: 1,
});

export const UserOfflineStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'Users',
  driver: INDEXEDDB,
  version: 1,
});

export const NotificationOfflineStorage = createInstance({
  name: 'To-Do.Studio',
  storeName: 'Notifications',
  driver: INDEXEDDB,
  version: 1,
});

export async function Initialize(): Promise<void> {
  await AppPersistantStorage.ready();
  await AppStorage.ready();
  await MutationsToSyncStorage.ready();
  await StudioOfflineStorage.ready();
  await ToDoOfflineStorage.ready();
  await UserOfflineStorage.ready();
  await NotificationOfflineStorage.ready();
}
